import Vue from 'vue'
import { EXTENDED_LINES_LINE_SIZE_THRESHOLD, LoanTerms, beginHomeDataFetchAndPreQualificationOffer, getHomeOffer, getTheoreticalPaymentStats } from '@/services/homeApi'
import { inspect, logger } from '@/utils/logger'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { savePreQualTermsAndOptionallyUpdateStatus } from '@/services/loanApplication'
import { getTheoreticalFixedMonthlyPayments } from '@/utils/paymentUtils'
import { experimentsMixin } from '@/mixins/experimentsMixin'

/**
 * Enumerates the possible actions for our code to takes after getting the pre-qual offer.
 * Keep in line with aven_backend/src/manager/underwritingManager.types.ts
 */
export enum PreQualAction {
    // complete, we will send this action to the front end to route applicant to the right page
    experianFail = 'experianFail',
    experianFrozen = 'experianFrozen',
    coApplicantExperianFrozen = 'coApplicantExperianFrozen',
    experianNotFound = 'experianNotFound',
    coApplicantExperianNotFound = 'coApplicantExperianNotFound',
    isIneligible = 'isIneligible',
    skipPrequal = 'skipPrequal', // this is assigned by front end only when applicant has already seen PQ (DM)
    showPrequal = 'showPrequal',
    offerAddCoApplicant = 'offerAddCoApplicant',
    blockRepeatApplicant = 'blockRepeatApplicant',
    remedyOwnerNameMismatch = 'remedyOwnerNameMismatch',
    blockedApplication = 'blockedApplication',
    underwritingFail = 'underwritingFail',
    homeOwnership = 'homeOwnership',
    homeIsLLC = 'homeIsLLC',
    homeIsTrustAndRin = 'homeIsTrustAndRin',
    homeIsTrustAndNOO = 'homeIsTrustAndNOO',
    homeIsTrustInNotSupportedState = 'homeIsTrustInNotSupportedState',
    denied = 'denied',
    humanInvestigate = 'humanInvestigate',
    expired = 'expired',
}

// Should match OfferInfoPayloadFromLoanApplication in src/manager/underwritingManager.types.ts
interface OfferInfoPayloadFromLoanApplication {
    createdAt: Date
    isFirstLienPosition: boolean
    isTrust?: boolean // optional because trust status can be undefined on home table

    preQualOffers?: LoanTerms[] // optional because only for preQualified loan apps
    offerTerms?: LoanTerms // optional because only for preQualified loan apps
    preQualTerms?: LoanTerms // optional because only for preQualified loan apps

    statedGrossAnnualIncome?: string // optional because only for offered loan apps
    usedGrossAnnualIncome?: number // optional because only for offered loan apps
    offerTime?: Date // optional because only for offered loan apps

    offerAddCoApplicant?: boolean // optional because only for denied loan apps
    adverseActionLink?: string // optional because only for denied loan apps
}

// Should match OfferInfoPayloadFromUnderwritingResult in src/manager/underwritingManager.types.ts
interface OfferInfoPayloadFromUnderwritingResult extends OfferInfoPayloadFromLoanApplication {
    loanApplicationDate: Date
    preLineDTI: number | null
    score: number // fico score
    hasExtendedLinesOffer: boolean
}

// Should match HomeDataFetchAndPreQualificationOfferControllerResponse in src/manager/underwritingManager.types.ts
interface HomeDataFetchAndPreQualificationOfferControllerResponse {
    preQualAction?: PreQualAction
    underwritingResult?: OfferInfoPayloadFromUnderwritingResult | null
}

interface OfferTerms {
    apr: number
    lineSize: number
}

export default Vue.extend({
    mixins: [experimentsMixin],
    data: function () {
        return {
            selectedPreQualOffer: null,
            preQualOffers: null,
            loanApplicationDate: new Date(),
            dti: null,
            score: null,
            currentSelectedOffer: null,
            memoizedTheoreticalPaymentStats: {},
            memoizedTheoreticalFixedPaymentStats: {},
            theoreticalPaymentStats: null,
            theoreticalFixedPaymentStats: null,
            theoreticalTermMonthsOptions: [],
        } as {
            selectedPreQualOffer: LoanTerms | null
            preQualOffers: LoanTerms[] | null
            loanApplicationDate: Date
            dti: number | null
            score: number | null
            currentSelectedOffer: OfferTerms | null
            memoizedTheoreticalPaymentStats: any
            memoizedTheoreticalFixedPaymentStats: any
            theoreticalPaymentStats: any
            theoreticalFixedPaymentStats: any
            theoreticalTermMonthsOptions: any
        }
    },
    methods: {
        fetchTheoreticalPaymentStats: async function (apr: number, lineSize: number) {
            if (!apr || !lineSize) {
                return
            }

            const memoizationKey = `${apr}-${lineSize}`
            if (!this.memoizedTheoreticalPaymentStats[memoizationKey]) {
                // Stats are not locally cached, fetch pre-qual theoretical stats
                const theoreticalPaymentStatsResponse = await getTheoreticalPaymentStats(apr, lineSize)
                this.memoizedTheoreticalPaymentStats[memoizationKey] = theoreticalPaymentStatsResponse.data.payload
            }

            // @ts-ignore this.heraclesParameter comes from the experiments mixin
            const theoreticalTermMonthsOptions = this.heraclesParameter.LINE_SIZE_LIMIT_FOR_TERMS(lineSize)

            // fetch pre-qual theoretical fixed payment stats
            // includes options if line size <= 25k [5 year, 10 year] else [15 year, 30 year]
            if (!this.memoizedTheoreticalFixedPaymentStats[memoizationKey]) {
                const parsedApr = +(parseFloat(`${apr}`) * 100).toFixed(2) // api wants decimal point rate (e.g. 7.99)
                const [firstFixedPlanResponse, secondFixedPlanResponse] = await Promise.all([
                    // @ts-ignore this.heraclesParameter comes from the experiments mixin
                    getTheoreticalFixedMonthlyPayments(parsedApr, lineSize, theoreticalTermMonthsOptions[0], this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT),
                    // @ts-ignore this.heraclesParameter comes from the experiments mixin
                    getTheoreticalFixedMonthlyPayments(parsedApr, lineSize, theoreticalTermMonthsOptions[1], this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT),
                ])

                if (firstFixedPlanResponse && secondFixedPlanResponse) {
                    // if corecard is down or we don't receive a proper response, the component will be hidden
                    this.memoizedTheoreticalFixedPaymentStats[memoizationKey] = [firstFixedPlanResponse, secondFixedPlanResponse]
                }
            }

            // Update everything at the same time
            this.theoreticalTermMonthsOptions = theoreticalTermMonthsOptions
            this.theoreticalPaymentStats = this.memoizedTheoreticalPaymentStats[memoizationKey]
            this.theoreticalFixedPaymentStats = this.memoizedTheoreticalFixedPaymentStats[memoizationKey] || null
        },
        setVariablesFromUnderwritingResult: function (underwritingResult: OfferInfoPayloadFromUnderwritingResult): void {
            if (underwritingResult.isFirstLienPosition) {
                appSessionStorage.setItem(sessionStorageKey.isFirstLienPosition, 'True')
            }

            this.loanApplicationDate = underwritingResult.loanApplicationDate ? new Date(underwritingResult.loanApplicationDate) : new Date()

            this.dti = underwritingResult.preLineDTI || null
            this.score = underwritingResult.score || null // fico score

            this.selectedPreQualOffer = underwritingResult.preQualTerms || null
            this.preQualOffers = underwritingResult.preQualOffers || null
        },
        choosePreQualTermsAndOptionallyUpdateStatus: async function (preQualTerms: LoanTerms, committed: boolean = false) {
            await savePreQualTermsAndOptionallyUpdateStatus(preQualTerms, committed)
            appSessionStorage.removeItem(sessionStorageKey.preQualificationFailureCode)
            appSessionStorage.setItem(sessionStorageKey.preQualificationOffer, JSON.stringify(preQualTerms))
            if (preQualTerms.lineSize > EXTENDED_LINES_LINE_SIZE_THRESHOLD) {
                appSessionStorage.setItem(sessionStorageKey.hasExtendedLinesOffer, 'True')
            }
        },
        runAndGetHomeDataFetchAndPreQualificationOffer: async function (isDmPrequal: boolean): Promise<HomeDataFetchAndPreQualificationOfferControllerResponse> {
            const {
                data: {
                    payload: { jobId },
                },
            } = await beginHomeDataFetchAndPreQualificationOffer({ purpose: 'preQualification', isDmPrequal })

            let homeDataFetchAndPreQualificationOfferResponse
            const maxNumAttempts = 20
            const timeBetweenAttempts = 2_500 // milliseconds
            for (let numAttempt = 0; numAttempt < maxNumAttempts; numAttempt++) {
                homeDataFetchAndPreQualificationOfferResponse = await getHomeOffer(jobId)
                if (homeDataFetchAndPreQualificationOfferResponse.data.success) {
                    logger.info(`preQual underwriting is complete. Response: ${inspect(homeDataFetchAndPreQualificationOfferResponse.data)}`)
                    return homeDataFetchAndPreQualificationOfferResponse.data.payload
                }
                logger.info(`Still waiting for data fetch and preQual underwriting to complete. Attempt nr ${numAttempt + 1} out of ${maxNumAttempts}`)
                await new Promise((r) => setTimeout(r, timeBetweenAttempts))
            }
            logger.info('Data fetch and preQual failed, returning failed data fetch')
            return { preQualAction: PreQualAction.underwritingFail, underwritingResult: null }
        },
        onPrequalTermsSelection: async function (selectedPreQualOffer: OfferTerms) {
            await this.fetchTheoreticalPaymentStats(selectedPreQualOffer.apr, selectedPreQualOffer.lineSize)
            this.currentSelectedOffer = selectedPreQualOffer
        },
    },
})
