<template>
    <div>
        <upload-button
            class="mb-2"
            title="1099 (Last Year)"
            @on-file-change="uploadStatement($event, 1)"
            :is-complete="hasDocumentUploaded(1)"
            :is-submitting="isDocumentUploading(1)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <form-button
            v-if="areStatementsUploaded"
            type="button"
            label="Continue"
            class="mt-2"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_continue_verify_1099_form"
        />
    </div>
</template>

<script>
    import UploadButton from '@/components/UploadButton'
    import { mixins } from '@/mixins/mixins'
    import originationMixin from '@/mixins/originationMixin'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { beginPayStubVerification, uploadDocument } from '@/services/api'
    import { getNextRoute } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import FormButton from '@/components/base/FormButton'
    export default {
        name: 'Form1099',
        components: { FormButton, UploadButton },
        mixins: [mixins, originationMixin, documentVerificationMixin],
        mounted: function () {
            let previousUploadInfo = appSessionStorage.getItem(sessionStorageKey.form1099Info)
            if (previousUploadInfo) {
                this.uploadFileList = JSON.parse(previousUploadInfo).map((info) => !!info)
            } else {
                appSessionStorage.setItem(sessionStorageKey.form1099Info, JSON.stringify([null]))
                this.uploadFileList = [null]
            }
        },
        computed: {
            areStatementsUploaded() {
                return this.uploadFileList.every((v) => !!v)
            },
        },
        methods: {
            uploadStatement: async function (fileList, index) {
                this.$logEvent('click_button_upload_1099_document', { statementIndex: index })
                this.errorText = ''

                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Document file was not selected.')
                        this.errorText = i18n.tc('otherIncomeUpload.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const statementFileInfo = this.getFileInfo(file)
                    const previousFileUploads = JSON.parse(appSessionStorage.getItem(sessionStorageKey.form1099Info)) || [null]

                    // check if same file have already been uploaded
                    if (previousFileUploads.includes(statementFileInfo)) {
                        this.errorText = i18n.tc('otherIncomeUpload.errorAlreadyUploaded')
                        this.scrollToTop()
                        return
                    }

                    const response = await uploadDocument(file, 'form1099', index, this.isCoApplicantVerifyingIncome)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.tc('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    previousFileUploads[index - 1] = statementFileInfo // "index" begins at 1
                    appSessionStorage.setItem(sessionStorageKey.form1099Info, JSON.stringify(previousFileUploads))
                    this.uploadFileList = previousFileUploads // triggers the computed property to update
                } catch (error) {
                    logger.error(`Failed to upload income document`, error)
                    this.errorText = i18n.tc('otherIncomeUpload.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                }
            },
            next: async function () {
                if (this.submitting) return
                this.submitting = true
                this.errorText = ''

                try {
                    await beginPayStubVerification(this.isCoApplicantVerifyingIncome)
                    appSessionStorage.setItem(sessionStorageKey.incomeVerificationCompleted, 'true')
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    logger.error(`Failed to begin 1099 statement verification`, error)
                    this.errorText = ApiErrorHandler(error)
                    this.submitting = false
                }
            },
        },
    }
</script>

<style scoped></style>
