<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/question-circle.svg"
                alt="Question Mark"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                What is the difference?
            </p>
            <div class="text-muted">
                If both of you will be in the same room while signing, select yes. If not, no worries! We can schedule each of you to sign seperately.
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'WhatIsTheDifferenceListItem',
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
