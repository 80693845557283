<template>
    <div class="card border-0 p-2 px-3 mb-3 mt-2">
        <section-header class="text-center">
            {{ this.title }}
        </section-header>
        <div class="d-flex justify-content-between">
            <div class="text-start">
                <h4 class="mb-0">
                    {{ toFormattedUSDStripTrailingZeroCents(this.creditLimit) }}
                </h4>
                <p class="text-muted">
                    Credit Limit
                </p>
            </div>
            <div class="text-end">
                <h4 class="mb-0">
                    {{ formatApr(this.apr) }}
                </h4>
                <p class="text-muted">
                    Variable APR<sup>1</sup>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionHeader from '@/components/SectionHeader'
    import format from '@/mixins/format'

    export default {
        components: {
            'section-header': SectionHeader,
        },
        mixins: [format],
        props: {
            title: { type: String, required: true },
            creditLimit: { type: [String, Number], required: true },
            apr: { type: [String, Number], required: true },
        },
    }
</script>
