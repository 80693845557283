<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/cash-payment-bill.svg"
                alt=""
            >
        </div>
        <div class="col text-start">
            <p
                class="fw-bold mb-0 mt-1"
                v-html="$t('components.onboarding.cashBackListItem.title', { cashBackPercentage: formattedCashbackPercentage })"
            />
            <div
                class="text-muted"
                v-html="$t('components.onboarding.cashBackListItem.subTitle', { cashBackPercentage: formattedCashbackPercentage })"
            />
            <div class="bg-white p-2 px-3 rounded-3 d-flex align-items-center">
                <div class="pe-2">
                    <img
                        src="@/assets/images/global/homedepotslanted.png"
                        alt="Home Depot"
                        height="40"
                        width="40"
                    >
                </div>
                <div>
                    <p class="mb-0">
                        A neighbor in CA just got <span class="fw-bold">$165 in cashback</span> at Home Depot.
                    </p>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import format from '@/mixins/format'

    export default {
        name: 'UnlimitedCashbackListItem',
        mixins: [experimentsMixin, format],
        computed: {
            formattedCashbackPercentage() {
                return this.formatCashBackPercentage(this.heraclesParameter.BASIC_CASH_BACK_REDEMPTION_POINTS_TO_DOLLAR_RATIO)
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
