<template>
    <onboarding-layout
        :title="title"
        :active-step="3"
        :step-title-array="stepTitleNames"
        :loading="loading"
        :error-text="errorText"
        :loading-title="loadingTitle"
        :loading-sub-title="$t('bankConnect.loadingContent')"
    >
        <div
            class="card card-clickable shadow-none py-2 px-3 mb-2 text-start"
            @click="onPlaidConnectClick"
        >
            <div style="font-weight: bold">
                Connect Bank
            </div>
            <div class="text-muted">
                Connect your bank account where you get your paycheck or income
            </div>
        </div>

        <div
            class="card card-clickable shadow-none py-2 px-3 mb-2 text-start"
            @click="redirectToPayStubsVerification"
        >
            <div style="font-weight: bold">
                Upload Pay Stub
            </div>
            <div class="text-muted">
                Take a photo of your latest pay stub, and we’ll review within 1-2 days
            </div>
        </div>

        <div
            class="card card-clickable shadow-none py-2 px-3 mb-2 text-start"
            @click="redirectToTaxReturnVerification"
        >
            <div style="font-weight: bold">
                Upload Tax Document
            </div>
            <div class="text-muted">
                Upload your most recent tax return, and we'll review within 1-2 days
            </div>
        </div>

        <div
            class="card card-clickable shadow-none py-2 px-3 text-start"
            @click="callSupport"
        >
            <div style="font-weight: bold">
                Call Us
            </div>
            <div class="text-muted">
                {{ 'Call us at ' + supportPhone + ' with any questions about income verification' }}
            </div>
        </div>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { getNextRoute } from '@/flow/flowController'
    import { i18n } from '@/utils/i18n'
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { PlaidCompletionResponse, PlaidManager } from '@/mixins/plaidManager'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'

    export default {
        components: { OnboardingLayout },
        mixins: [originationMixin, documentVerificationMixin],
        data: function () {
            return {
                loading: true,
                loadingTitle: i18n.t('bankConnect.loading'),
                plaidManager: new PlaidManager(this.onPlaidSuccess, this.onNoDepositoryAccountFound, this.onPlaidExit),
                supportPhone: contact_info.phone,
            }
        },
        computed: {
            title() {
                if (!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                    // Individual applicant
                    return 'What is the easiest way for you to provide supporting information?'
                } else {
                    // Primary or co-applicant
                    return `What is the easiest way for ${this.verifyingApplicantName} to provide supporting information?`
                }
            },
        },
        mounted: async function () {
            await this.mountedFlow()
        },
        beforeRouteLeave: function (to, from, next) {
            // Make sure we close the Plaid window when the user navigates away
            this.plaidManager.exit()
            next()
        },
        methods: {
            mountedFlow: async function () {
                try {
                    const plaidInitialized = await this.plaidManager.init()
                    if (!plaidInitialized) {
                        this.errorText = i18n.tc('conversation.adBlockMessage')
                    }
                    this.loading = false
                    this.$logEvent('view_provide_supporting_information')
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
            },
            onPlaidConnectClick: function () {
                this.errorText = '' // clear error message
                this.plaidManager.open()
            },
            onPlaidSuccess: async function (plaidPublicToken, accounts, institutionInfo) {
                logger.info('connected to bank account, trying to fetch plaid report...')
                this.loadingTitle = i18n.t('bankConnect.loadingContent')
                this.loading = true
                try {
                    const plaidCompletionResponse = await this.plaidManager.completePlaidFetch(plaidPublicToken, accounts, institutionInfo, this.isCoApplicantVerifyingIncome)
                    if (plaidCompletionResponse === PlaidCompletionResponse.success) {
                        appSessionStorage.setItem(sessionStorageKey.incomeVerificationCompleted, 'true')
                        return await this.$router.push(getNextRoute(this.$router))
                    } else if (plaidCompletionResponse === PlaidCompletionResponse.nameMismatch) {
                        logger.info(`Plaid name mismatch.`)
                        appSessionStorage.setItem(sessionStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push(getNextRoute(this.$router))
                    }
                } catch (e) {
                    ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('bankConnect.loading')
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
            onNoDepositoryAccountFound: function (bankName) {
                logger.info('Error: Bank has no depository account')
                this.errorText = i18n.tc('conversation.noDepositoryAccount', null, { bankName })
            },
            // eslint-disable-next-line no-unused-vars
            onPlaidExit: function (error, metadata) {
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
            redirectToPayStubsVerification: async function () {
                return await this.$router.push({ path: originationPagePaths.PAY_STUB_VERIFICATION }) // not using the flow controller for this. Should fix at some point
            },
            redirectToTaxReturnVerification: async function () {
                return await this.$router.push({ path: originationPagePaths.TAX_RETURN_VERIFICATION }) // not using the flow controller for this. Should fix at some point
            },
            callSupport: function () {
                window.location.href = 'tel:' + this.supportPhone
            },
        },
    }
</script>
