<template>
    <div class="auth-layout">
        <loading-indicator
            :title="loadingTitle"
            :content="loadingSubtitle"
            v-if="loading"
        />
        <slot v-else />
    </div>
</template>

<script>
    /**
 * Use as the base for all auth screens.

 */

    import LoadingIndicator from '@/components/LoadingIndicator'

    export default {
        name: 'AuthLayout',
        props: {
            loading: {
                type: Boolean,
                required: false,
            },
            loadingTitle: {
                type: String,
                required: false,
            },
            loadingSubtitle: {
                type: String,
                required: false,
            },
        },
        components: {
            'loading-indicator': LoadingIndicator,
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/layouts/auth';
</style>
