<template>
    <onboarding-layout
        :title="$t('pages.origination.hmda.title')"
        :loading="loading"
        :loading-title="$t('pages.origination.hmda.loading')"
        :error-text="errorText"
    >
        <a
            href="#"
            data-testid="hmda-skip-all-link"
            class="fw-bolder text-center mb-5"
            @click="skipAll"
        > Skip, I Don’t Wish To Provide </a>

        <!--   Primary Applicant HMDA form     -->
        <hmda-form
            data-testid="primary-hmda-form"
            form-name="primary-hmda-form"
            :applicant-name="primaryApplicantName"
            ref="primaryHmdaForm"
        />

        <!--    CoApplicant HMDA Form    -->
        <hmda-form
            class="mt-6"
            v-if="hasCoApplicant"
            data-testid="secondary-hmda-form"
            form-name="secondary-hmda-form"
            :applicant-name="secondaryApplicantName"
            ref="secondaryHmdaForm"
        />
        <form-button
            button-classes="btn btn-secondary mt-2"
            data-testid="hmda-skip-all-button"
            @click="skipAll"
            :label="$t('pages.origination.hmda.skipCta')"
            event-name="click_button_skip_hdma"
        />
        <form-button
            button-classes="btn btn-secondary mt-2"
            data-testid="hmda-submit-all-button"
            @click="handleSubmit"
            :label="$t('pages.origination.hmda.cta')"
            event-name="click_button_submit_hdma_form"
        />

        <!-- Needed for testing standalone flow -->
        <span
            v-if="returnToken"
            style="visibility: hidden"
            data-testid="hmda-return-token"
        >
            {{ returnToken }}
        </span>

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <skip-list-item />
            <why-hmda-list-item />
            <hmda-app-impact-list-item />
            <feedback-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import SkipListItem from '@/components/onboarding/SkipListItem'
    import WhyHmdaListItem from '@/components/onboarding/WhyHmdaListItem'
    import HmdaAppImpactListItem from '@/components/onboarding/HmdaAppImpactListItem'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { postHmdaAnswers } from '@/services/homeApi'
    import { getNextRoute } from '@/flow/flowController'
    import originationMixin from '@/mixins/originationMixin'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import HMDAForm from '../../components/hmda/HMDAForm'
    import { startCase, toLower } from 'lodash'
    import { getReturnTokenForCurrentLA } from '../../services/testUtilApi'
    import FormButton from '@/components/base/FormButton.vue'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    const customStartCase = (str) => startCase(toLower(str))
    const formatName = (first, last) => `${customStartCase(first)} ${customStartCase(last)}`

    export default {
        name: 'HomeHmda',
        components: {
            FeedbackListItem,
            'hmda-app-impact-list-item': HmdaAppImpactListItem,
            'why-hmda-list-item': WhyHmdaListItem,
            'skip-list-item': SkipListItem,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
            'hmda-form': HMDAForm,
            'form-button': FormButton,
        },
        mixins: [originationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        data() {
            const primaryApplicantName = formatName(appSessionStorage.getItem(sessionStorageKey.firstName), appSessionStorage.getItem(sessionStorageKey.lastName))
            const secondaryApplicantName = formatName(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName), appSessionStorage.getItem(sessionStorageKey.coApplicantLastName))

            return {
                primaryApplicantName,
                secondaryApplicantName,
                isSkipAll: false,
                isProcessing: true,
                isSubmitting: true,
                returnToken: '',
            }
        },
        mounted: async function () {
            if (appSessionStorage.getItem(sessionStorageKey.alreadySubmittedHMDA) === 'true') {
                logger.info('skipping hmda page for user that has already submitted it')
                return await this.$router.push(getNextRoute(this.$router))
            }

            // Needed for testing standalone flow
            const returnTokenResponse = await getReturnTokenForCurrentLA()
            this.returnToken = returnTokenResponse.data.payload.returnToken

            // Generating these documents 1 page in advance
            try {
                this.isProcessing = false
                await this.tryGoNext()
                this.$logEvent('view_hmda')
            } catch (error) {
                this.errorText = ApiErrorHandler(error)
            }
        },
        methods: {
            tryGoNext: async function () {
                if (!this.isProcessing && !this.isSubmitting) {
                    appSessionStorage.setItem(sessionStorageKey.alreadySubmittedHMDA, 'true')
                    return await this.$router.push(getNextRoute(this.$router))
                }
            },
            areFormsComplete: function () {
                const isPrimaryComplete = this.$refs.primaryHmdaForm.isComplete()
                let isSecondaryComplete = true
                if (this.hasCoApplicant) {
                    isSecondaryComplete = this.$refs.secondaryHmdaForm.isComplete()
                }

                return isPrimaryComplete && isSecondaryComplete
            },
            skipAll: function () {
                this.$refs.primaryHmdaForm.skipNoSubmit()

                if (this.hasCoApplicant) {
                    this.$refs.secondaryHmdaForm.skipNoSubmit()
                }

                this.handleSubmit()
            },
            handleSubmit: async function () {
                // prevent double submissions
                if (this.loading) {
                    console.log('Still loading, refusing to submit')
                    return
                }
                this.errorText = ''

                const areFormsComplete = this.areFormsComplete()

                if (!areFormsComplete) {
                    this.errorText = 'Please complete the form below'
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    return
                }

                this.loading = true

                logger.info('Attempting to submit chosen HMDA questions')
                try {
                    const primaryAnswers = this.$refs.primaryHmdaForm.getAnswers()
                    let secondaryAnswers
                    if (this.hasCoApplicant) {
                        secondaryAnswers = this.$refs.secondaryHmdaForm.getAnswers()
                    }

                    const response = await postHmdaAnswers(primaryAnswers, secondaryAnswers)
                    logger.info(JSON.stringify(response.data))
                    this.isSubmitting = false
                    await this.tryGoNext()
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                    this.loading = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-radio {
        margin-bottom: $spacer;
    }
    p#subTitle {
        color: $black !important;
        font-weight: $font-weight-bold !important;
    }
    .subRadio {
        display: none;
    }
    :checked ~ .subRadio {
        display: block;
        margin-top: $spacer;
    }
</style>
