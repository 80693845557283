<template>
    <onboarding-layout
        :title="title"
        :sub-title="subTitle"
        :error-text="errorText"
        data-testid="other-income-letter-verification-page"
    >
        <form-radio-group
            :options="otherIncomeOptions"
            v-model="incomeOption"
            name="otherIncomeOptions"
        />
        <w2-field
            class="mt-3"
            v-show="incomeOption === 'W2'"
            data-testid="other-income-letter-verification_w2-option"
        />
        <Form1099
            class="mt-3"
            v-show="incomeOption === '1099'"
            data-testid="other-income-letter-verification_form-1099-option"
        />
        <OtherIncomeLetterForm
            class="mt-3"
            v-show="incomeOption === 'OTHER_LETTER'"
            data-testid="other-income-letter-verification_other-option"
        />
        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { mixins } from '@/mixins/mixins'
    import { i18n } from '@/utils/i18n'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import W2Field from '@/components/incomeVerification/W2Form'
    import Form1099 from '@/components/incomeVerification/Form1099'
    import OtherIncomeLetterForm from '@/components/incomeVerification/OtherIncomeLetterForm'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    const OtherIncomeOptions = [
        { name: 'W2 (Last Year)', value: 'W2' },
        { name: "1099's (Last Year)", value: '1099' },
        { name: 'Award Letter, offer letter, etc.', value: 'OTHER_LETTER' },
    ]

    export default {
        components: {
            FeedbackListItem,
            OtherIncomeLetterForm,
            Form1099,
            W2Field,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
            'form-radio-group': FormRadioGroup,
        },
        mixins: [mixins, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                incomeOption: '',
                otherIncomeOptions: OtherIncomeOptions,
            }
        },
        computed: {
            title() {
                return i18n.t('otherIncomeUpload.title')
            },
            subTitle() {
                return this.$route.query.verificationInProgress ? i18n.t('pages.shared.incomeVerification.verificationInProgress', { uploadedDocumentDescriptor: 'your income documents' }) : null
            },
        },
        mounted: function () {
            this.$logEvent('view_other_income_verification')
        },
    }
</script>
