import { coApplicantHttpClient, httpClient, runWithRetryLogic } from '@/utils/http-client'
import { StatedUsageType } from '@/services/api'

const updateEmployer = async (employer: string, jobTitle: string, isCoApplicant?: boolean) => {
    const postBody = {
        employer,
        jobTitle,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/submitEmployer', postBody)
    }
    return await httpClient.post('/submitEmployer', postBody)
}

const updateEmployerEmail = async (employerEmail: string, isCoApplicant?: boolean) => {
    const postBody = {
        employerEmail,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/submitEmployerEmail', postBody)
    }
    return await httpClient.post('/submitEmployerEmail', postBody)
}

const updateStatedIncome = async (statedIncome: string, isCoApplicant?: boolean) => {
    const postBody = {
        statedIncome: parseInt(statedIncome),
    }
    if (isCoApplicant) {
        return await runWithRetryLogic(async () => await coApplicantHttpClient.post('/submitStatedIncome', postBody), 1)
    }
    return await runWithRetryLogic(async () => await httpClient.post('/submitStatedIncome', postBody), 1)
}

const savePreQualTermsAndOptionallyUpdateStatus = async (preQualTerms: object, committed: boolean = false) => {
    const payload = { preQualTerms, committed }
    return await httpClient.post('/origination/savePreQualTermsAndOptionallyUpdateStatus', payload)
}

const workNumberIncomeValidated = async () => {
    return (await httpClient.get('/workNumberIncomeVerification'))?.data?.success
}

const verifyEmployerEmailCode = async (authCode: string, isCoApplicant?: boolean) => {
    const postBody = {
        authCode,
    }
    if (isCoApplicant) {
        return await coApplicantHttpClient.post('/verifyEmployerEmailCode', postBody)
    }
    return await httpClient.post('/verifyEmployerEmailCode', postBody)
}

const updateStatedUsage = async (statedUsageAmount: string, statedUsage: StatedUsageType | null) => {
    const postBody = {
        statedUsageAmount,
        statedUsage: statedUsage || null,
    }
    return await httpClient.post('/submitStatedUsageAmount', postBody)
}

export { savePreQualTermsAndOptionallyUpdateStatus, updateStatedIncome, updateEmployer, updateEmployerEmail, verifyEmployerEmailCode, updateStatedUsage, workNumberIncomeValidated }
