<template>
    <div>
        <p class="document-disclaimer">
            <span v-if="disclaimerTitle">{{ disclaimerTitle }} </span>
            <span
                v-for="(document, docIndex) in documents"
                :key="document.documentKey"
            >
                <span v-if="docIndex !== 0"> and </span>
                <a
                    href="#"
                    :data-testid="`document-disclaimer-download-link-${docIndex}`"
                    @click="() => openDocumentByKey(document.documentKey, document.documentTitle)"
                >{{
                    document.documentTitle
                }}</a>
            </span>
        </p>
        <p
            v-if="documentSubtitle"
            class="form-check-label mt-1 mb-0 text-muted small"
        >
            {{ documentSubtitle }}
        </p>
    </div>
</template>

<script>
    import { getUniqueNumber } from '../../utils/number-utils'
    import { httpClient } from '../../utils/http-client'
    import { openDocument } from '../../utils/document'

    export default {
        name: 'DocumentDisclosure',
        props: {
            documents: {
                type: Array,
            },
            disclaimerTitle: {
                type: String,
            },
        },
        data() {
            const componentId = `required-document-ack-${getUniqueNumber()}`
            return {
                componentId,
            }
        },
        methods: {
            getValue() {
                return {
                    type: 'DocumentDisclosure',
                }
            },
            async openDocumentByKey(documentKey, documentTitle) {
                const link = `/stateModules/document?documentKey=${documentKey}`
                const pdfDocument = await httpClient.get(link, {
                    responseType: 'blob',
                })

                openDocument(pdfDocument.data, documentTitle)
            },
        },
    }
</script>

<style scoped>
    .document-disclaimer {
        font-size: small;
    }
</style>
