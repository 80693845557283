<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="ssnValidation"
        >
            <div class="input form-floating">
                <p
                    v-if="prompt || errors.length > 0"
                    class="text-start fw-bold"
                >
                    {{ prompt }}
                </p>
                <input
                    type="tel"
                    inputmode="decimal"
                    class="form-control has-lock-icon"
                    :id="name"
                    v-mask="mask"
                    :placeholder="ssnLabel"
                    :class="{ 'is-invalid': errors[0] }"
                    :name="name"
                    v-model="ssnValue"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                >
                <label :for="name">
                    {{ ssnLabel }}
                </label>
                <div class="invalid-feedback text-start">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormSsnInput',
        mixins: [formInputMixin],
        props: {
            lastFourOnly: { type: Boolean, default: false },
            prompt: { type: String, default: '' },
            required: { type: Boolean, default: true },
        },
        data() {
            return {
                // You must set 'mask' to be a regular variable (not a function)
                // Otherwise you will cause an infinite loop with vee-validate
                // See FormFieldCurrency.vue for more details
                // https://github.com/probil/v-mask/issues/511
                mask: this.lastFourOnly ? 'XXX-XX-####' : '###-##-####',
                ssnValue: '',
                ssnLabel: this.lastFourOnly ? i18n.t('components.formFieldSsn.placeholderLast4') : i18n.t('components.formFieldSsn.placeholder'),
            }
        },
        computed: {
            ssnValidation() {
                const ssnValidation = this.lastFourOnly ? 'ssnLast4' : 'ssnFull9'
                const requiredValidation = this.required ? 'required|' : ''
                return `${requiredValidation}|${ssnValidation}`
            },
        },
        watch: {
            ssnValue: function (newValue, oldValue) {
                logger.log(`ssnValue: ${oldValue} -> ${newValue}`)
                const filteredValue = newValue?.replace(/[-X]/gi, '')
                this.$emit('input', filteredValue)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
