<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/signers-icon.svg"
                alt="Other Signers"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.otherSignersListItem.title') }}
            </p>
            <div class="text-muted">
                {{ $t('components.onboarding.otherSignersListItem.subTitle') }}
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'WhyOtherSignersListItem',
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
