<template>
    <div>
        <div>
            <h3
                class="section-header"
                :data-testid="`${formName}-hmda-applicant-name`"
            >
                {{ applicantName }}
            </h3>
        </div>
        <div v-if="submitted">
            <p
                class="fw-bold"
                :data-testid="`${formName}-hmda-form-completed`"
            >
                Completed
            </p>
        </div>
        <div v-else>
            <form-container :id="baseId">
                <!-- Sex -->
                <div :id="`${baseId}-sex`">
                    <p class="fw-bold">
                        {{ $t('pages.origination.hmda.sex.placeholder') }}
                    </p>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="sex"
                            value="female"
                            :id="`${baseId}-femaleSex`"
                            v-model="sex"
                        >
                        <label
                            class="custom-control-label"
                            :for="`${baseId}-femaleSex`"
                            :data-testid="`${formName}-sex-female`"
                        >{{ $t('pages.origination.hmda.sex.female') }} </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="sex"
                            value="male"
                            :id="`${baseId}-maleSex`"
                            v-model="sex"
                        >
                        <label
                            :for="`${baseId}-maleSex`"
                            class="custom-control-label"
                            :data-testid="`${formName}-sex-male`"
                        >
                            {{ $t('pages.origination.hmda.sex.male') }}
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="sex"
                            value="none"
                            :id="`${baseId}-noSex`"
                            v-model="sex"
                        >
                        <label
                            :for="`${baseId}-noSex`"
                            class="custom-control-label"
                            :data-testid="`${formName}-sex-none`"
                        >{{ $t('pages.origination.hmda.dontProvide') }}</label>
                    </div>
                </div>

                <!-- Ethnicity -->
                <div
                    :id="`${baseId}-ethnicity`"
                    class="mt-4"
                >
                    <p class="fw-bold">
                        {{ $t('pages.origination.hmda.ethnicity.placeholder') }}
                    </p>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="ethnicity"
                            value="hispanic"
                            :id="`${baseId}-hispanicPlaceholder`"
                            v-model="rawEthnicity"
                        >
                        <label
                            :for="`${baseId}-hispanicPlaceholder`"
                            class="custom-control-label"
                            :data-testid="`${formName}-ethnicity-hispanic`"
                        >{{
                            $t('pages.origination.hmda.ethnicity.hispanic.placeholder')
                        }}</label>
                        <div class="subRadio">
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hispanicEthnicity"
                                    value="mexican"
                                    :id="`${baseId}-mexicanEthnicity`"
                                    v-model="rawHispanicEthnicity"
                                >
                                <label
                                    :for="`${baseId}-mexicanEthnicity`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-ethnicity-hispanic-mexican`"
                                >
                                    {{ $t('pages.origination.hmda.ethnicity.hispanic.mexican') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hispanicEthnicity"
                                    value="puertoRican"
                                    :id="`${baseId}-puertoRicanEthnicity`"
                                    v-model="rawHispanicEthnicity"
                                >
                                <label
                                    :for="`${baseId}-puertoRicanEthnicity`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-ethnicity-hispanic-puertoRican`"
                                >
                                    {{ $t('pages.origination.hmda.ethnicity.hispanic.puertoRican') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hispanicEthnicity"
                                    value="cuban"
                                    :id="`${baseId}-cubanEthnicity`"
                                    v-model="rawHispanicEthnicity"
                                >
                                <label
                                    :for="`${baseId}-cubanEthnicity`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-ethnicity-hispanic-cuban`"
                                >{{
                                    $t('pages.origination.hmda.ethnicity.hispanic.cuban')
                                }}</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hispanicEthnicity"
                                    value="otherHispanicEthnicity"
                                    :id="`${baseId}-otherHispanicEthnicityPlaceholder`"
                                    v-model="rawHispanicEthnicity"
                                >
                                <label
                                    :for="`${baseId}-otherHispanicEthnicityPlaceholder`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-ethnicity-hispanic-other`"
                                >
                                    {{ $t('pages.origination.hmda.ethnicity.hispanic.other.placeholder') }}
                                </label>
                                <div class="subRadio">
                                    <form-field
                                        v-model="otherHispanicEthnicity"
                                        class="form-field mb-2"
                                        validation-mode="passive"
                                        validation-rules="min:2"
                                        name="otherHispanicEthnicity"
                                        :data-testid="`${formName}-ethnicity-hispanic-other-freeformInput`"
                                        :placeholder="$t('pages.origination.hmda.ethnicity.hispanic.other.hint')"
                                        :label="$t('pages.origination.hmda.ethnicity.hispanic.other.hint')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="ethnicity"
                            value="notHispanic"
                            :id="`${baseId}-notHispanicEthnicity`"
                            v-model="rawEthnicity"
                        >
                        <label
                            :for="`${baseId}-notHispanicEthnicity`"
                            class="custom-control-label"
                            :data-testid="`${formName}-ethnicity-notHispanic`"
                        >
                            {{ $t('pages.origination.hmda.ethnicity.notHispanic') }}
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="ethnicity"
                            value="none"
                            :id="`${baseId}-noEthnicity`"
                            v-model="rawEthnicity"
                        >
                        <label
                            :for="`${baseId}-noEthnicity`"
                            class="custom-control-label"
                            :data-testid="`${formName}-ethnicity-noEthnicity`"
                        >
                            {{ $t('pages.origination.hmda.dontProvide') }}
                        </label>
                    </div>
                </div>

                <!-- Race -->
                <div
                    :id="`${baseId}-race`"
                    class="mt-4"
                >
                    <p class="fw-bold">
                        {{ $t('pages.origination.hmda.race.placeholder') }}
                    </p>
                    <!-- American Indian -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            value="americanIndianRace"
                            :id="`${baseId}-americanIndianRacePlaceholder`"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-americanIndianRacePlaceholder`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-americanIndian`"
                        >
                            {{ $t('pages.origination.hmda.race.americanIndian.placeholder') }}
                        </label>
                        <div class="subRadio">
                            <form-field
                                v-model="otherAmericanIndianRace"
                                class="form-field mb-2"
                                validation-mode="passive"
                                validation-rules="min:2"
                                name="americanIndianRace"
                                :placeholder="$t('pages.origination.hmda.race.americanIndian.hint')"
                                :label="$t('pages.origination.hmda.race.americanIndian.hint')"
                                :data-testid="`${formName}-race-americanIndian-freeformInput`"
                            />
                        </div>
                    </div>
                    <!-- Asian -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            :id="`${baseId}-asianRacePlaceholder`"
                            value="asian"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-asianRacePlaceholder`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-asian`"
                        >
                            {{ $t('pages.origination.hmda.race.asian.placeholder') }}
                        </label>
                        <div class="subRadio">
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="asianIndian"
                                    :id="`${baseId}-asianIndianRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-asianIndianRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-asianIndian`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.asianIndian') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="chinese"
                                    :id="`${baseId}-chineseRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-chineseRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-chinese`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.chinese') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="filipino"
                                    :id="`${baseId}-filipinoRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-filipinoRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-filipino`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.filipino') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="japanese"
                                    :id="`${baseId}-japaneseRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-japaneseRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-japanese`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.japanese') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="korean"
                                    :id="`${baseId}-koreanRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-koreanRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-korean`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.korean') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="vietnamese"
                                    :id="`${baseId}-vietnameseRace`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-vietnameseRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-vietnamese`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.vietnamese') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="asianRace"
                                    value="otherAsianRace"
                                    :id="`${baseId}-otherAsianRacePlaceholder`"
                                    v-model="rawAsianRace"
                                >
                                <label
                                    :for="`${baseId}-otherAsianRacePlaceholder`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-asian-other`"
                                >
                                    {{ $t('pages.origination.hmda.race.asian.other.placeholder') }}
                                </label>
                                <div class="subRadio">
                                    <form-field
                                        v-model="otherAsianRace"
                                        class="form-field mb-2"
                                        validation-mode="passive"
                                        validation-rules="min:2"
                                        name="otherAsianRace"
                                        :placeholder="$t('pages.origination.hmda.race.asian.other.hint')"
                                        :label="$t('pages.origination.hmda.race.asian.other.hint')"
                                        :data-testid="`${formName}-race-asian-other-freeformInput`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Black -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            value="black"
                            :id="`${baseId}-blackRace`"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-blackRace`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-black`"
                        >
                            {{ $t('pages.origination.hmda.race.black') }}
                        </label>
                    </div>
                    <!-- hawaiian -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            value="hawaiian"
                            :id="`${baseId}-hawaiianRacePlaceholder`"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-hawaiianRacePlaceholder`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-hawaiian`"
                        >
                            {{ $t('pages.origination.hmda.race.hawaiian.placeholder') }}
                        </label>
                        <div class="subRadio">
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hawaiianRace"
                                    value="nativeHawaiian"
                                    :id="`${baseId}-nativeHawaiianRace`"
                                    v-model="rawHawaiianRace"
                                >
                                <label
                                    :for="`${baseId}-nativeHawaiianRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-hawaiian-nativeHawaiian`"
                                >
                                    {{ $t('pages.origination.hmda.race.hawaiian.native') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hawaiianRace"
                                    value="guamanian"
                                    :id="`${baseId}-guamanianRace`"
                                    v-model="rawHawaiianRace"
                                >
                                <label
                                    :for="`${baseId}-guamanianRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-hawaiian-guamanian`"
                                >
                                    {{ $t('pages.origination.hmda.race.hawaiian.guamanian') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hawaiianRace"
                                    value="samoan"
                                    :id="`${baseId}-samoanRace`"
                                    v-model="rawHawaiianRace"
                                >
                                <label
                                    :for="`${baseId}-samoanRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-hawaiian-samoan`"
                                >
                                    {{ $t('pages.origination.hmda.race.hawaiian.samoan') }}
                                </label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="hawaiianRace"
                                    value="otherHawaiianRace"
                                    :id="`${baseId}-otherHawaiianRace`"
                                    v-model="rawHawaiianRace"
                                >
                                <label
                                    :for="`${baseId}-otherHawaiianRace`"
                                    class="custom-control-label"
                                    :data-testid="`${formName}-race-hawaiian-other`"
                                >
                                    {{ $t('pages.origination.hmda.race.hawaiian.other.placeholder') }}
                                </label>
                                <div class="subRadio">
                                    <form-field
                                        v-model="otherHawaiianRace"
                                        class="form-field mb-2"
                                        validation-mode="passive"
                                        validation-rules="min:2"
                                        name="otherHawaiianRace"
                                        :placeholder="$t('pages.origination.hmda.race.hawaiian.other.hint')"
                                        :label="$t('pages.origination.hmda.race.hawaiian.other.hint')"
                                        :data-testid="`${formName}-race-hawaiian-other-freeformInput`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- White -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            value="white"
                            :id="`${baseId}-whiteRace`"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-whiteRace`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-white`"
                        >
                            {{ $t('pages.origination.hmda.race.white') }}
                        </label>
                    </div>
                    <!-- None -->
                    <div class="custom-control custom-radio">
                        <input
                            class="custom-control-input"
                            type="radio"
                            name="race"
                            value="none"
                            :id="`${baseId}-noRace`"
                            v-model="rawRace"
                        >
                        <label
                            :for="`${baseId}-noRace`"
                            class="custom-control-label"
                            :data-testid="`${formName}-race-noRace`"
                        >
                            {{ $t('pages.origination.hmda.dontProvide') }}
                        </label>
                    </div>
                </div>
            </form-container>
        </div>
    </div>
</template>

<script>
    import { getUniqueNumber } from '../../utils/number-utils'
    import { logger } from '@/utils/logger'
    import FormContainer from '@/components/base/FormContainer.vue'
    import FormField from '@/components/base/FormField.vue'

    export default {
        name: 'HMDAForm',
        props: {
            applicantName: {
                type: String,
                required: true,
            },
            formName: {
                type: String,
                required: true,
            },
        },
        components: {
            'form-field': FormField,
            'form-container': FormContainer,
        },
        data() {
            return {
                baseId: `hmda-form-${getUniqueNumber()}`,
                submitted: false,
                sex: null,
                rawEthnicity: null,
                rawHispanicEthnicity: null,
                otherHispanicEthnicity: '',
                otherAmericanIndianRace: '',
                otherAsianRace: '',
                otherHawaiianRace: '',
                rawRace: null,
                rawAsianRace: null,
                rawHawaiianRace: null,
            }
        },
        computed: {
            race: function () {
                if (this.rawRace === 'americanIndianRace') {
                    if (this.otherAmericanIndianRace === '') return 'americanIndian-other'
                    return 'americanIndian-' + this.otherAmericanIndianRace
                }

                if (this.rawRace === 'asian' && this.rawAsianRace) {
                    if (this.rawAsianRace === 'otherAsianRace') {
                        if (this.otherAsianRace === '') return 'asian-other'
                        return 'asian-' + this.otherAsianRace
                    }

                    return this.rawAsianRace
                }

                if (this.rawRace === 'hawaiian' && this.rawHawaiianRace) {
                    if (this.rawHawaiianRace === 'otherHawaiianRace') {
                        if (this.otherHawaiianRace === '') return 'hawaiian-other'
                        return 'hawaiian-' + this.otherHawaiianRace
                    }

                    return this.rawHawaiianRace
                }

                return this.rawRace
            },
            ethnicity: function () {
                if (this.rawEthnicity === 'hispanic' && this.rawHispanicEthnicity) {
                    if (this.rawHispanicEthnicity === 'otherHispanicEthnicity') {
                        if (this.otherHispanicEthnicity === '') return 'hispanic-other'
                        return 'hispanic-' + this.otherHispanicEthnicity
                    }

                    return this.rawHispanicEthnicity
                }

                return this.rawEthnicity
            },
        },
        methods: {
            getAnswers() {
                logger.info(`Sex: ${this.sex}, Ethnicity: ${this.ethnicity}, Race: ${this.race}`)
                return {
                    sex: this.sex,
                    ethnicity: this.ethnicity,
                    race: this.race,
                }
            },
            isComplete() {
                return !!this.sex && !!this.ethnicity && !!this.race
            },
            skipNoSubmit() {
                logger.log(`Skipping ${this.applicantName} questions...`)
                this.sex = 'none'
                this.rawEthnicity = 'none'
                this.rawRace = 'none'
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-radio {
        margin-bottom: $spacer;
    }
    .subRadio {
        display: none;
    }
    :checked ~ .subRadio {
        display: block;
        margin-top: $spacer;
    }
</style>
