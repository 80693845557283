<template>
    <onboarding-layout
        :loading="loading"
        :error-text="errorText"
        loading-title="Finalizing lookup..."
        data-testid="property-verification-page"
    >
        <h5 class="fw-light mb-0">
            {{ $t('pages.origination.propertyVerification.title') }}
        </h5>
        <div data-testid="property-verification-cards">
            <div class="section-header text-center fw-bold mt-5">
                {{ $t('pages.origination.propertyVerification.pickOne') }}
            </div>
            <div class="row mt-3">
                <div class="col pe-1">
                    <document-verification-option-card
                        :class="{ selected: selectedPropertyVerificationDocument === PropertyVerificationDocument.utiliyBill }"
                        image-src="global/utility.svg"
                        image-alt="utilities"
                        :title="$t('pages.origination.propertyVerification.utilityBill.button')"
                        @on-click="selectedPropertyVerificationDocument = PropertyVerificationDocument.utiliyBill"
                        data-testid="property-verification-utility-bill-option"
                    />
                </div>
                <div class="col ps-1">
                    <document-verification-option-card
                        :class="{ selected: selectedPropertyVerificationDocument === PropertyVerificationDocument.insuranceDocument }"
                        image-src="global/insurance.svg"
                        image-alt="insurance"
                        :title="$t('pages.origination.propertyVerification.insurance.button')"
                        @on-click="selectedPropertyVerificationDocument = PropertyVerificationDocument.insuranceDocument"
                        data-testid="property-verification-insurance-option"
                    />
                </div>
            </div>
        </div>

        <div v-show="selectedPropertyVerificationDocument === PropertyVerificationDocument.utiliyBill">
            <upload-button
                class="mb-2 mt-1"
                :title="$t('pages.origination.propertyVerification.utilityBill.upload')"
                @on-file-change="uploadUtilityBill($event, 1)"
                :is-complete="hasUtilityBillUploaded"
                :is-submitting="isUtilityBillUploading"
                :disabled="!!currentlyUploadingIndex"
                incomplete-text="Add"
                complete-text="✓ Added"
                ref="utilityBillUpload"
                accept="image/*,application/pdf"
            />
        </div>

        <div v-show="selectedPropertyVerificationDocument === PropertyVerificationDocument.insuranceDocument">
            <upload-button
                class="mb-2 mt-1"
                :title="$t('pages.origination.propertyVerification.insurance.upload')"
                @on-file-change="uploadInsurance($event, 1)"
                :is-complete="hasInsuranceUploaded"
                :is-submitting="isInsuranceUploading"
                :disabled="!!currentlyUploadingIndex"
                incomplete-text="Add"
                complete-text="✓ Added"
                ref="insuranceUpload"
                accept="image/*,application/pdf"
            />
        </div>

        <div v-if="selectedPropertyVerificationDocument">
            {{ $t('pages.origination.propertyVerification.documentData') }}
            <li v-html="fullNameContent" />
            <li v-html="propertyAddressContent" />
        </div>
        <form-button
            v-if="hasDocumentUploaded(1)"
            type="button"
            :label="$t('pages.origination.propertyVerification.cta')"
            class="mt-3"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_property_verification_continue"
            data-testid="property-verification-continue-button"
        />

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <feedback-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import { misc } from '@/mixins/misc'
    import originationMixin from '@/mixins/originationMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import DocumentVerificationOptionCard from '@/components/DocumentVerificationOptionCard'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import { logger } from '@/utils/logger'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { ResidenceType, uploadDocument } from '@/services/api'
    import UploadButton from '@/components/UploadButton'
    import { i18n } from '@/utils/i18n'
    import { getPropertyDetails } from '@/services/homeApi'

    const PropertyVerificationDocument = {
        utiliyBill: 'utilityBill',
        insuranceDocument: 'insuranceDocument',
    }

    export default {
        name: 'PropertyVerification',
        components: {
            FeedbackListItem,
            DocumentVerificationOptionCard,
            UploadButton,
            FormButton,
            OnboardingLayout,
            OfferInfoCard,
        },
        mixins: [misc, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin],
        data: function () {
            return {
                loading: true,
                PropertyVerificationDocument: PropertyVerificationDocument,
                selectedPropertyVerificationDocument: null,
                isUtilityBillUploading: false,
                hasUtilityBillUploaded: false,
                isInsuranceUploading: false,
                hasInsuranceUploaded: false,
                addressStreet: '',
                addressUnit: '',
                addressCity: '',
                addressState: '',
                addressPostalCode: '',
                residenceType: '',
            }
        },
        mounted: async function () {
            const propertyDetailsResponse = await getPropertyDetails()
            /**
             * propertyDetails = {
             *      "addressStreet": "204 Shoreview Ave",
             *      "addressUnit": null,
             *      "addressCity": "Pacifica",
             *      "addressState": "CA",
             *      "addressPostalCode": "94044",
             *      "residenceType": "SECONDARY",
             * }
             */
            const { residenceType, ...propertyAddressData } = propertyDetailsResponse.data.payload

            // Document upload for property verification is only enabled for NOO properties today
            if (residenceType !== ResidenceType.SECONDARY) {
                logger.log(`This is a primary property... continuing!`)
                return await this.next()
            }
            logger.log(`This is a secondary property!... saving property details`)

            const basicInfo = {
                propertyAddressData,
                residenceType,
            }
            appSessionStorage.setItem(sessionStorageKey.basicInfo, JSON.stringify(basicInfo))
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)

            this.residenceType = residenceType
            this.addressStreet = propertyAddressData.addressStreet
            this.addressUnit = propertyAddressData.addressUnit
            this.addressCity = propertyAddressData.addressCity
            this.addressState = propertyAddressData.addressState
            this.addressPostalCode = propertyAddressData.addressPostalCode
            console.log(`Property details saved!`)

            try {
                this.loading = false
                this.$logEvent('view_property_verification')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            primaryFullName: function () {
                return `<b>${this.primaryFirstName + ' ' + this.primaryLastName}</b>`
            },
            secondaryFullName: function () {
                return `<b>${this.secondaryFirstName + ' ' + this.secondaryLastName}</b>`
            },
            fullNameContent: function () {
                let fullNameContent = this.primaryFullName
                if (this.hasCoApplicant) {
                    fullNameContent += ' or ' + this.secondaryFullName
                }
                return fullNameContent
            },
            propertyAddressContent: function () {
                return `<b>${this.addressStreet}${this.addressUnit ? ' #' + this.addressUnit : ''}, ${this.addressCity}, ${this.addressState} ${this.addressPostalCode}</b>`
            },
        },
        methods: {
            uploadUtilityBill: async function (fileList, index) {
                this.$logEvent('click_button_upload_utility_bill')
                this.errorText = ''
                this.isUtilityBillUploading = true
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Utility bill was not selected.')
                        this.errorText = i18n.t('pages.origination.propertyVerification.utilityBill.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const utilityBillFileInfo = this.getFileInfo(file)
                    if (utilityBillFileInfo === appSessionStorage.getItem(sessionStorageKey.utilityBillInfo)) {
                        logger.warn('Utility bill file already exists in storage')
                        this.errorText = i18n.t('pages.origination.propertyVerification.utilityBill.errorDuplicate')
                        this.scrollToTop()
                        return
                    }
                    const response = await uploadDocument(file, PropertyVerificationDocument.utiliyBill, index, false)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.t('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = utilityBillFileInfo
                    appSessionStorage.setItem(sessionStorageKey.utilityBillInfo, JSON.stringify([utilityBillFileInfo]))
                    this.hasUtilityBillUploaded = true
                } catch (error) {
                    logger.error('Failed to upload utility bill', error)
                    this.errorText = i18n.t('pages.origination.propertyVerification.utilityBill.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                    this.isUtilityBillUploading = false
                }
            },
            uploadInsurance: async function (fileList, index) {
                this.$logEvent('click_button_upload_insurance_document')
                this.errorText = ''
                this.isInsuranceUploading = true
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Insurance document was not selected.')
                        this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const insuranceFileInfo = this.getFileInfo(file)
                    if (insuranceFileInfo === appSessionStorage.getItem(sessionStorageKey.insuranceInfo)) {
                        logger.warn('Insurance document already exists in storage')
                        this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorDuplicate')
                        this.scrollToTop()
                        return
                    }
                    const response = await uploadDocument(file, PropertyVerificationDocument.insuranceDocument, index, false)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.t('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = insuranceFileInfo
                    appSessionStorage.setItem(sessionStorageKey.insuranceInfo, JSON.stringify([insuranceFileInfo]))
                    this.hasInsuranceUploaded = true
                } catch (error) {
                    logger.error('Failed to upload insurance document', error)
                    this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                    this.isInsuranceUploading = false
                }
            },
            next: async function () {
                return await this.$router.push(getNextRoute(this.$router))
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/document-verification-selection-card';
</style>
