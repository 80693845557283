<template>
    <onboarding-layout
        :loading="true"
        :error-text="errorText"
    />
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import originationMixin from '@/mixins/originationMixin'
    import { getForwardToNotarizationLink } from '@/services/schedulingApi'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        mixins: [originationMixin],
        mounted: async function () {
            try {
                const forwardToNotarizationLinkResponse = await getForwardToNotarizationLink()
                const forwardToNotarizationLink = forwardToNotarizationLinkResponse.data.payload
                logger.info(`Redirecting to notary system @ ${forwardToNotarizationLink}...`)
                window.location = forwardToNotarizationLink
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
    }
</script>
