<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-calendar.svg"
                alt="Monthly Payment"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.monthlyPaymentListItem.title') }}
            </p>
            <div class="text-muted">
                <p>You have two options for monthy payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p>
                    Here is an example if you use <strong>{{ lineSizeFormatted }}</strong> at your <strong>{{ interestRateFixed }}% rate</strong>.
                </p>
                <div class="bg-gray-100 rounded-3 p-0 border-1 text-dark">
                    <div class="section-header fw-bold p-1 text-center bg-dark text-white header-rounded">
                        Standard Min Payment
                    </div>
                    <div class="d-flex flex-row justify-content-between pt-2 px-2">
                        <div class="col-4 fw-bold border-secondary">
                            First<br>Payment
                        </div>
                        <div class="col-4 text-end border-secondary">
                            Total<br>Paid
                        </div>
                        <div class="col-4 text-end border-secondary">
                            Months<br>Payback
                        </div>
                    </div>
                    <hr class="bg-dark">
                    <div class="d-flex flex-row justify-content-between px-2">
                        <div class="col-4 fw-bold border-0">
                            {{ firstMinPaymentFormatted }}
                        </div>
                        <div class="col-4 border-0 text-end">
                            {{ totalFormatted }}
                        </div>
                        <div class="col-4 border-0 text-end">
                            {{ totalPayments }}
                        </div>
                    </div>
                    <p class="small mb-0 text-muted p-2">
                        Your minimum is based on 1% of your balance, so your monthly payments will go down as you pay down your balance.
                    </p>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import format from '@/mixins/format'
    import { calculateTotalPlusBtOrCoFee } from '@/utils/paymentUtils'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        name: 'MonthlyPaymentListItem',
        props: {
            lineSize: { type: Number, required: true },
            apr: { type: Number, required: true },
            paymentSize: { type: Number, required: true },
            totalPayments: { type: Number, required: true },
            totalPaid: { type: Number, required: true },
        },
        mixins: [format, experimentsMixin],
        computed: {
            lineSizeFormatted: function () {
                return this.toFormattedUSDNoCents(this.lineSize)
            },
            firstMinPaymentFormatted: function () {
                return this.toFormattedUSD(this.paymentSize)
            },
            totalFormatted: function () {
                return this.toFormattedUSD(calculateTotalPlusBtOrCoFee(this.totalPaid, this.lineSize, this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT))
            },
            interestRateFixed: function () {
                return (this.apr * 100).toFixed(2)
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
