<template>
    <onboarding-layout
        :title="title"
        :sub-title="subTitle"
        :error-text="errorText"
        :active-step="3"
        step-title="Tax Documents"
    >
        <div class="card bg-light rounded-3 mb-2">
            <img
                class="card-img rounded-3"
                src="../../assets/images/origination/TaxForm.svg"
                alt="Tax Form Example"
                width="100%"
            >
            <div class="card-body pt-0">
                <div
                    class="card-text text-gray-500 small text-center text-md-start"
                    v-html="imageText"
                />
            </div>
        </div>
        <upload-button
            class="mb-2"
            title="First Page"
            @on-file-change="uploadTaxReturn($event, 1)"
            :is-complete="hasDocumentUploaded(1)"
            :is-submitting="isDocumentUploading(1)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add Photo"
            complete-text="✓ Added"
            ref="taxReturnUpload"
            accept="image/*,application/pdf"
        />
        <form-button
            v-if="hasDocumentUploaded(1)"
            type="button"
            label="Continue"
            class="mt-2"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_continue_verify_tax_return"
        />
        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { mixins } from '@/mixins/mixins'
    import { i18n } from '@/utils/i18n'
    import FormButton from '@/components/base/FormButton'
    import UploadButton from '@/components/UploadButton'
    import { getNextRoute } from '@/flow/flowController'
    import { beginTaxReturnVerification, uploadDocument } from '@/services/api'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        components: {
            FeedbackListItem,
            OnboardingLayout,
            'form-button': FormButton,
            'upload-button': UploadButton,
            'offer-info-card': OfferInfoCard,
        },
        mixins: [mixins, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                imageText: i18n.t('taxReturnUpload.imageText'),
            }
        },
        computed: {
            title() {
                if (!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                    // Individual applicant
                    return i18n.t('taxReturnUpload.title')
                } else {
                    // Primary or co-applicant
                    return i18n.t('taxReturnUpload.titleWithCoApplicant', { firstName: this.verifyingApplicantName })
                }
            },
            subTitle() {
                return this.$route.query.verificationInProgress ? i18n.t('pages.shared.incomeVerification.verificationInProgress', { uploadedDocumentDescriptor: 'your tax return' }) : null
            },
        },
        mounted: function () {
            let previousUploadInfo = appSessionStorage.getItem(sessionStorageKey.taxReturnInfo)
            if (previousUploadInfo) {
                this.uploadFileList = JSON.parse(previousUploadInfo).map((info) => !!info)
            } else {
                appSessionStorage.setItem(sessionStorageKey.taxReturnInfo, JSON.stringify([null]))
                this.uploadFileList = [null]
            }

            this.$logEvent('view_tax_return_verification')
        },
        methods: {
            uploadTaxReturn: async function (fileList, index) {
                this.$logEvent('click_button_upload_tax_return')
                this.errorText = ''
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Tax return photo was not selected.')
                        this.errorText = i18n.tc('taxReturnUpload.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const taxReturnFileInfo = this.getFileInfo(file)
                    if (taxReturnFileInfo === appSessionStorage.getItem(sessionStorageKey.taxReturnInfo)) {
                        logger.warn('Tax return file already exists in storage')
                        this.errorText = i18n.tc('taxReturnUpload.errorDuplicate')
                        this.scrollToTop()
                        return
                    }

                    const response = await uploadDocument(file, 'taxReturn', index, this.isCoApplicantVerifyingIncome)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.tc('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = taxReturnFileInfo
                    appSessionStorage.setItem(sessionStorageKey.taxReturnInfo, JSON.stringify([taxReturnFileInfo]))
                } catch (error) {
                    logger.error('Failed to upload tax return', error)
                    this.errorText = i18n.tc('taxReturnUpload.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                }
            },
            next: async function () {
                if (this.submitting) return
                this.submitting = true
                this.errorText = ''

                try {
                    await beginTaxReturnVerification(this.isCoApplicantVerifyingIncome)
                    appSessionStorage.setItem(sessionStorageKey.incomeVerificationCompleted, 'true')
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                    this.submitting = false
                }
            },
        },
    }
</script>
