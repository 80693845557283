<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-happy-face.svg"
                alt="Short Process Assurance"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.shortProcessAssuranceListItem.title') }}
            </p>
            <div
                class="text-muted"
                v-html="$t('components.onboarding.shortProcessAssuranceListItem.subTitle')"
            />
            <div
                v-show="isExtendedLinesOffer"
                class="text-muted"
                v-html="$t('components.onboarding.shortProcessAssuranceListItem.over100K')"
            />
        </div>
    </li>
</template>

<script>
    export default {
        name: 'ShortProcessAssuranceListItem',
        props: {
            isExtendedLinesOffer: {
                type: Boolean,
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
