<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validation"
        >
            <div class="input form-floating">
                <input
                    type="tel"
                    class="form-control"
                    :id="name"
                    v-mask="'##/##/####'"
                    :class="{ 'is-invalid': errors[0], 'is-valid': valid }"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    inputmode="decimal"
                    autocomplete="date"
                    v-model="dateValue"
                    @input="onInput($event.target)"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                >
                <label
                    v-if="dynamicPlaceholder"
                    :for="name"
                >{{ dynamicPlaceholder }}</label>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormFieldDate',
        mixins: [formInputMixin],
        props: {
            validation: { type: String, default: 'required|dateRule' },
            options: {
                type: Object,
                default: function () {
                    return { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] }
                },
            },
        },
        data() {
            return {
                dateValue: '',
            }
        },
    }
</script>
