<template>
    <div>
        <modal
            v-if="preQualOffers && preQualOffers.length > 0"
            :open="modalOpen"
            @on-modal-close-click="closeModal"
        >
            <h5 class="text-center fw-bold fs-5 mb-3">
                {{ $t('pages.origination.preQualification.qualifyForUpToModal') }}
            </h5>

            <h1 class="text-center">
                {{ toFormattedUSDStripTrailingZeroCents(maxOfferLineSize) }}
            </h1>

            <p class="text-center mb-3">
                {{ $t('pages.origination.preQualification.selectPreferredOffer') }}
            </p>

            <div
                class="d-grid mb-3"
                v-for="(offer, index) in preQualOffers"
                :key="index"
            >
                <button
                    :data-testid="`prequal-info-select-offer-${index}`"
                    class="btn btn-outline-primary text-start position-relative"
                    :class="selectedOfferIndex == index ? 'border-dark border-2 outline-0' : 'border-secondary'"
                    @click="selectOffer(index)"
                >
                    <span>
                        <span class="fw-bold">
                            {{ toFormattedUSDStripTrailingZeroCents(offer.lineSize) }}
                        </span>
                        <span class="fw-normal">
                            {{ $t('pages.origination.preQualification.modalApr', { apr: formatApr(offer.apr) }) }}
                        </span>
                    </span>
                    <div
                        v-if="selectedOfferIndex == index"
                        class="bg-dark position-absolute end-0 bottom-0 rounded-2-diagonal"
                    >
                        <img
                            src="@/assets/images/origination/check-white.svg"
                            alt="Check Icon"
                        >
                    </div>
                </button>
            </div>

            <button
                class="mb-2 btn btn-primary w-100"
                data-testid="prequal-info-change-offer-continue"
                @click="onModalContinue"
            >
                {{ $t('pages.origination.preQualification.modalContinue') }}
            </button>
        </modal>
        <div
            v-if="preQualOffers && preQualOffers.length > 0"
            class="card card-shadow rounded-3 mt-2 p-3 px-md-5"
        >
            <div class="text-center">
                <h2
                    class="mt-4 mb-0"
                    data-testid="pre-qual-info-options-line-size"
                >
                    {{ toFormattedUSDStripTrailingZeroCents(selectedPreQualOffer.lineSize) }}
                </h2>

                <p class="fw-bold mb-0">
                    {{ $t('pages.origination.preQualification.creditLimit') }}
                </p>

                <p
                    v-if="!isTaxState && preQualOffers.length > 1"
                    class="small mb-0"
                >
                    <span v-if="selectedOfferIndex == 0">{{ $t('pages.origination.preQualification.qualifyForUpTo', { maxOffer: toFormattedUSDKs(maxOfferLineSize) }) }}</span>
                    &nbsp;
                    <a
                        class="text-underline cursor-pointer"
                        data-testid="prequal-info-change-offer-open-modal-button"
                        @click="openModal"
                    >{{
                        $t('pages.origination.preQualification.changeQuestion')
                    }}</a>?
                </p>

                <img
                    class="mt-4"
                    src="@/assets/images/origination/BlankCardVisa.jpg"
                    width="100%"
                    alt="Sample VISA Card"
                >
            </div>

            <div class="d-flex flex-column mt-4">
                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <div>
                        <p
                            class="mb-0"
                            v-html="$t('pages.origination.preQualification.apr')"
                        />
                        <div
                            class="small text-muted"
                            v-html="$t('pages.origination.offerPreview.autopayDiscount')"
                        />
                    </div>
                    <h5
                        class="mb-0"
                        data-testid="pre-qual-info-options-apr"
                        v-html="formatApr(selectedPreQualOffer.apr)"
                    />
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p
                        class="mb-0"
                        v-html="$t('pages.origination.preQualification.cashBack')"
                    />
                    <h5
                        class="mb-0"
                        v-html="toFormattedAprStripTrailingZerosAfterDecimalPoint(selectedPreQualOffer.cashBack)"
                    />
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.annualFee') }}
                    </p>
                    <h5 class="mb-0">
                        $0
                    </h5>
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.signUpFee') }}
                    </p>
                    <h5 class="mb-0">
                        $0
                    </h5>
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.recordingFee') }}
                    </p>
                    <div class="d-flex justify-content-end w-auto">
                        <h5
                            class="mb-0 text-muted text-strikethrough"
                            v-if="isTaxState"
                        >
                            {{ toFormattedUSDStripTrailingZeroCents(recordingCharges) }}
                        </h5>
                        <h5 class="mb-0 ms-1">
                            {{ toFormattedUSDStripTrailingZeroCents(effectiveRecordingCharges) }}
                        </h5>
                    </div>
                </div>
                <div
                    class="small text-muted"
                    v-if="isTaxState"
                >
                    <span> Don’t worry, we will cover it </span>
                </div>

                <div
                    v-if="overrideParameters.preQualificationPage.showCountdown"
                    class="rounded-2 bg-light d-flex p-2 mt-4"
                >
                    <img
                        class="me-2"
                        src="@/assets/images/origination/clock-green.svg"
                        alt="Clock Icon"
                    >
                    <div>
                        <div class="fw-bold text-green">
                            {{ $t('pages.origination.preQualification.timeRemaining', { time }) }}
                        </div>
                        <div class="small">
                            {{ $t('pages.origination.preQualification.secureYourOffer') }}
                        </div>
                    </div>
                </div>
                <!-- delete this when you delete override parameter above-->
                <div
                    v-else
                    class="mt-2"
                >
&nbsp;
                </div>

                <validation-observer ref="observer">
                    <state-form-module
                        ref="stateModules"
                        class="mt-4"
                        :data="stateModules"
                        v-if="stateModules && stateModules.modules && stateModules.modules.length > 0"
                    />
                </validation-observer>
            </div>

            <form-button
                class="mt-2"
                data-testid="prequal-info-options-continue-button"
                @click="submitPrequal"
                :submitting="submitting"
                :disabled="ctaButtonDisabled"
                event-name="click_button_pre_qualification_continue"
                :event-props="selectedPreQualOffer"
            >
                {{ $t('pages.origination.preQualification.getYourCard') }}
            </form-button>

            <safe-credit-score
                :safe-credit-score-text="$t('pages.marketing.landing.newSafeCreditScore')"
                class="mt-2 mb-4"
            />
        </div>
    </div>
</template>

<script>
    import { DateTime } from 'luxon'
    import format from '@/mixins/format'
    import FormButton from '@/components/base/FormButton'
    import Modal from '@/components/modal/Modal'
    import DividerLine from '@/components/DividerLine'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import StateFormModule from './state/StateFormModule'
    import { ValidationObserver } from 'vee-validate'
    import { getStatePageModules } from '../services/api'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        name: 'PreQualInfoOptions',
        components: {
            'form-button': FormButton,
            modal: Modal,
            'divider-line': DividerLine,
            'safe-credit-score': SafeCreditScore,
            'state-form-module': StateFormModule,
            'validation-observer': ValidationObserver,
        },
        mixins: [format, experimentsMixin],
        props: {
            preQualTerms: { type: Object },
            preQualOffers: { type: Array, default: [] },
            loanApplicationDate: { type: Date, require: false },
            choosePreQualTerms: { type: Function, require: true },
            onSubmit: { type: Function, require: true },
            submitting: { type: Boolean, default: false },
            isConfirmedFirstLienPosition: { type: Boolean, required: true },
        },
        data: function () {
            return {
                time: null,
                intervalId: null,
                modalOpen: false,
                selectedOfferIndex: 0,
                stateModules: {},
                ctaButtonDisabled: false,
            }
        },
        methods: {
            getStateModuleData: async function () {
                this.ctaButtonDisabled = true
                try {
                    const stateModulesResponse = await getStatePageModules('PreQual')
                    this.stateModules = stateModulesResponse.data.payload
                } finally {
                    this.ctaButtonDisabled = false
                }
            },
            openModal: function () {
                this.$logEvent('click_button_text_open_modal_select_prequal_options')
                this.modalOpen = true
            },
            closeModal: function () {
                this.$logEvent('click_button_close_modal_select_prequal_options')
                this.modalOpen = false
            },
            onModalContinue: async function () {
                await this.choosePreQualTerms(this.selectedPreQualOffer)
                this.$logEvent('click_button_continue_close_modal_select_prequal_options')
                this.modalOpen = false
            },
            selectOffer: function (index) {
                this.$logEvent('click_button_select_prequal_option', { prequalOffer: this.preQualOffers[index] })
                this.selectedOfferIndex = index
            },
            submitPrequal: async function () {
                const isValid = await this.$refs.observer.validate()
                if (!isValid) {
                    this.$refs.observer.$el.scrollIntoView()
                    return
                }

                if (this.$refs.stateModules) {
                    await this.$refs.stateModules.sendValuesToBackend()
                }

                this.onSubmit(this.selectedPreQualOffer)
            },
        },
        computed: {
            maxOfferLineSize: function () {
                if (this.preQualOffers && this.preQualOffers.length > 0) {
                    return Math.max(...this.preQualOffers.map((o) => o.lineSize))
                }

                return 0
            },
            selectedPreQualOffer: function () {
                return this.preQualOffers[this.selectedOfferIndex]
            },
            recordingCharges: function () {
                return this.selectedPreQualOffer.recordingCharges || 0
            },
            effectiveRecordingCharges: function () {
                return this.selectedPreQualOffer.effectiveRecordingCharges || 0
            },
            isTaxState: function () {
                return this.recordingCharges > 0
            },
        },
        watch: {
            selectedPreQualOffer: function () {
                this.$emit('prequalTermsSelection', this.selectedPreQualOffer)
            },
        },
        mounted: async function () {
            // If the user has selected an offer...
            if (this.preQualTerms) {
                // ...search offers for the offer's index
                for (const [index, offer] of this.preQualOffers.entries()) {
                    if (offer.policyName === this.preQualTerms.policyName) {
                        this.selectedOfferIndex = index
                        break
                    }
                }
            }

            await this.getStateModuleData()

            this.intervalId = setInterval(() => {
                const applicationStartDate = DateTime.fromJSDate(this.loanApplicationDate)
                const minutesSinceApplicationStartDate = DateTime.now().diff(applicationStartDate, ['minutes']).toObject().minutes
                const minutesUntilNextDeadline = 30 - (minutesSinceApplicationStartDate % 30)

                this.time = DateTime.now().plus({ minutes: minutesUntilNextDeadline }).diff(DateTime.now()).toFormat('mm:ss')
            }, 1000)
            this.$logEvent('view_prequal_options_modal')
        },
        beforeDestroy: function () {
            clearInterval(this.intervalId)
        },
    }
</script>

<style lang="scss" scoped>
    .cursor-pointer {
        cursor: pointer;
    }

    .custom-prime-pill {
        color: $black;
        background-color: #add6e3;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .rounded-2-diagonal {
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .text-green {
        color: #499f6e;
    }

    .bg-gray {
        background-color: #f2f4f5;
    }

    .outline-0:focus {
        outline: none;
        box-shadow: none;
    }
</style>
