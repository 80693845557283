<template>
    <div>
        <!--
      Having just the validation provider wrap the prompt and hidden input
      If provider wraps the for loop, it causes an infinite loop
      This also allows to show the error on the prompt
    -->
        <validation-provider
            mode="lazy"
            rules="required"
            v-slot="{ errors }"
        >
            <input
                :value="value"
                hidden
            >
            <p
                v-if="prompt || errors.length > 0"
                class="text-start fw-normal mx-3"
                :class="errors[0] ? 'text-danger' : null"
            >
                {{ prompt }}
                <span
                    class="fw-bold small block"
                    v-if="errors[0]"
                ><br v-if="prompt">{{ errors[0] }}</span>
            </p>
        </validation-provider>

        <div
            class="text-start"
            v-for="(option, index) in richOptions"
            :key="name + option.value + index"
            :class="{ 'form-check form-check-inline': inline }"
        >
            <div class="custom-control custom-radio">
                <input
                    type="radio"
                    class="custom-control-input"
                    :name="name"
                    :checked="value === option.value"
                    @change="onChange(option.value)"
                    :id="name + option.value + index"
                >
                <label
                    class="custom-control-label"
                    :for="name + option.value + index"
                    :data-testid="`${name}-radio-option-${index}`"
                >{{ option.name }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'

    export default {
        name: 'FormRadioGroup',
        props: {
            value: String,
            prompt: String,
            name: String,
            options: Array,
            inline: Boolean,
        },
        computed: {
            richOptions() {
                // The code below is meant to ensure backwards compatibility for those components that
                // pass an Array<string> rather than Array<{name:string, value:string}> as the "options"
                // prop.
                return this.options.map((option) => {
                    if (typeof option === 'string') {
                        return { name: option, value: option }
                    }
                    if (!option || typeof option !== 'object' || typeof option.name !== 'string' || typeof option.value !== 'string') {
                        throw new TypeError('options prop passed to FormRadioGroup must be an array containing any of either strings and/or objects of type: {name:string, value:string}')
                    }
                    return option
                })
            },
        },
        components: {
            'validation-provider': ValidationProvider,
        },
        methods: {
            onChange(newValue) {
                this.$emit('input', newValue)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-radio {
        margin-bottom: $spacer * 1.5;
    }
    :checked {
        display: block;
        margin-top: $spacer;
    }
</style>
