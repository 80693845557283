<template>
    <li
        v-if="shouldDisplayNoCreditCardCost"
        class="g-0 row mb-2"
    >
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/credit-card-visa.svg"
                alt=""
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.noCreditCardCostListItem.title') }}
            </p>
            <div
                class="text-muted"
                v-html="
                    shouldDisplayBodyWithNoBalanceTransferFee
                        ? $t('components.onboarding.noCreditCardCostListItem.bodyWithNoBalanceTransferFee')
                        : $t('components.onboarding.noCreditCardCostListItem.bodyWithBalanceTransfer')
                "
            />
        </div>
    </li>
</template>

<script>
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        name: 'NoCreditCardCostListItem',
        computed: {
            shouldDisplayNoCreditCardCost() {
                return this.heraclesParameter.BASE_AVEN_CARD_MONTHLY_FEE === 0 && this.heraclesParameter.ORIGINATION_FEE === 0
            },
            shouldDisplayBodyWithNoBalanceTransferFee() {
                return this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT === 0
            },
        },
        mixins: [experimentsMixin],
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';

    .list-item-icon {
        padding-top: 0.7rem;
    }
</style>
