<template>
    <div>
        <div
            v-for="(module, index) in modules"
            :key="`module${index}`"
        >
            <required-document-ack
                v-if="module.type === 'RequiredDocumentAck'"
                :ref="`module${index}`"
                :document-id="module.data.documentId"
                :document-title="module.data.documentTitle"
                :document-link="module.data.documentLink"
                :document-key="module.data.documentKey"
                :document-subtitle="module.data.documentSubtitle"
            />
            <document-disclosure
                v-if="module.type === 'DocumentDisclosure'"
                :ref="`module${index}`"
                :documents="module.data.documents"
                :disclaimer-title="module.data.disclaimerTitle"
            />
        </div>
    </div>
</template>

<script>
    import RequiredDocumentAck from './RequiredDocumentAck'
    import DocumentDisclosure from './DocumentDisclosure'
    import { saveStateModuleValues } from '../../services/api'

    export default {
        name: 'StateFormModule',
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        components: {
            'required-document-ack': RequiredDocumentAck,
            'document-disclosure': DocumentDisclosure,
        },
        computed: {
            modules: function () {
                const modules = this.data?.modules || []
                return modules
            },
        },
        methods: {
            getValues() {
                const refs = this.$refs
                return this.modules.map((module, index) => {
                    return refs[`module${index}`][0].getValue()
                })
            },
            async sendValuesToBackend() {
                const moduleValues = this.getValues()
                const payload = {
                    moduleValues,
                }
                return saveStateModuleValues(payload)
            },
        },
    }
</script>

<style scoped></style>
