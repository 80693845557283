<template>
    <onboarding-layout
        :title="title"
        :loading="loading"
        :loading-title="$t('global.loadingMessage.loading')"
        :error-text="errorText"
    >
        <div class="mb-2">
            <div class="text-center mb-6">
                <img
                    :src="imageSrc"
                    width="112px"
                    alt="License"
                >
            </div>
            <form-container
                id="ssnVerificationForm"
                ref="ssnVerificationForm"
            >
                <form-button
                    :submitting="submitting"
                    type="submit"
                    class="mt-2"
                    label="Yes, I Have An ID"
                    event-name="click_title_name_verification_affirmative"
                    data-testid="remedy-name-mismatch-its-me"
                    @click="handleItsMeClick"
                />
                <form-button
                    :submitting="submitting"
                    class="mt-2"
                    button-classes="btn btn-secondary"
                    label="No"
                    event-name="click_title_name_verification_negative"
                    data-testid="remedy-name-mismatch-its-not-me"
                    @click="handleItsNotMeClick"
                />
            </form-container>
        </div>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script lang="js">
    import startCase from 'lodash/startCase'
    import toLower from 'lodash/toLower'
    import OnboardingLayout from "@/layouts/Onboarding"
    import { sharedPagePaths } from "@/routes/sharedRoutes";
    import { getNonEntityOwnerNames } from "@/services/homeApi";
    import { logger } from "@/utils/logger";
    import { updateApplicantNameFromNonEntityOwnerName } from "@/services/api";
    import { getNextRoute } from "@/flow/flowController";
    import FormButton from '../../components/base/FormButton'
    import FeedbackListItem from "@/components/onboarding/FeedbackListItem";
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { appSessionStorage, sessionStorageKey } from "@/utils/storage";

    export default {
        components: {
            FeedbackListItem,
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
        },
        mixins: [experimentsMixin],
        data: function() {
            return {
                loading: true,
                errorText: '',
                ownerNameChoiceIndex: 0,
                ownerNameChoices: [],
                currentOwnerNameToConsider: null,
                imageSrc: require('../../assets/images/origination/License.svg'),
            }
        },
        computed: {
            title() {
                return `It looks like your home's title has a slightly different name. Do you have a valid Driver's License or Passport with the name ${startCase(toLower(this.currentOwnerNameToConsider))}?`
            }
        },
        mounted: async function() {
            try {
                // "non-entity" in this case means non-trust and non-LLC. These names will form the
                // options for which names the applicant can choose from.
                logger.info('Making request for non-entity owner names to remedy name mismatch')
                const nonEntityOwnerNamesResponse = await getNonEntityOwnerNames()
                this.ownerNameChoices = nonEntityOwnerNamesResponse.data.payload?.ownerNames ?? []
                if (nonEntityOwnerNamesResponse.data.success && this.ownerNameChoices.length > 0) {
                    logger.info(`Got ${this.ownerNameChoices.length} owner names to choose from ${JSON.stringify(this.ownerNameChoices)}`)
                    this.setCurrentOwnerNameChoice(this.ownerNameChoiceIndex)
                } else {
                    logger.info(`Getting non-entity owner names ${nonEntityOwnerNamesResponse.data.success ? 'returned no names to choose from' : `was not successful. Error ${nonEntityOwnerNamesResponse.data.error}`}`)
                    await this.navigateToThanks()
                }
                this.$logEvent('view_remedy_name_mismatch', { ownerNameChoices: this.ownerNameChoices })
            } catch (e) {
                logger.error(`Couldn't get non-entity owner names to remedy name mismatch.`, null /* event */, e)
                await this.navigateToThanks()
            } finally {
                this.loading = false
            }
        },
        methods: {
            setCurrentOwnerNameChoice: function (ownerNameIndex) {
                const ownerName = this.ownerNameChoices[ownerNameIndex]
                logger.info(`Setting current owner name choice to ${JSON.stringify(ownerName)} at index ${ownerNameIndex}`)
                this.currentOwnerNameToConsider = ownerName
            },
            handleItsMeClick: async function() {
                try {
                    this.$logEvent('click_button_update_name_to_owner_name', { selectedName: this.currentOwnerNameToConsider })
                    logger.info(`Applicant says their name is ${this.currentOwnerNameToConsider}`)
                    this.loading = true
                    const selectedOwnerName = this.ownerNameChoices[this.ownerNameChoiceIndex]
                    const response = await updateApplicantNameFromNonEntityOwnerName(selectedOwnerName)
                    if (response.data.success) {
                        const { updatedFirstName, updatedLastName } = response.data.payload
                        logger.info(`Successfully updated applicant's name`)
                        // Update applicant name may not overwrite applicant name directly if the home owner name
                        // seems malformed, it returns the updated names if things changed so we need to
                        // store the new ones locally for use elsewhere during origination.
                        if (updatedFirstName) {
                            logger.info(`Storing ${updatedFirstName} locally as updated first name`)
                            appSessionStorage.setItem(sessionStorageKey.firstName, updatedFirstName)
                        }
                        if (updatedLastName) {
                            logger.info(`Storing ${updatedLastName} locally as updated last name`)
                            appSessionStorage.setItem(sessionStorageKey.lastName, updatedLastName)
                        }
                        await this.$router.replace(getNextRoute(this.$router))
                    } else {
                        logger.error(`Failed to update applicant's name from owner name ${JSON.stringify(this.ownerNameChoices[this.ownerNameChoiceIndex])}. Error: ${response.data.error}`)
                        await this.navigateToThanks()
                    }
                } catch (e) {
                    logger.error(`Couldn't update applicant's name from owner name ${JSON.stringify(this.ownerNameChoices[this.ownerNameChoiceIndex])}`, null, e)
                    await this.navigateToThanks()
                } finally {
                    this.loading = false
                }
            },
            handleItsNotMeClick: async function() {
                this.$logEvent('click_button_do_not_update_name_to_owner_name', { consideredName: this.currentOwnerNameToConsider })
                logger.info(`Applicant says they are not ${this.currentOwnerNameToConsider}`)
                // If we've already showed the applicant the last owner name choice, and they've
                // responded that it isn't them, then navigate to the thanks page.
                if (this.ownerNameChoiceIndex === this.ownerNameChoices.length - 1) {
                    return await this.navigateToThanks()
                }
                // Otherwise present the next possible owner name choice.
                // Todo Make it obvious that the name option changed so the applicant knows
                //  they have to make another choice.
                else {
                    this.ownerNameChoiceIndex += 1
                    this.setCurrentOwnerNameChoice(this.ownerNameChoiceIndex)
                }
            },
            navigateToThanks: async function() {
                await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'homeOwnership' } })
            }
        }
    }
</script>
