<template>
    <div>
        <validation-provider
            mode="lazy"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors }"
        >
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="isChecked"
                    :id="`${componentId}-checkbox`"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                >
                <label
                    class="custom-control-label small"
                    data-testid="required-document-ack-checkbox"
                    :for="`${componentId}-checkbox`"
                >
                    By checking the box, you are signing and agreeing to the <a
                        href="#"
                        data-testid="required-document-ack-download"
                        @click="openDocument"
                    >{{ documentTitle }}</a>
                </label>
                <p
                    v-if="errors.length > 0"
                    class="text-start fw-bold"
                    :class="errors[0] ? 'text-danger' : null"
                >
                    <span
                        class="fw-normal small block"
                        v-if="errors[0]"
                    >
                        {{ errors[0] }}
                    </span>
                </p>
                <p
                    v-if="documentSubtitle"
                    class="form-check-label mt-1 mb-0 text-muted small"
                >
                    {{ documentSubtitle }}
                </p>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { getUniqueNumber } from '../../utils/number-utils'
    import { httpClient } from '../../utils/http-client'
    import { openDocument } from '../../utils/document'

    export default {
        name: 'RequiredDocumentAck',
        props: {
            documentId: {
                type: Number,
            },
            documentTitle: {
                type: String,
            },
            documentLink: {
                type: String,
            },
            documentKey: {
                type: String,
            },
            documentSubtitle: {
                type: String,
            },
        },
        components: {
            'validation-provider': ValidationProvider,
        },
        data() {
            const componentId = `required-document-ack-${getUniqueNumber()}`
            return {
                componentId,
                isChecked: false,
            }
        },
        methods: {
            getValue() {
                return {
                    type: 'RequiredDocumentAck',
                    id: this.documentId,
                    value: this.isChecked,
                }
            },
            async openDocument() {
                let link = this.documentLink
                if (link) {
                    openDocument(link, this.documentTitle)
                    return
                }

                link = `/stateModules/document?documentKey=${this.documentKey}`
                const pdfDocument = await httpClient.get(link, {
                    responseType: 'blob',
                })

                openDocument(pdfDocument.data, this.documentTitle)
            },
        },
    }
</script>

<style scoped></style>
