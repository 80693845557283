import { coApplicantHttpClient, httpClient } from '@/utils/http-client'

const verifyApplicant = async (isCoApplicant?: boolean) => {
    const path = `/verifyApplicant`
    if (isCoApplicant) {
        return await coApplicantHttpClient.get(path)
    }
    return await httpClient.get(path)
}

const verifyApplicantFullSSN = async (isCoApplicant?: boolean) => {
    const path = `/verifyApplicantFullSSN`
    if (isCoApplicant) {
        return await coApplicantHttpClient.get(path)
    }
    return await httpClient.get(path)
}

// Idology endpoint postQuestionAnswers - IDology presents applicant with 3 screening questions
// if they are not able to validate the applicant confidently. This endpoint is used to
// post the answers for verification
const postQuestionAnswers = async (payload: object, idologyQueryId = false, isCoApplicant?: boolean) => {
    const path = `/idology_qa_answers`
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(path, {
            idologyQueryId,
            ...payload,
        })
    }
    return await httpClient.post(path, {
        idologyQueryId,
        ...payload,
    })
}

// Idology endpoint getIDologyChallengeQuestions - if the applicant answers 1 question incorrectly, the
// applicant is presented with a challenge question. if the applicant answers more than 1 question incorrectly,
// IDology fails the applicant instantly
const getIDologyChallengeQuestions = async (isCoApplicant?: boolean) => {
    const path = `/challenge_questions`
    if (isCoApplicant) {
        return await coApplicantHttpClient.get(path)
    }
    return await httpClient.get(path)
}

// Idology endpoint postChallengeQuestionAnswers - this endpoint is used to submit answer for challenge question
// for verification. if the applicant answers this correctly we route them to the prequal offer page.
const postChallengeQuestionAnswers = async (payload: object, idologyQueryId = false, isCoApplicant?: boolean) => {
    const path = `/idology_challenge_answers`
    if (isCoApplicant) {
        return await coApplicantHttpClient.post(path, {
            idologyQueryId,
            answers: payload,
        })
    }
    return await httpClient.post(path, {
        idologyQueryId,
        answers: payload,
    })
}

export { verifyApplicant, verifyApplicantFullSSN, getIDologyChallengeQuestions, postChallengeQuestionAnswers, postQuestionAnswers }
