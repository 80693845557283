<template>
    <onboarding-layout
        :loading="loading || submitting"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <div
            class="alert alert-secondary border-0 d-flex flex-row align-items-center mb-0 border-0"
            v-if="incomeVerifiedThroughWorkNumberOrExperian"
        >
            <div>
                <img
                    src="@/assets/images/global/celebrate.svg"
                    alt="offerPreview"
                >
            </div>
            <div class="ps-2 text-muted">
                Good news! Your income has been automatically verified by Experian and Equifax.
            </div>
        </div>
        <img
            src="@/assets/images/origination/OfferPreview.svg"
            alt="Offer preview"
            class="mb-1"
            v-else
        >
        <h5
            class="text-center mt-3 mb-0"
            data-testid="offer-preview-title"
        >
            {{ $t('pages.origination.offerPreview.title', { firstName: primaryFirstName }) }}
        </h5>

        <h5 class="fw-light text-center mt-1 mb-0">
            {{ $t('pages.origination.offerPreview.subtitle') }}
        </h5>

        <div
            class="card card-shadow rounded-3 mt-5 px-3"
            v-if="creditOffer"
        >
            <div class="d-flex flex-column mt-4">
                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.offerPreview.creditLimit') }}
                    </p>
                    <h5
                        class="mb-0"
                        data-testid="final-offer-line-size"
                    >
                        {{ toFormattedUSDStripTrailingZeroCents(creditOffer.lineSize) }}
                    </h5>
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p
                        class="mb-0"
                        v-html="$t('pages.origination.offerPreview.apr')"
                    />
                    <h5
                        class="mb-0"
                        data-testid="final-offer-apr"
                    >
                        {{ formatApr(creditOffer.apr) }}
                    </h5>
                </div>

                <div v-if="isTaxState">
                    <divider-line class="p-0" />

                    <div class="d-inline-flex justify-content-between align-items-center w-100">
                        <div>
                            <p class="mb-0">
                                {{ $t('pages.origination.preQualification.recordingFee') }}
                            </p>
                        </div>

                        <div class="d-flex justify-content-end w-50">
                            <h5
                                data-testid="final-offer-tax-amount"
                                :class="`mb-0 ${isTaxFreeOffer ? 'text-muted text-strikethrough' : ''}`"
                            >
                                {{ toFormattedUSDStripTrailingZeroCents(recordingCharges) }}
                            </h5>
                            <h5
                                data-testid="final-offer-tax-free-amount"
                                class="mb-0 ms-1"
                                v-if="isTaxFreeOffer"
                            >
                                {{ toFormattedUSDStripTrailingZeroCents(effectiveRecordingCharges) }}
                            </h5>
                        </div>
                    </div>
                    <div
                        class="small text-muted"
                        data-testid="final-offer-tax-amount-subtitle"
                    >
                        <span v-if="isTaxFreeOffer"> Don’t worry, we will cover it </span>
                        <span v-else>We will spread the one time cost over the first 12 months – {{ toFormattedUSDStripTrailingZeroCents(effectiveRecordingCharges / 12) }}/mo.</span>
                    </div>
                </div>

                <div
                    v-show="showWhyIsItDifferent"
                    class="mt-3 text-center"
                >
                    <div
                        @click="expandWhyIsItDifferent = !expandWhyIsItDifferent"
                        @keydown.enter="expandWhyIsItDifferent = !expandWhyIsItDifferent"
                        style="cursor: pointer; display: inline-block"
                    >
                        Why is it different?
                    </div>

                    <div v-show="expandWhyIsItDifferent">
                        <p class="text-muted mt-1 mb-0">
                            {{ whyIsItDifferentIncomeMessage }}
                            <a
                                href="#"
                                @click="provideSupportingInformation"
                            >Provide supporting information.</a>
                        </p>
                    </div>

                    <divider-line class="p-0" />
                </div>

                <div class="text-center mt-4">
                    <a
                        href="#"
                        @click="getDocument(LegalDocumentTypes.accountAgreement)"
                    >Important Terms & Conditions</a>
                </div>

                <div
                    class="mt-2 mb-4 small text-muted text-center"
                    v-html="$t('pages.origination.offerPreview.autopayDiscount')"
                />
            </div>
        </div>

        <!-- <reward-bonus-card :user-seen-offer-start-time="userSeenOfferTime" />-->

        <form-button
            :label="$t('pages.origination.offerPreview.cta')"
            data-testid="final-offer-accept-button"
            @click="acceptOffer"
            event-name="click_button_accept_offer"
            class="mt-5"
        />

        <form-button
            button-classes="btn btn-tertiary mt-2"
            :label="$t('pages.origination.offerPreview.decline')"
            @click="declineOffer"
            event-name="click_button_decline_offer"
        />

        <div class="d-none d-md-block small text-muted text-start">
            <p class="mt-2">
                {{ $t('pages.origination.offerPreview.hardpull') }}
            </p>
            <span>
                {{ $t('pages.origination.offerPreview.legalDocs') }}
                <a
                    href="#"
                    @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                >HELOC Application Disclosure</a>,
                <a
                    href="/docs/CFPBHELOCBooklet.pdf"
                    target="_blank"
                    @click="$logEvent('click_link_heloc_handbook')"
                >HELOC Booklet</a>,
                <a
                    href="/docs/CFPBCharmBooklet.pdf"
                    target="_blank"
                    @click="$logEvent('click_link_charm_handbook')"
                >CHARM Handbook</a>,
                <a
                    href="#"
                    @click="getDocument(LegalDocumentTypes.creditScoreDisclosure)"
                >Credit Score Disclosure</a>,
                <a
                    href="#"
                    @click="getDocument(LegalDocumentTypes.hudGovDoc)"
                >List of Home Ownership Counseling Services</a>, and
                <a
                    :href="creditOffer && creditOffer.product === ProductCategory.prime ? '/docs/PrimeRewardsTerms.pdf' : '/docs/rewards15Terms.pdf'"
                    target="_blank"
                    @click="$logEvent('click_rewards_terms_and_conditions')"
                >Rewards Terms and Conditions</a>.
            </span>
            <p
                class="small text-muted text-start mt-2 mb-0"
                v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.prequalFooter`)"
            />
            <p
                class="small text-muted text-start mt-2 mb-0"
                v-html="$t('pages.origination.preQualification.cashbackFootnote')"
            />
            <p
                class="small text-muted text-start mt-2 mb-0"
                v-html="$t('pages.origination.offerPreview.extendedLinesFootnoteDisclosure')"
            />
            <p
                class="small text-muted text-start mt-2 mb-0"
                v-html="$t('pages.origination.offerPreview.bonusDisclosure')"
            />
        </div>
        <div class="d-block d-md-none small text-muted text-start">
            <p class="mt-2 mb-0">
                {{ $t('pages.origination.offerPreview.hardpull') }}
            </p>
        </div>
        <template #sidebar>
            <short-process-assurance-list-item :is-extended-lines-offer="isExtendedLinesOffer" />
            <social-proof-list-item :loading="loading" />
            <monthly-payment-list-item
                v-if="theoreticalPaymentStats"
                :line-size="creditOffer.lineSize"
                :apr="theoreticalPaymentStats.apr"
                :payment-size="theoreticalPaymentStats.firstPayment"
                :total-payments="theoreticalPaymentStats.totalPayments"
                :total-paid="theoreticalPaymentStats.totalPaid"
            />
            <fixed-monthly-payment-list-item
                v-if="theoreticalFixedPaymentStats"
                :theoretical-fixed-payment-stats="theoreticalFixedPaymentStats"
                :line-size="creditOffer.lineSize"
                :term-months-options="theoreticalTermMonthsOptions"
            />
            <feedback-list-item />
            <div class="d-block d-md-none small text-muted text-start">
                <span>
                    {{ $t('pages.origination.offerPreview.legalDocs') }}
                    <a
                        href="#"
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                    >HELOC Application Disclosure</a>,
                    <a
                        href="/docs/CFPBHELOCBooklet.pdf"
                        target="_blank"
                        @click="$logEvent('click_link_heloc_handbook')"
                    >HELOC Booklet</a>,
                    <a
                        href="/docs/CFPBCharmBooklet.pdf"
                        target="_blank"
                        @click="$logEvent('click_link_charm_handbook')"
                    >CHARM Handbook</a>,
                    <a
                        href="#"
                        @click="getDocument(LegalDocumentTypes.creditScoreDisclosure)"
                    >Credit Score Disclosure</a>,
                    <a
                        href="#"
                        @click="getDocument(LegalDocumentTypes.hudGovDoc)"
                    >List of Home Ownership Counseling Services</a>, and
                    <a
                        :href="creditOffer && creditOffer.product === ProductCategory.prime ? '/docs/PrimeRewardsTerms.pdf' : '/docs/rewards15Terms.pdf'"
                        target="_blank"
                        @click="$logEvent('click_rewards_terms_and_conditions')"
                    >Rewards Terms and Conditions</a>.
                </span>
                <p
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.prequalFooter`)"
                />
                <p
                    class="small text-muted text-start mt-2 mb-0"
                    v-if="isExtendedLinesOffer"
                    v-html="$t('pages.origination.preQualification.cashbackFootnote')"
                />
                <p
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.offerPreview.bonusDisclosure')"
                />
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import MonthlyPaymentListItem from '@/components/onboarding/MonthlyPaymentListItem'
    import { mixins } from '@/mixins/mixins'
    import format from '@/mixins/format'
    import { i18n } from '@/utils/i18n'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { getNextRoute } from '@/flow/flowController'
    import { acceptOrDeclineOffer, getTheoreticalPaymentStats, OfferFailureCode, ProductCategory } from '@/services/homeApi'
    import { misc } from '@/mixins/misc'
    import { getIsWaitingOnManualDocumentVerification, LegalDocumentTypes } from '@/services/api'
    import originationMixin from '@/mixins/originationMixin'
    import { RouteOption } from '@/flow/flowUtility'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import DividerLine from '@/components/DividerLine'
    import ShortProcessAssuranceListItem from '@/components/onboarding/ShortProcessAssuranceListItem'
    import SocialProofListItem from '@/components/onboarding/SocialProofListItem'
    import confetti from 'canvas-confetti'
    import isEqual from 'lodash/isEqual'
    import { EXTENDED_LINES_LINE_SIZE_THRESHOLD } from '@/services/homeApi'
    import FixedMonthlyPaymentListItem from '@/components/onboarding/FixedMonthlyPaymentListItem'
    import { beginHomeQualOffer, getHomeOffer } from '../../services/homeApi'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { getTheoreticalFixedMonthlyPayments } from '@/utils/paymentUtils'

    export default {
        components: {
            FeedbackListItem,
            FixedMonthlyPaymentListItem,
            SocialProofListItem,
            ShortProcessAssuranceListItem,
            DividerLine,
            MonthlyPaymentListItem,
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
        },
        mixins: [mixins, misc, originationMixin, format, documentVerificationMixin, experimentsMixin],
        data: function () {
            return {
                LegalDocumentTypes,
                ProductCategory,
                submitting: false,
                loading: true,
                loadingTitle: i18n.t('offerPreview.loading'),
                creditOffer: null,
                preQualificationOffer: JSON.parse(appSessionStorage.getItem(sessionStorageKey.preQualificationOffer) || null),
                usedGrossAnnualIncome: '',
                statedGrossAnnualIncome: '',
                doesPreQualificationOfferMatch: false,
                showWhyIsItDifferent: false,
                expandWhyIsItDifferent: false,
                whyIsItDifferentIncomeMessage: '',
                theoreticalPaymentStats: null,
                userSeenOfferTime: null,
                theoreticalFixedPaymentStats: null,
                theoreticalTermMonthsOptions: null,
            }
        },
        mounted: async function () {
            try {
                if (appSessionStorage.getItem(sessionStorageKey.preQualificationFailureCode)) {
                    await this.handleOfferFailureCode(appSessionStorage.getItem(sessionStorageKey.preQualificationFailureCode))
                    return
                }

                const isWaitingOnManualDocumentVerificationResponse = await getIsWaitingOnManualDocumentVerification(this.isCoApplicantVerifyingIncome)
                if (isWaitingOnManualDocumentVerificationResponse.data.payload.isWaiting) {
                    logger.info(`Currently waiting on some form of manual document verification. Redirecting to awaiting manual document verification page.`)
                    return await this.$router.push(getNextRoute(this.$router, RouteOption.awaitingManualDocumentVerification))
                } else {
                    logger.info(`Not waiting on manual document verification!`)
                }

                await this.getOffer()
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            isExtendedLinesOffer: function () {
                return this.creditOffer?.lineSize > EXTENDED_LINES_LINE_SIZE_THRESHOLD
            },
            incomeVerifiedThroughWorkNumberOrExperian: function () {
                const incomeVerificationMethod = appSessionStorage.getItem(sessionStorageKey.incomeVerificationMethod)
                return incomeVerificationMethod === 'workNumber' || incomeVerificationMethod === 'experian'
            },
            recordingCharges: function () {
                return this.creditOffer?.recordingCharges || 0
            },
            effectiveRecordingCharges: function () {
                return this.creditOffer?.effectiveRecordingCharges || 0
            },
            isTaxState: function () {
                return this.recordingCharges > 0
            },
            isTaxFreeOffer: function () {
                return this.creditOffer?.policyName.includes('TaxFreeVariant') || (this.recordingCharges > 0 && this.effectiveRecordingCharges === 0)
            },
        },
        methods: {
            provideSupportingInformation: async function () {
                return await this.$router.push(getNextRoute(this.$router, RouteOption.supportingInfo))
            },
            getOffer: async function () {
                try {
                    this.loading = true
                    logger.log('Attempting to retrieve final aven credit card offer (if any)...')

                    const {
                        data: {
                            payload: { jobId },
                        },
                    } = await beginHomeQualOffer({ purpose: 'qual' })

                    let qualOfferResponse
                    const maxNumAttempts = 20
                    const timeBetweenAttempts = 2_500 // milliseconds
                    for (let numAttempt = 0; numAttempt < maxNumAttempts; numAttempt++) {
                        qualOfferResponse = await getHomeOffer(jobId)
                        if (qualOfferResponse.data.success) {
                            break
                        }
                        logger.info(`Still waiting for qual underwriting to complete. Attempt nr ${numAttempt + 1} out of ${maxNumAttempts}`)
                        await new Promise((r) => setTimeout(r, timeBetweenAttempts))
                    }

                    if (!qualOfferResponse?.data?.success || !qualOfferResponse?.data?.payload?.isApproved) {
                        logger.info('Someone needs to be manually reviewed!')
                        await this.handleOfferFailureCode(qualOfferResponse?.data?.payload?.error || OfferFailureCode.underwritingFail)
                    } else {
                        const payload = qualOfferResponse.data.payload.offerPayload

                        this.creditOffer = payload.offerTerms
                        appSessionStorage.setItem(sessionStorageKey.creditOffer, JSON.stringify(this.creditOffer))

                        if (this.preQualificationOffer?.apr && this.preQualificationOffer?.lineSize) {
                            const usedGrossAnnualIncomeInt = parseInt(payload.usedGrossAnnualIncome, 10)
                            const statedGrossAnnualIncomeInt = parseInt(payload.statedGrossAnnualIncome, 10)
                            this.usedGrossAnnualIncome = this.toFormattedUSDStripTrailingZeroCents(usedGrossAnnualIncomeInt.toString())
                            this.statedGrossAnnualIncome = this.toFormattedUSDStripTrailingZeroCents(statedGrossAnnualIncomeInt.toString())
                            this.whyIsItDifferentIncomeMessage = i18n.t('pages.origination.offerPreview.whyIsItDifferentIncome', { income: this.statedGrossAnnualIncome })

                            this.doesPreQualificationOfferMatch = isEqual(this.creditOffer, payload.preQualTerms)
                            this.showWhyIsItDifferent = !this.doesPreQualificationOfferMatch && usedGrossAnnualIncomeInt < statedGrossAnnualIncomeInt
                        }

                        logger.info(
                            `Credit Offer: ${JSON.stringify(this.creditOffer)}, PreQualification Offer: ${JSON.stringify(this.preQualificationOffer)}, Matches: ${this.doesPreQualificationOfferMatch}`
                        )

                        logger.info(`Retrieving theoretical payment stats...`)
                        const theoreticalPaymentStatsResponse = await getTheoreticalPaymentStats(this.creditOffer.apr, this.creditOffer.lineSize)
                        this.theoreticalPaymentStats = theoreticalPaymentStatsResponse.data.payload
                        logger.info(`Retrieving theoretical payment stats...done (got: ${JSON.stringify(this.theoreticalPaymentStats)})`)

                        // fetch pre-qual theoretical fixed payment stats
                        // includes options if line size <= 25k [5 year, 10 year] else [15 year, 30 year]
                        this.theoreticalTermMonthsOptions = this.heraclesParameter.LINE_SIZE_LIMIT_FOR_TERMS(this.creditOffer.lineSize)
                        const parsedApr = +(parseFloat(this.creditOffer.apr) * 100).toFixed(2) // api wants decimal point rate (e.g. 7.99)
                        const [firstFixedPlanResponse, secondFixedPlanResponse] = await Promise.all([
                            getTheoreticalFixedMonthlyPayments(parsedApr, this.creditOffer.lineSize, this.theoreticalTermMonthsOptions[0], this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT),
                            getTheoreticalFixedMonthlyPayments(parsedApr, this.creditOffer.lineSize, this.theoreticalTermMonthsOptions[1], this.heraclesParameter.BALANCE_TRANSFER_FEE_PERCENT),
                        ])
                        // if corecard is down or we don't receive a proper response, the component will be hidden
                        this.theoreticalFixedPaymentStats = [firstFixedPlanResponse, secondFixedPlanResponse]

                        // These are the dynamically generated legal docs the applicant can view on this page.
                        const docsToGenerate = [LegalDocumentTypes.accountAgreement, LegalDocumentTypes.earlyHELOCDisclosure, LegalDocumentTypes.creditScoreDisclosure, LegalDocumentTypes.hudGovDoc]
                        const generatedDocsResults = await this.generateAndSaveLegalDocuments(docsToGenerate)
                        // Todo Do we really need to fail if docs are not generated? These docs are for our record-keeping
                        //  which is definitely important, but should it block an applicant from getting an offer?
                        if (generatedDocsResults.data.payload.failures.length) {
                            throw new Error(`Not all legal documents could be generated: ${JSON.stringify(generatedDocsResults)}`)
                        }

                        this.loading = false // Only stop loading if the offer goes through /and/ all documents are generated
                        this.$logEvent('view_offer_preview')

                        // DISABLED FOR NOW on 01/14/2022
                        // start the sign on bonus countdown timer based on when the loan app offer was initially "offered"
                        // this.userSeenOfferTime = DateTime.fromISO(payload.offerTime).toJSDate()

                        // Once the offer is displayed, give some celebratory feedback to the user
                        // in the form of a confetti explosion!
                        confetti({
                            particleCount: 100,
                            gravity: 0.75,
                            colors: ['#ADD6E3', '#000000'],
                        })
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
            },
            handleOfferFailureCode: async function (failureCode) {
                logger.info(`Received offer failure code of ${failureCode}`)
                switch (failureCode) {
                    case OfferFailureCode.humanInvestigate:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    case OfferFailureCode.expired:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'expired' } })
                    case OfferFailureCode.denied:
                        return await this.$router.push({ path: sharedPagePaths.LOAN_NOT_APPROVED })
                    case OfferFailureCode.homeIsLLC:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'homeIsTrustOrLLC' } })
                    case OfferFailureCode.homeIsTrustAndRin:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'trustRIN' } })
                    case OfferFailureCode.homeIsTrustInNotSupportedState:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'trustUnsupportedState' } })
                    case OfferFailureCode.homeOwnershipRemedy:
                        return await this.$router.push(getNextRoute(this.$router, RouteOption.remedyNameMismatch))
                    case OfferFailureCode.homeOwnership:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'homeOwnership' } })
                    case OfferFailureCode.priorApplicationFound:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'duplicate' } })
                    case OfferFailureCode.underwritingFail:
                        logger.error(`OfferFailureCode at qual offer: ${failureCode}, dropping into review`)
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    default:
                        logger.error(`Unknown failure code: ${failureCode}`)
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                }
            },
            acceptOffer: async function () {
                if (this.submitting) {
                    logger.log('Already submitting, ignoring button click')
                    return
                }

                try {
                    this.loadingTitle = 'Loading...'
                    this.submitting = true
                    logger.log('User accepted credit offer, moving to next route')
                    await acceptOrDeclineOffer(true)
                    await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loadingTitle = i18n.t('offerPreview.loading')
                    this.submitting = false
                }
            },
            declineOffer: async function () {
                if (this.submitting) {
                    logger.log('Already submitting, ignoring button click')
                    return
                }

                try {
                    this.submitting = true
                    logger.log('User declined credit offer, redirecting to /thanks page')
                    await acceptOrDeclineOffer(false)
                    await this.$router.push({
                        path: sharedPagePaths.THANKS,
                        query: { reason: 'declinedOffer' },
                    })
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.submitting = false
                }
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>

<style scoped></style>
