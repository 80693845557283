import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'

export const hasMailInvite = (): boolean => {
    const hasInviteCode = !!appSessionStorage.getItem(sessionStorageKey.inviteCode)
    logger.info(`has invite code: ${hasInviteCode}`)
    const hasMailCode = hasInviteCode && appSessionStorage.getItem(sessionStorageKey.inviteType) === 'mail'
    logger.info(`isMail invite code: ${hasInviteCode}`)
    return hasMailCode
}
