<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5
            class="fw-light"
            v-html="$t('pages.origination.statedIncome.title')"
        />

        <form-container
            id="statedIncomeForm"
            ref="form"
            @on-submit="onSubmit"
        >
            <div v-if="hasCoApplicant">
                <section-header class="mt-5">
                    {{ primaryHeading }}
                </section-header>
            </div>
            <div v-else>
                <section-header class="mt-5">
                    Your Annual Income
                </section-header>
            </div>

            <form-field-currency
                v-model="primaryIncome"
                data-testid="stated-income-input"
                :placeholder="$t('pages.origination.statedIncome.placeholder')"
                :label="$t('pages.origination.statedIncome.placeholder')"
                name="primaryIncome"
                validation-rules="required|currency:20000"
            />

            <div
                v-if="hasCoApplicant"
                class="mb-2 row"
            >
                <div class="col">
                    <section-header class="mt-3">
                        {{ secondaryHeading }}
                    </section-header>

                    <form-field-currency
                        v-model="secondaryIncome"
                        data-testid="stated-income-secondary-input"
                        :placeholder="$t('pages.origination.statedIncome.placeholder')"
                        :label="$t('pages.origination.statedIncome.placeholder')"
                        name="secondaryIncome"
                        validation-rules="required|currency:10000"
                    />
                </div>
            </div>

            <form-button
                type="button"
                data-testid="stated-income-continue-button"
                label="Check Your Offer"
                class="mt-4"
                :block="true"
                :submitting="submitting"
                @click="onSubmit"
                event-name="click_button_submit_stated_income_form"
            />
        </form-container>

        <safe-credit-score class="mb-0 mt-1" />
        <div class="d-none d-md-block mt-3">
            <p class="text-muted small text-start mb-0">
                {{ $t('pages.origination.statedIncome.legal') }}
            </p>
            <p class="text-muted small text-start mt-2">
                {{ $t('components.applicationForm.legal.incomeDisclaimer') }}
            </p>
        </div>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
            <div class="d-block d-md-none">
                <p class="text-muted small text-start mb-0">
                    {{ $t('pages.origination.statedIncome.legal') }}
                </p>
            </div>
        </template>
    </onboarding-layout>
</template>

<script lang="js">
    import OnboardingLayout from "@/layouts/Onboarding";
    import SectionHeader from "@/components/SectionHeader";
    import FormButton from "@/components/base/FormButton";
    import FormContainer from "@/components/base/FormContainer";
    import FormFieldCurrency from "@/components/base/FormFieldCurrency";
    import originationMixin from "@/mixins/originationMixin";
    import {ApiErrorHandler} from "@/utils/exception-handler";
    import {getNextRoute} from "@/flow/flowController";
    import {sessionStorageKey, appSessionStorage} from '@/utils/storage'
    import {updateStatedIncome} from "@/services/loanApplication";
    import { logger } from '@/utils/logger'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import {hasMailInvite} from "@/utils/utils";
    import FeedbackListItem from "@/components/onboarding/FeedbackListItem";
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        components: {
            FeedbackListItem,
            SafeCreditScore,
            "onboarding-layout": OnboardingLayout,
            "form-button": FormButton,
            "form-container": FormContainer,
            "form-field-currency": FormFieldCurrency,
            'section-header': SectionHeader,
        },
        data: function() {
            return {
                primaryIncome: '',
                secondaryIncome: '',
                loadingTitle: 'Checking For Offers...',
            }
        },
        mixins: [originationMixin, experimentsMixin],
        mounted: async function() {
            /**
             * both checks were added originally in commit https://github.com/heraclescorp/heracles/commit/25c576c9f2ef289086184302f339272d9a2995b6
             * original check only looked for existence of invite code and we only had one type of invite then, 'mail'
             * now we have pif invite as well.
             * as of Feb 8, 2022,
             * during mail invite origination, annual income is submitted as part of personal info page. therefore, we skip the standalone version of stated_income.vue
             * for all other origination, i.e. pif invite or no invite code, we cannot skip this page
             */
            if (hasMailInvite()) {
                logger.info(`Application started with Mail Invite, annual income was provided on mailer personal info, skipping origination/stated_income page`)
                return await this.$router.push(getNextRoute(this.$router))
            }

            if( appSessionStorage.getItem(sessionStorageKey.addedCoApplicantOnFailure )) {
                logger.info(`sessionStorageKey.addedCoApplicantOnFailure exists, applicant has already provided stated income, skipping origination/stated_income page`)
                return await this.$router.push(getNextRoute(this.$router))
            }
            this.$logEvent('view_stated_income')
        },
        computed: {
            address: function() {
                const basicInfo = this.getBasicInfo()
                return basicInfo?.addressData
            },
            secondaryHeading() {
                return `${this.secondaryFirstName}’s INCOME`
            },
            primaryHeading() {
                return `${this.primaryFirstName}’s INCOME`
            },
        },
        methods: {
            onSubmit: async function() {
                this.loading = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid || isNaN(parseInt(this.primaryIncome)) || (this.hasCoApplicant && isNaN(parseInt(this.secondaryIncome)))) {
                    this.loading = false
                    return
                }

                try {
                    await updateStatedIncome(this.primaryIncome, false)
                    appSessionStorage.setItem(sessionStorageKey.statedIncome, this.primaryIncome)

                    if (this.hasCoApplicant) {
                        await updateStatedIncome(this.secondaryIncome, true)
                        appSessionStorage.setItem(sessionStorageKey.coApplicantStatedIncome, this.secondaryIncome)
                    }

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.loading = false
            },
            getBasicInfo: function () {
                try {
                    return JSON.parse(appSessionStorage.getItem(sessionStorageKey.basicInfo))
                } catch(e) {
                    logger.error(`Could not parse basicInfo in StatedIncome`, e)
                }
            }
        }
    };
</script>
