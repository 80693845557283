<template>
    <div class="p-3 pb-0">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <img
                    class="star"
                    src="@/assets/images/global/trustpilot-rating-star.svg"
                    alt="Rating first star"
                ><img
                    class="star"
                    src="@/assets/images/global/trustpilot-rating-star.svg"
                    alt="Rating second star"
                ><img
                    class="star"
                    src="@/assets/images/global/trustpilot-rating-star.svg"
                    alt="Rating third star"
                ><img
                    class="star"
                    src="@/assets/images/global/trustpilot-rating-star.svg"
                    alt="Rating fourth star"
                ><img
                    class="star"
                    src="@/assets/images/global/trustpilot-rating-star.svg"
                    alt="Rating fifth star"
                >
            </div>
            <div class="small-text text-muted">
                <img
                    class="star"
                    src="@/assets/images/global/check-circled.svg"
                    alt="Circled Check"
                >
                Verified
            </div>
        </div>
        <div class="mt-2">
            {{ review }}
        </div>
    </div>
</template>

<script>
    import { convertDateTimeFormat, getCurrentTimezone } from '@/utils/date'

    const formatDate = (date) => {
        return convertDateTimeFormat(date, 'Etc/UTC', getCurrentTimezone(), 'MMM, D YYYY')
    }

    export default {
        name: 'SocialProofItem',
        props: {
            review: { type: String, required: true },
        },
        computed: {
            date: function () {
                if (this.updatedDate) {
                    return `Updated ${formatDate(this.updatedDate)}`
                }

                return formatDate(this.createdDate)
            },
        },
    }
</script>

<style scoped>
    .small-text {
        font-size: 0.75rem;
    }

    .star {
        padding-right: 0.25rem;
    }
</style>
