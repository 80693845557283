<template>
    <div>
        <p class="text-muted small text-start mt-4 mb-2">
            For more information, you can also read our <a
                href="/privacy"
                target="_blank"
            >Privacy Policy</a>,
            <a
                @click="onGetDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                href="#"
            > Early HELOC Disclosure </a>,
            <a
                @click="onGetDocument(LegalDocumentTypes.pricingAndTerms)"
                href="#"
            >Pricing and Terms</a>
            <span v-if="isConfirmedFirstLienPosition">, <a
                @click="onGetDocument(LegalDocumentTypes.propertyValuation)"
                href="#"
            >Property Valuation</a> </span>,
            <a
                href="/disclosures"
                target="_blank"
            >Disclosures</a>, and
            <a
                @click="onGetDocument(LegalDocumentTypes.creditScoreDisclosure)"
                href="#"
            >Credit Score Disclosure</a>
        </p>
        <p class="small text-muted text-start mt-2 mb-0">
            <span>
                {{ $t('pages.origination.preQualification.legalText3') }}
            </span>
        </p>
        <p
            class="small text-muted text-start mt-2 mb-0"
            v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.prequalFooter`)"
        />

        <div
            class="small text-muted text-start mt-2 mb-0"
            v-html="$t('pages.origination.preQualification.cashbackFootnote')"
        />
    </div>
</template>

<script>
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { LegalDocumentTypes } from '@/services/api'
    export default {
        name: 'PreQualTerms',
        mixins: [experimentsMixin],
        props: {
            onGetDocument: { type: Function, required: true },
            isConfirmedFirstLienPosition: { type: Boolean, required: true },
        },
        data: function () {
            return {
                LegalDocumentTypes,
            }
        },
    }
</script>

<style scoped></style>
