<template>
    <onboarding-layout
        :title="title"
        :sub-title="subTitle"
        :error-text="errorText"
        data-testid="pay-stub-verification-page"
    >
        <upload-button
            class="mb-2"
            title="Latest Pay Stub"
            @on-file-change="uploadPayStub($event, 1)"
            :is-complete="hasDocumentUploaded(1)"
            :is-submitting="isDocumentUploading(1)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <template v-for="(uploadButton, index) in uploadFileList">
            <upload-button
                :key="`upload-button-${index + 2}`"
                class="mb-2"
                title="Add More (Optional)"
                @on-file-change="uploadPayStub($event, index + 2)"
                :is-complete="hasDocumentUploaded(index + 2)"
                :is-submitting="isDocumentUploading(index + 2)"
                :disabled="!!currentlyUploadingIndex"
                incomplete-text="Add"
                complete-text="✓ Added"
                accept="image/*,application/pdf"
            />
        </template>

        <form-button
            v-if="hasDocumentUploaded(1)"
            type="button"
            label="Continue"
            class="mt-2"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_continue_verify_pay_stub"
            data-testid="pay-stub-verification-page_continue-button"
        />

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { mixins } from '@/mixins/mixins'
    import { i18n } from '@/utils/i18n'
    import FormButton from '@/components/base/FormButton'
    import UploadButton from '@/components/UploadButton'
    import { getNextRoute } from '@/flow/flowController'
    import { beginPayStubVerification, uploadDocument } from '@/services/api'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        components: {
            FeedbackListItem,
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
            'upload-button': UploadButton,
            'offer-info-card': OfferInfoCard,
        },
        mixins: [mixins, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        computed: {
            title() {
                if (!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                    // Individual applicant
                    return i18n.t('payStubUpload.title', { firstName: this.verifyingApplicantName })
                } else {
                    // Primary or co-applicant
                    return i18n.t('payStubUpload.titleWithCoApplicant', { firstName: this.verifyingApplicantName })
                }
            },
            subTitle() {
                return this.$route.query.verificationInProgress ? i18n.t('pages.shared.incomeVerification.verificationInProgress', { uploadedDocumentDescriptor: 'your latest pay stub' }) : null
            },
        },
        mounted: function () {
            let previousUploadInfo = appSessionStorage.getItem(sessionStorageKey.payStubInfo)
            if (previousUploadInfo) {
                this.uploadFileList = JSON.parse(previousUploadInfo).map((info) => !!info)
            } else {
                appSessionStorage.setItem(sessionStorageKey.payStubInfo, JSON.stringify([]))
                this.uploadFileList = []
            }

            this.$logEvent('view_pay_stub_verification')
        },
        methods: {
            uploadPayStub: async function (fileList, index) {
                this.$logEvent('click_button_upload_pay_stub')
                this.errorText = ''
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Pay stub file was not selected.')
                        this.errorText = i18n.tc('payStubUpload.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const payStubFileInfo = this.getFileInfo(file)
                    const previousFileUploads = JSON.parse(appSessionStorage.getItem(sessionStorageKey.payStubInfo)) || []

                    // check if same file have already been uploaded
                    if (previousFileUploads.includes(payStubFileInfo)) {
                        logger.warn('Pay stub file already exists in storage')
                        this.errorText = i18n.tc('payStubUpload.errorDuplicate')
                        this.scrollToTop()
                        return
                    }

                    const response = await uploadDocument(file, 'payStub', index, this.isCoApplicantVerifyingIncome)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.tc('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = previousFileUploads[index - 1] = payStubFileInfo
                    appSessionStorage.setItem(sessionStorageKey.payStubInfo, JSON.stringify(previousFileUploads))
                } catch (error) {
                    logger.error(`Failed to upload pay stub`, error)
                    this.errorText = i18n.tc('payStubUpload.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                }
            },
            next: async function () {
                if (this.submitting) return
                this.submitting = true
                this.errorText = ''

                try {
                    await beginPayStubVerification(this.isCoApplicantVerifyingIncome)
                    appSessionStorage.setItem(sessionStorageKey.incomeVerificationCompleted, 'true')
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    logger.error('Error beginning pay stub verification', error)
                    this.errorText = ApiErrorHandler(error)
                    this.submitting = false
                }
            },
        },
    }
</script>
