<template>
    <onboarding-layout
        :loading="loading || submitting"
        :loading-title="submitting ? 'Saving...' : 'Loading...'"
        :title="title"
        :error-text="errorText"
    >
        <div v-if="missingFirstSecondaryRelationship">
            <form-container
                ref="missing-first-secondary-relationship-form"
                @on-submit="onSubmit"
            >
                <form-radio-group
                    class="mb-5"
                    data-testid="missing-first-secondary-relationship"
                    :options="missingSecondarySignerNames"
                    :name="missingSecondarySignerNamesInput"
                    v-model="primaryApplicantSpouse"
                    validation-rules="required"
                />
                <form-button
                    class="mt-3"
                    :label="$t('global.cta.continue')"
                    :disabled="!primaryApplicantSpouse"
                    @click="submitSpouseNameOrOther"
                    event-name="click_button_submit_missing_primary_spouse_name"
                    data-testid="secondarySigners-declare-spouse"
                />
            </form-container>
        </div>

        <div v-else>
            <div
                class="alert alert-secondary border-0 d-flex flex-row align-items-center mb-4 border-0 p-2 small"
                style="margin-top: -1.5rem"
            >
                <div class="text-muted">
                    We legally need to collect signatures from everyone on a home's title, including their spouses. If the list below is incorrect, please
                    <button
                        class="btn-clickable-text fw-bold text-underline"
                        @click="handleDisputeClick"
                    >
                        click here
                    </button> for our team to conduct a manual review. This can take a few days.
                    <br>
                    <p
                        v-for="(secondaryApplicant, i) in secondarySignersList"
                        :key="`secondary-${i}`"
                        class="mb-0 fw-bold"
                    >
                        {{ secondaryApplicant }}
                    </p>
                </div>
            </div>
            <form-container
                id="coOwnerForm"
                ref="secondarySignersForm"
                @on-submit="onSubmit"
            >
                <div
                    v-for="(secondaryApplicant, i) in missingSecondaryApplicants"
                    :key="`secondary-${i}`"
                    class="secondary-applicant"
                >
                    <div class="icon-number">
                        <img
                            class="image-fluid"
                            :src="require(`@/assets/images/origination/${i + 1}.svg`)"
                            :alt="$t(`${i + 1}`)"
                        >
                    </div>
                    <div class="secondary-info">
                        <p class="fw-bold">
                            {{ secondaryApplicant.suggestedName }}
                        </p>

                        <div class="row g-2">
                            <div class="col">
                                <form-field
                                    v-model="secondaryApplicant.firstName"
                                    :disabled="!secondaryApplicant.isCoOwner"
                                    validation-rules="required|min:2"
                                    validation-mode="lazy"
                                    :data-testid="`secondary-first-name-input-${i}`"
                                    :name="`secondary-${i}-firstName`"
                                    placeholder="First Name"
                                    label="First Name"
                                    key="First Name"
                                />
                            </div>
                            <div class="col">
                                <form-field
                                    v-model="secondaryApplicant.lastName"
                                    :disabled="!secondaryApplicant.isCoOwner"
                                    validation-rules="required|min:2"
                                    validation-mode="lazy"
                                    :data-testid="`secondary-last-name-input-${i}`"
                                    :name="`secondary-${i}-lastName`"
                                    placeholder="Last Name"
                                    label="Last Name"
                                    key="Last Name"
                                />
                            </div>
                            <span
                                class="small text-gray-400 mt-1"
                                v-show="secondaryApplicant.isCoOwner"
                            >Please use full legal name from a valid state ID or passport</span>
                        </div>
                        <form-field-phone
                            class="mt-2"
                            :name="`secondary-phone-input-${i}`"
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            :data-testid="`secondary-phone-input-${i}`"
                            v-model="secondaryApplicant.phoneNumber"
                            validation-rules="required"
                            validation-mode="lazy"
                        />
                        <div
                            v-if="!secondaryApplicant.isCoOwner"
                            class="mt-1"
                        >
                            <span class="text-gray-400">{{ `${secondaryApplicant.firstName} is a co-applicant and will be sent a card` }}</span>
                        </div>
                    </div>
                </div>

                <form-button
                    class="mt-4"
                    label="Continue"
                    :block="true"
                    :submitting="submitting"
                    @click="submitSecondaryApplicantInfo"
                    data-testid="secondary-info-submit-button"
                    event-name="click_button_submit_co_owner_form"
                />

                <p class="text-muted mt-2 mb-0 text-center">
                    {{ $t('pages.origination.coOwner.smsAuth') }}
                </p>
            </form-container>
        </div>
        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <why-other-signers-list-item />
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getCoOwnerInfo, ParentApplicantRelationshipType, postCoOwnerInfo } from '@/services/homeApi'
    import { getNextRoute } from '@/flow/flowController'
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import FormField from '@/components/base/FormField'
    import FormContainer from '@/components/base/FormContainer'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import FormButton from '@/components/base/FormButton'
    import originationMixin from '@/mixins/originationMixin'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import WhyOtherSignersListItem from '@/components/onboarding/WhyOtherSignersListItem'
    import { getTrustInfo } from '@/services/api'
    import { inspect, logger } from '@/utils/logger'
    import startCase from 'lodash/startCase'
    import toLower from 'lodash/toLower'
    import { PostCoOwnerInfoError, postCoApplicantDetails } from '@/services/homeApi'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { cloneDeep, pull } from 'lodash'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { i18n } from '../../utils/i18n'
    import { originationPagePaths } from '../../routes/originationRoutes'

    const spouseIsNotAListedApplicant = -1

    export default {
        components: {
            FeedbackListItem,
            'onboarding-layout': OnboardingLayout,
            'form-field': FormField,
            'form-radio-group': FormRadioGroup,
            'form-field-phone': FormFieldPhone,
            'form-container': FormContainer,
            'form-button': FormButton,
            'offer-info-card': OfferInfoCard,
            'why-other-signers-list-item': WhyOtherSignersListItem,
        },
        mixins: [originationMixin, computeCreditOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                ParentApplicantRelationshipType,

                loading: true,
                missingSecondaryApplicants: [],
                missingPrimaryApplicantSpouse: false,
                missingCoApplicantPhone: false,
                hasCoApplicant: false,

                secondarySignersList: [],
                missingSecondarySignerNames: [],
                primaryApplicantSpouse: undefined,
                // For debugging only
                missingHomeOwners: [],
            }
        },
        mounted: async function () {
            try {
                const trustResponse = await getTrustInfo()
                if (trustResponse.data.payload.isTrust) {
                    logger.info('Found trust, skipping co-owner page')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                const signerCount = await this.updatePageDataAndGetSignerCount()
                if (signerCount === 0) {
                    logger.info('0 missing signers detected, moving to next page')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                this.missingSecondarySignerNames = this.getNameOfSpouseOptions()

                this.loading = false
                this.$logEvent('view_co_owner', { signerCount })
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            // Only allow checking one authorized user
            // (coApplicant is always a default authorized user)
            showCheckboxes: function () {
                if (!this.missingSecondaryApplicants?.length) return true

                if (this.hasCoApplicant) return false

                return !this.missingSecondaryApplicants.some((secondaryApplicant) => secondaryApplicant.cardCreation)
            },
            missingFirstSecondaryRelationship: function () {
                // Secondary signers, if they exist, are classified as MARRIED or OTHER (read: coowners). If we have a
                // coowner, then we need to ask the primary to pick their spouse from a list of all coowners and give
                // an option to add the spouse if they are not in the list
                return this.missingPrimaryApplicantSpouse && this.missingSecondaryApplicants?.length > 0 && this.missingSecondaryApplicants[0].suggestedName
            },
            title: function () {
                if (this.loading || this.submitting) {
                    return ''
                }

                // if we don't know the spouse, ask
                if (this.missingFirstSecondaryRelationship) {
                    return `To know who else might need to sign, who is ${appSessionStorage.getItem(sessionStorageKey.firstName)}'s spouse?`
                }

                // tell the primary we need all coowners + spouse (if married & not a coowner) to sign
                const nMissingSecondarySigners = this.missingSecondaryApplicants.length
                let personOrPeople = 'person'
                if (nMissingSecondarySigners > 1) {
                    personOrPeople = 'people'
                }
                return `It looks like ${nMissingSecondarySigners} other ${personOrPeople} will need to sign online as well.  Please confirm their information.`
            },
        },
        methods: {
            handleDisputeClick: async function () {
                logger.log('Applicant wants to dispute the list of co-owners, redirecting to dispute_co_owner_info page')
                try {
                    this.$logEvent('click_dispute_co_owner_info')
                    appSessionStorage.setItem(sessionStorageKey.secondarySignersList, JSON.stringify(this.secondarySignersList))
                    await this.$router.push(originationPagePaths.DISPUTE_CO_OWNER_INFO)
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
            },
            getNameOfSpouseOptions: function () {
                logger.log(`There are ${this.missingSecondaryApplicants.length} missing applicants, asking customer to choose which (if any) is their spouse`)
                const missingSecondarySignerNames = []
                this.missingSecondaryApplicants.forEach((applicant, index) => {
                    logger.log(`Filling array for missing applicant ${applicant.suggestedName}`)
                    missingSecondarySignerNames.push({ name: applicant.suggestedName, value: `${index}` })
                })
                missingSecondarySignerNames.push({
                    name: i18n.t('pages.origination.personalInfo.missingSecondarySignerRelationship.notListedHere'),
                    value: `${spouseIsNotAListedApplicant}`,
                })

                return missingSecondarySignerNames
            },
            updatePageDataAndGetSignerCount: async function () {
                const coOwnerResponse = await getCoOwnerInfo()

                const {
                    data: {
                        payload: { missingPrimaryApplicantSpouse, missingHomeOwners, missingCoApplicantPhone },
                    },
                } = coOwnerResponse
                this.hasCoApplicant = !!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)
                this.missingPrimaryApplicantSpouse = missingPrimaryApplicantSpouse
                this.missingCoApplicantPhone = missingCoApplicantPhone
                this.missingHomeOwners = missingHomeOwners

                logger.log('Got coOwnerInfo, resetting missingSecondaryApplicants array')

                // We need to set this to 'reset' the list of elements in the DOM
                // Some of them don't react to changing inputs (i.e. form-field-phone) and so have to be destroyed to clear them
                this.missingSecondaryApplicants = []

                // Wait for the next tick before setting the new missingSecondaryApplicants, otherwise the reset above won't work
                await this.$nextTick()

                this.missingSecondaryApplicants = missingHomeOwners.map((missingHomeOwner) => this.convertMissingHomeOwnersToCoOwnerSchema(missingHomeOwner, ParentApplicantRelationshipType.OTHER))
                logger.log(`Created this.missingSecondaryApplicants array: ${inspect(this.missingSecondaryApplicants)}`)

                // Detect if we have coApplicants
                // If so, let's collect their phone numbers here
                // (But don't allow them to change the listed name)
                if (this.missingCoApplicantPhone) {
                    logger.log('Updating this.missingSecondaryApplicants with coApplicant because this.hasCoApplicant is true and missingCoApplicantPhone is true')
                    // We ALWAYS want to suggest coApplicants first
                    this.missingSecondaryApplicants.unshift({
                        suggestedName: `${appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)} ${appSessionStorage.getItem(sessionStorageKey.coApplicantLastName)}`,
                        firstName: appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName),
                        lastName: appSessionStorage.getItem(sessionStorageKey.coApplicantLastName),
                        cardCreation: true,
                        isCoOwner: false,
                        parentApplicantRelationship: ParentApplicantRelationshipType.OTHER,
                        phoneNumber: '',
                    })
                } else if (this.missingPrimaryApplicantSpouse && this.missingSecondaryApplicants.length === 0) {
                    // User stated they're married so we need the spouse's signature. If we
                    // don't have a name on the deed, we need to add a field for spouse.
                    this.missingSecondaryApplicants.unshift(this.convertMissingHomeOwnersToCoOwnerSchema('', ParentApplicantRelationshipType.MARRIED))
                }

                if (missingHomeOwners.length === 0 && !this.missingPrimaryApplicantSpouse && !this.missingCoApplicantPhone) {
                    logger.info('No additional signers are necessary for this applicant and no co-applicant data is needed, moving along...')
                    // return 0 and allow caller to navigate away from the page
                    return 0
                }

                logger.info(
                    `Additional info needed, missingHomeOwners.length: ${missingHomeOwners.length}, this.missingPrimaryApplicantSpouse: ${this.missingPrimaryApplicantSpouse}, this.missingCoApplicantPhone: ${this.missingCoApplicantPhone}`
                )

                if (!this.missingPrimaryApplicantSpouse) {
                    // Additional signers required AND they are not looking at the "how are you related" question
                    this.$logEvent('view_co_owner_form')
                }

                return this.missingSecondaryApplicants.length
            },
            convertMissingHomeOwnersToCoOwnerSchema: function (suggestedOwnerName, parentApplicantRelationship) {
                // We're explicitly creating the schema we want
                // This is more resilient than depending on the exact format of the data returned from the backend
                return {
                    suggestedName: startCase(toLower(suggestedOwnerName)),
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    cardCreation: false,
                    isCoOwner: true,
                    parentApplicantRelationship,
                }
            },
            submitSpouseNameOrOther: function () {
                const primaryApplicantSpouseArrayPosition = Number(this.primaryApplicantSpouse)
                logger.log(`The user is married to ${primaryApplicantSpouseArrayPosition}`)
                if (primaryApplicantSpouseArrayPosition !== spouseIsNotAListedApplicant) {
                    this.$logEvent('click_button_submit_co_owner_status_spouse')
                    logger.log('User said they are married to one of the missing secondary applicants, setting parentApplicantRelationship for secondaryApplicant to MARRIED')
                    // This might be a coApplicant OR a coOwner
                    this.missingSecondaryApplicants[primaryApplicantSpouseArrayPosition].parentApplicantRelationship = ParentApplicantRelationshipType.MARRIED
                    // Hey look, we found their spouse here ^
                } else {
                    // the spouse is the 'Not Listed Here' applicant option
                    this.$logEvent('click_button_submit_co_owner_status_other')
                    logger.log('User said they are not married to any of the missing secondary applicants, adding an additional coOwner for spouse')
                    this.missingSecondaryApplicants.unshift({
                        suggestedName: 'Your Spouse',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        cardCreation: false,
                        isCoOwner: true,
                        parentApplicantRelationship: ParentApplicantRelationshipType.MARRIED,
                    })
                    // Hey look, we created their spouse right here! ^
                }

                this.missingPrimaryApplicantSpouse = false
                this.$logEvent('view_co_owner_form')

                // populate the list of people who need to sign
                this.missingSecondaryApplicants.forEach((applicant, index) => {
                    logger.log(`adding ${applicant.suggestedName} to the secondary signers list`)
                    this.secondarySignersList.push(`${index + 1}. ${applicant.suggestedName}`)
                })
            },
            handleSecondarySubmissionError: function (error, proposedSecondaryApplicant) {
                switch (error) {
                    case PostCoOwnerInfoError.INVALID_US_PHONE_NUMBER_ERROR:
                        this.$logEvent('secondary_signer_fail_state_invalid_number')
                        this.errorText = `Invalid US Phone number. Please try again.`
                        break
                    case PostCoOwnerInfoError.PHONE_NUMBER_ALREADY_EXISTS:
                        this.$logEvent('secondary_signer_fail_state_number_exists')
                        this.errorText = `Phone number is already associated with an active account, please contact support.`
                        break
                    case PostCoOwnerInfoError.ERROR_COOWNER_NOT_FOUND:
                        this.$logEvent('secondary_signer_fail_state_coowner_not_found')
                        this.errorText = `Name does not match co-owner listed on the property. Please try again.`
                        break
                    case PostCoOwnerInfoError.ERROR_APPLICANT_PHONE_CANNOT_BE_SHARED:
                        this.$logEvent('secondary_signer_fail_state_applicant_phone_cannot_be_shared')
                        this.errorText = `Co-${!proposedSecondaryApplicant.isCoOwner ? 'applicant' : 'owner'}'s phone number cannot be shared with existing applicants. Please try again.`
                        break
                    case PostCoOwnerInfoError.ERROR_APPLICANT_PHONE_BELONGS_TO_PRIMARY:
                        this.$logEvent('secondary_signer_fail_state_applicant_phone_belongs_to_coapplicant')
                        this.errorText = `Phone number already belongs to the primary applicant. Please try again with a new number.`
                        break
                    case PostCoOwnerInfoError.ERROR_APPLICANT_PHONE_BELONGS_TO_COAPPLICANT:
                        this.$logEvent('secondary_signer_fail_state_applicant_phone_belongs_to_coapplicant')
                        this.errorText = `Phone number already belongs to the coapplicant. Please try again with a new number.`
                        break
                    case PostCoOwnerInfoError.ERROR_APPLICANT_NAME_ALREADY_EXISTS:
                        this.$logEvent('secondary_signer_fail_state_applicant_name_exists')
                        this.errorText = `${proposedSecondaryApplicant.firstName} ${proposedSecondaryApplicant.lastName} is already a signer. Please try again with a different person.`
                        break
                    default:
                        this.$logEvent('secondary_signer_fail_state_unknown')
                        this.errorText = ApiErrorHandler(error)
                        break
                }
            },
            submitSecondaryApplicantInfo: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true
                this.errorText = ''

                const isValid = await this.$refs.secondarySignersForm.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                // We want to clone this b/c removing elements from an array while iterating on it is bad
                const missingSecondaryApplicantsClone = cloneDeep(this.missingSecondaryApplicants)
                for (const proposedSecondaryApplicant of missingSecondaryApplicantsClone) {
                    logger.log(`posting information for secondary applicant: ${JSON.stringify(proposedSecondaryApplicant)}`)
                    try {
                        let resp
                        if (!proposedSecondaryApplicant.isCoOwner) {
                            // This is actually a coApplicant, update their phone number and move on
                            logger.log(`Attempting to post proposedCoApplicant ${JSON.stringify(proposedSecondaryApplicant)}`)
                            resp = await postCoApplicantDetails(
                                proposedSecondaryApplicant.phoneNumber,
                                proposedSecondaryApplicant.firstName,
                                proposedSecondaryApplicant.lastName,
                                proposedSecondaryApplicant.parentApplicantRelationship
                            )
                        } else {
                            // There's a primaryApplicantRelationshipType included, ergo this is a coOwner we're looking at
                            logger.log(`Attempting to post proposedSecondaryApp ${JSON.stringify(proposedSecondaryApplicant)}`)
                            resp = await postCoOwnerInfo(
                                proposedSecondaryApplicant.phoneNumber,
                                proposedSecondaryApplicant.firstName,
                                proposedSecondaryApplicant.lastName,
                                proposedSecondaryApplicant.cardCreation,
                                proposedSecondaryApplicant.parentApplicantRelationship
                            )
                        }

                        // The format of the response from the updateApplicantInfo endpoint is strange
                        if (resp.data?.payload?.errors?.[0]?.errorCode) {
                            // Map it to a more reasonable place to check for errors
                            resp.data.error = resp.data?.payload?.errors?.[0]?.errorCode
                        }

                        // If there was an error
                        if (resp.data.error) {
                            this.handleSecondarySubmissionError(resp.data.error, proposedSecondaryApplicant)

                            logger.log(`Detected error during proposedSecondaryApp submission, halting further submissions: ${resp.data.error}`)
                            // Don't take the proposedSecondaryApp out of missingSecondaryApplicants, b/c it was not successfully submitted
                            break
                        }
                        // If there was no error
                        else {
                            logger.log(`Successfully posted proposedSecondaryApp ${JSON.stringify(proposedSecondaryApplicant)} !`)
                            // Remove this element from the missingSecondaryApplicants array (b/c we were successful)
                            pull(this.missingSecondaryApplicants, proposedSecondaryApplicant)
                        }
                    } catch (error) {
                        this.errorText = ApiErrorHandler(error)
                        // Don't take the proposedSecondaryApp out of missingSecondaryApplicants, b/c it was not successfully submitted
                        break
                    }
                }

                // At this point, we should double-check with the backend that all missingSecondaryApplicants have been accounted for and redirect
                const signerCount = await this.updatePageDataAndGetSignerCount()
                if (signerCount === 0) {
                    logger.info('0 missing signers detected, moving to next page')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                this.submitting = false
            },
        },
    }
</script>

<style lang="scss">
    .secondary-applicant {
        display: flex;
        margin-bottom: 40px;
        p {
            margin-bottom: 8px;
        }
        .icon-number {
            margin-right: 8px;
        }
        .secondary-info {
            margin-top: 2px;
        }
    }
</style>
