<template>
    <p
        class="safe-credit-score text-center fw-bold small"
        v-html="safeCreditScoreText"
    />
</template>

<script>
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'SafeCreditScore',
        props: {
            safeCreditScoreText: {
                type: String,
                default: i18n.t('pages.marketing.landing.safeCreditScore'),
            },
        },
    }
</script>

<style>
    .thick-underline {
        border-bottom: 2px solid currentColor;
    }
</style>
