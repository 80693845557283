<template>
    <onboarding-layout
        :title="$t('pages.identity.title')"
        :loading="loading"
        :error-text="errorText"
        :loading-title="$t('global.loadingMessage.verifying')"
        step-title="Verify Identity"
    />
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { verifyApplicant, verifyApplicantFullSSN } from '@/services/idology'
    import { sessionStorageKey, appSessionStorage } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { RouteOption } from '@/flow/flowUtility'
    import originationMixin from '@/mixins/originationMixin'
    import assert from 'assert'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        props: {
            isCoApplicant: { type: Boolean, default: false },
        },
        data() {
            return {
                loading: true,
            }
        },
        mixins: [originationMixin],
        mounted: async function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)

            if (this.isCoApplicant && !this.hasCoApplicant) {
                // This page is hit with this.isCoApplicant as true by everyone. If individual applicant just move on.
                return await this.$router.replace(getNextRoute(this.$router))
            }

            if (appSessionStorage.getItem(sessionStorageKey.verifiedKba) === 'true') {
                appSessionStorage.removeItem(sessionStorageKey.verifiedKba)
                return await this.$router.replace(getNextRoute(this.$router))
            }

            try {
                await this.verify()
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            verify: async function () {
                const verifyFullSSNResponse = await verifyApplicantFullSSN(this.isCoApplicant)
                logger.info(`verifyFullSSNResponse: ${JSON.stringify(verifyFullSSNResponse.data)}`)

                switch (verifyFullSSNResponse.data.payload.status) {
                    case 'pass': {
                        // Continue to verifyApplicant()
                        logger.info('User passed full ssn check, verifying applicant.')
                        break
                    }
                    case 'fail': {
                        // user doesn't have full 9 ssn
                        if (this.isCoApplicant) {
                            logger.info('Asking CoApplicant for full ssn')
                            return await this.$router.replace(getNextRoute(this.$router, RouteOption.coApplicantSsnVerification))
                        } else {
                            logger.info('Asking Applicant for full ssn')
                            return await this.$router.replace(getNextRoute(this.$router, RouteOption.ssnVerification))
                        }
                    }
                    default:
                        this.errorText = ApiErrorHandler(new Error('postssn - Unknown response.data structure'))
                        break
                }

                const verifyApplicantResponse = await verifyApplicant(this.isCoApplicant)
                logger.info(`verifyApplicantResponse: ${JSON.stringify(verifyApplicantResponse.data)}`)

                if (verifyApplicantResponse.data.success) {
                    const payload = verifyApplicantResponse.data.payload
                    switch (payload.status) {
                        case 'pass': {
                            // Continue with loan application
                            logger.info('User passed verify applicant, proceeding to next route.')
                            return await this.$router.replace(getNextRoute(this.$router))
                        }
                        case 'allowableFail': {
                            logger.info('User received allowable fail, proceeding to next route.')
                            return await this.$router.replace(getNextRoute(this.$router))
                        }
                        case 'questions': {
                            // show Idology questions view
                            logger.info(`idology questions: ${JSON.stringify(payload)}`)
                            appSessionStorage.setItem(sessionStorageKey.identityQA, JSON.stringify(payload))
                            return await this.$router.replace(getNextRoute(this.$router, RouteOption.identityQuestions))
                        }
                        default:
                            this.errorText = ApiErrorHandler(new Error('postssn - Unknown response.data structure'))
                            break
                    }
                } else if (verifyApplicantResponse.data.error === 'INCOMPLETE_APPLICATION') {
                    logger.info(`verifyIdentity error: ${verifyApplicantResponse.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identity' } })
                } else if (verifyApplicantResponse.data.error === 'OUT_OF_COUNTRY') {
                    logger.info(`verifyIdentity error: ${verifyApplicantResponse.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'geo' } })
                } else if (verifyApplicantResponse.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(verifyApplicantResponse.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${verifyApplicantResponse.data.payload.returnToken2}`)
                    const nextPath = verifyApplicantResponse.data.payload.isPriorityApplication ? sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION : sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION
                    const pathAfterNextOne = getNextRoute(this.$router)
                    return await this.$router.push({
                        path: nextPath,
                        query: {
                            p: verifyApplicantResponse.data.payload.returnToken2,
                            a: pathAfterNextOne,
                            c: this.isCoApplicant,
                            s: verifyApplicantResponse.data.payload.priorStatus,
                        },
                    })
                } else {
                    // Want to skip this page in the event the user goes 'back'
                    await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }
            },
        },
    }
</script>

<style scoped></style>
