<template>
    <onboarding-layout
        :loading="loading"
        :error-text="errorText"
        loading-title="Finalizing lookup..."
        data-testid="employment-income-verification-page"
    >
        <h5 class="fw-light mb-0">
            Let’s quickly verify <span class="fw-bold"> {{ currentApplicantName }}'s </span>
            employment and income
        </h5>
        <p class="text-muted mt-1">
            Unlike a traditional mortgage or HELOC, we use technology to shrink your process to a few minutes.
        </p>
        <form-container
            id="employerForm"
            ref="employerFormRef"
        >
            <div class="mt-3">
                <div class="row g-0">
                    <div class="col-6 custom-control custom-radio d-inline-flex custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline1"
                            name="customRadioInline1"
                            class="custom-control-input"
                            :value="EmploymentType.employed"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline1"
                            data-testid="employment-income-verification-employed-option"
                        >Employed</label>
                    </div>
                    <div class="col custom-control custom-radio custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline2"
                            name="customRadioInline2"
                            class="custom-control-input"
                            :value="EmploymentType.selfEmployed"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline2"
                            data-testid="employment-income-verification-self-employed-option"
                        >Self Employed</label>
                    </div>
                </div>
                <div class="mt-2 row g-0">
                    <div class="col-6 custom-control custom-radio d-inline-flex custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline3"
                            name="customRadioInline3"
                            class="custom-control-input"
                            :value="EmploymentType.retired"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline3"
                            data-testid="employment-income-verification-retired-option"
                        >Retired</label>
                    </div>
                    <div class="col custom-control custom-radio custom-control-tight">
                        <input
                            type="radio"
                            id="customRadioInline4"
                            name="customRadioInline4"
                            class="custom-control-input"
                            :value="EmploymentType.other"
                            v-model="employmentType"
                        >
                        <label
                            class="custom-control-label custom-control-label-tight"
                            for="customRadioInline4"
                            data-testid="employment-income-verification-other-option"
                        >Other</label>
                    </div>
                </div>
            </div>

            <div v-if="employmentType && employmentType !== EmploymentType.retired">
                <form-field
                    v-model="employer"
                    class="mt-3"
                    validation-mode="passive"
                    validation-rules="required|min:2"
                    name="employer"
                    ref="employer"
                    placeholder="Employer / Business Name"
                    label="Employer / Business Name"
                    data-testid="employment-income-verification-employer-input"
                />
            </div>
        </form-container>

        <div
            v-show="showIncomeVerificationCards"
            class="fade-in"
            data-testid="employment-income-verification-cards"
        >
            <div class="section-header text-center fw-bold mt-5">
                PICK ONE
            </div>
            <div
                class="text-center"
                style="font-size: 80%"
                v-if="isJointApplication && hasExtendedLinesOffer"
            >
                Be sure to upload documents for <span class="fw-bold">both</span> {{ primaryApplicantName }} and {{ secondaryApplicantName }}
            </div>
            <div class="row mt-3">
                <div class="col pe-1">
                    <document-verification-option-card
                        image-src="global/saving-bank.svg"
                        image-alt="bank"
                        title="Verify Bank"
                        description="Securely sign-in to your<br/>primary account"
                        badge-text="Fastest"
                        @on-click="onPlaidConnectClick"
                        data-testid="employment-income-verification-plaid-option"
                    />
                </div>
                <div class="col ps-1">
                    <document-verification-option-card
                        image-src="global/accounting-document.svg"
                        image-alt="accounting document"
                        title="Pay Stub"
                        description="Photo of your latest<br/>pay stub"
                        @on-click="redirectToPayStubsVerification"
                        data-testid="employment-income-verification-pay-stub-option"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col pe-1">
                    <document-verification-option-card
                        image-src="global/accounting-invoice-hand.svg"
                        image-alt="tax document"
                        title="Tax Return"
                        description="Front page of your<br/>2020 tax return"
                        @on-click="redirectToTaxReturnVerification"
                    />
                </div>
                <div class="col ps-1">
                    <document-verification-option-card
                        image-src="global/common-file-text-add.svg"
                        image-alt="add document"
                        title="Other"
                        description="W2, 1099’s,<br/>Award Letter"
                        @on-click="redirectToOtherIncomeVerification"
                        data-testid="employment-income-verification-other-letter-option"
                    />
                </div>
            </div>
        </div>

        <div v-show="showContinueButton">
            <form-button
                class="mt-3"
                :label="$t('pages.origination.employer.cta')"
                :submitting="submitting"
                type="button"
                event-name="click_button_submit_employer_form"
                @click="handleClickContinue"
            />
        </div>

        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <feedback-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormField from '@/components/base/FormField'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import { misc } from '@/mixins/misc'
    import originationMixin from '@/mixins/originationMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { updateEmployer } from '@/services/loanApplication'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import DocumentVerificationOptionCard from '@/components/DocumentVerificationOptionCard'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'
    import { RouteOption } from '@/flow/flowUtility'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { PlaidCompletionResponse, PlaidManager } from '@/mixins/plaidManager'
    import { startCase, toLower } from 'lodash'
    import { getIsIncomeVerified, IncomeVerificationPurpose } from '../../services/api'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    const EmploymentType = {
        employed: 'employed',
        selfEmployed: 'selfEmployed', // Must change python code if this word is changed
        retired: 'retired', // Must change python code if this word is changed
        other: 'other', // Must change python code if this word is changed
    }

    export default {
        name: 'EmploymentIncomeVerification',
        components: {
            FeedbackListItem,
            'document-verification-option-card': DocumentVerificationOptionCard,
            'form-container': FormContainer,
            'form-field': FormField,
            'form-button': FormButton,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
        },
        props: {
            isCoApplicant: {
                type: Boolean,
                default: false,
            },
        },
        mixins: [misc, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                loading: true,
                EmploymentType: EmploymentType,
                employmentType: null,
                employer: '',
                jobTitle: '',
                plaidManager: new PlaidManager(this.onPlaidSuccess, this.onNoDepositoryAccountFound, this.onPlaidExit),
                incomeVerificationCompleted: false, // can be set by WorkNumber OR when a user has already uploaded verification documents
                hasExtendedLinesOffer: false, // we currently only want to show bank verify to non-extended lines
            }
        },
        mounted: async function () {
            // this.isCoApplicant will be true or false depending on whether this page is being displayed
            // on the primary applicant income verification route or the co-applicant income verification
            // route:
            // originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION
            // originationPagePaths.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION
            // In the co-applicant case, we'll mount this page whether or not the application has a co-applicant.
            // If it does, we'll continue with the rest of the logic below. If it does not, we'll navigate
            // to the next route, skipping co-applicant income verification.
            if (this.isCoApplicant && !this.hasCoApplicant) {
                logger.info(`Applicant is co-applicant, but application has no co-applicant (?!?!), navigating from income verification to next route`)
                return await this.$router.replace(getNextRoute(this.$router))
            }

            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
            const preQualFailureCode = appSessionStorage.getItem(sessionStorageKey.preQualificationFailureCode)
            if (preQualFailureCode) {
                logger.info(`Application has pre-qual failure code of ${preQualFailureCode}, navigating from income verification to next route`)
                return await this.$router.push(getNextRoute(this.$router))
            }

            // Not redirecting for other reasons - beginning income verification - fire an event so we can keep track of everyone who attempted income verification
            this.$logEvent('attempt_employment_income_verification')

            try {
                // Check if we can skip this page entirely from pre-existing prequal work number income or experian if applicable
                const isStatedIncomeValidated = await this.getIsIncomeVerified(IncomeVerificationPurpose.automatic)
                if (isStatedIncomeValidated) {
                    logger.info('Stated income is already validated. Skipping income verification page and continuing on.')
                    return await this.$router.replace(getNextRoute(this.$router))
                }

                const applicantSubmittedEmployer = appSessionStorage.getItem(sessionStorageKey.applicantSubmittedEmployer) === 'true'
                const coApplicantSubmittedEmployer = appSessionStorage.getItem(sessionStorageKey.coApplicantSubmittedEmployer) === 'true'
                const currApplicantSubmittedEmployer = !this.isCoApplicant ? applicantSubmittedEmployer : coApplicantSubmittedEmployer
                const submittedIncomeDocuments = appSessionStorage.getItem(sessionStorageKey.incomeVerificationCompleted) === 'true'

                if (this.isCurrentApplicantVerifying) {
                    logger.info('Current applicant is verifying income...')
                    if (submittedIncomeDocuments && currApplicantSubmittedEmployer) {
                        logger.info('Current applicant has already submitted income docs and submitted employer. Navigating to next route')
                        return await this.$router.replace(getNextRoute(this.$router))
                    }

                    await this.initializePlaid()

                    this.incomeVerificationCompleted = await this.getIsIncomeVerified(IncomeVerificationPurpose.automatic)
                    if (this.incomeVerificationCompleted && currApplicantSubmittedEmployer) {
                        logger.info('Current applicant has completed income verification and submitted employer. Navigating to next route')
                        return await this.$router.replace(getNextRoute(this.$router))
                    }
                } else if (currApplicantSubmittedEmployer) {
                    logger.info('Current applicant has already submitted employer and is verified. Navigating to next route')
                    // primary or co applicant already submitted employer and is verified, go to next screen
                    return await this.$router.replace(getNextRoute(this.$router))
                }

                this.hasExtendedLinesOffer = !!appSessionStorage.getItem(sessionStorageKey.hasExtendedLinesOffer)

                this.loading = false
                this.$logEvent('view_employment_income_verification')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            isCurrentApplicantVerifying: function () {
                return this.isCoApplicant ? this.isCoApplicantVerifyingIncome : !this.isCoApplicantVerifyingIncome
            },
            completedEmployer: function () {
                const requiresEmployerName = this.employer.length > 2 && this.employmentType !== EmploymentType.retired
                const employeeIsRetired = this.employmentType === EmploymentType.retired
                return this.employmentType && (requiresEmployerName || employeeIsRetired)
            },
            showIncomeVerificationCards: function () {
                // income verification has not completed
                // user has input an employer name or is retired
                // current applicant is verifying
                return !this.incomeVerificationCompleted && this.completedEmployer && this.isCurrentApplicantVerifying
            },
            showContinueButton: function () {
                return this.completedEmployer && (this.incomeVerificationCompleted || !this.isCurrentApplicantVerifying)
            },
            currentApplicantName() {
                if (this.isCoApplicant) {
                    return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)))
                } else {
                    return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.firstName)))
                }
            },
            isJointApplication() {
                return !!appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)
            },
            primaryApplicantName() {
                return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.firstName)))
            },
            secondaryApplicantName() {
                return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)))
            },
        },
        methods: {
            getIsIncomeVerified: async function (purpose) {
                const incomeVerificationResponse = await getIsIncomeVerified(purpose)
                const { isIncomeVerified, incomeVerificationMethod } = incomeVerificationResponse.data.payload
                if (isIncomeVerified) {
                    appSessionStorage.setItem(sessionStorageKey.incomeVerificationCompleted, 'true')
                }
                if (incomeVerificationMethod) {
                    appSessionStorage.setItem(sessionStorageKey.incomeVerificationMethod, incomeVerificationMethod)
                }
                return isIncomeVerified
            },
            submitEmployer: async function () {
                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.employerFormRef.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                // Set jobTitle to 'retired' if employer is 'retired'
                const employer = this.employmentType === EmploymentType.retired ? EmploymentType.retired : this.employer

                try {
                    // update applicant's employer and we set jobTitle to EmploymentType.*
                    this.jobTitle = this.employmentType
                    await updateEmployer(employer, this.jobTitle, this.isCoApplicant)

                    if (this.isCoApplicant) {
                        appSessionStorage.setItem(sessionStorageKey.coApplicantSubmittedEmployer, 'true')
                    } else {
                        appSessionStorage.setItem(sessionStorageKey.applicantSubmittedEmployer, 'true')
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                this.submitting = false
            },
            handleClickContinue: async function () {
                this.$logEvent('click_button_continue_employment_income_verification')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router))
            },
            redirectToPayStubsVerification: async function () {
                this.$logEvent('click_button_verify_income_with_pay_stub')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.payStubsVerify))
            },
            redirectToTaxReturnVerification: async function () {
                this.$logEvent('click_button_verify_income_with_tax_return')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.taxReturnVerify))
            },
            redirectToOtherIncomeVerification: async function () {
                this.$logEvent('click_button_verify_income_with_other')
                await this.submitEmployer()
                return await this.$router.push(getNextRoute(this.$router, RouteOption.otherIncomeVerify))
            },
            initializePlaid: async function () {
                const plaidInitialized = await this.plaidManager.init()
                if (!plaidInitialized) {
                    this.errorText = i18n.tc('conversation.adBlockMessage')
                }
            },
            onPlaidConnectClick: async function () {
                this.$logEvent('click_button_verify_income_with_plaid')
                await this.submitEmployer()
                this.errorText = '' // clear error message
                this.plaidManager.open()
            },
            onPlaidSuccess: async function (plaidPublicToken, accounts, institutionInfo) {
                logger.info('connected to bank account, trying to fetch plaid report...')
                this.loadingTitle = i18n.t('bankConnect.loadingContent')
                this.loading = true
                try {
                    const plaidCompletionResponse = await this.plaidManager.completePlaidFetch(plaidPublicToken, accounts, institutionInfo, this.isCoApplicant)
                    if (plaidCompletionResponse === PlaidCompletionResponse.success) {
                        if (await this.getIsIncomeVerified(IncomeVerificationPurpose.plaid)) {
                            logger.info('User qualifies without further income verification... Forwarding towards offer page!')
                            return await this.$router.push(getNextRoute(this.$router))
                        } else {
                            this.loading = false
                            this.loadingTitle = i18n.t('bankConnect.loading')
                            this.errorText = i18n.tc('bankConnect.insufficientIncome')
                            return
                        }
                    } else if (plaidCompletionResponse === PlaidCompletionResponse.nameMismatch) {
                        logger.info(`Plaid name mismatch.`)
                        appSessionStorage.setItem(sessionStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push(getNextRoute(this.$router))
                    }
                } catch (e) {
                    ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('bankConnect.loading')
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
            onNoDepositoryAccountFound: function (bankName) {
                logger.info('Error: Bank has no depository account')
                this.errorText = i18n.tc('conversation.noDepositoryAccount', null, { bankName })
            },
            // eslint-disable-next-line no-unused-vars
            onPlaidExit: function (error, metadata) {
                this.errorText = i18n.tc('bankConnect.tryAgain')
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/document-verification-selection-card';
</style>
