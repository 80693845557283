import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

const computeCreditOfferFromStorageMixin = {
    computed: {
        creditOffer() {
            const creditOffer = appSessionStorage.getItem(sessionStorageKey.creditOffer)
            return creditOffer ? JSON.parse(creditOffer) : null
        },
    },
}

export default computeCreditOfferFromStorageMixin
