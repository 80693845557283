<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <!-- made this boy invisible so we can keep spacing -->
            <img
                class="list-item-icon invisible"
                src="@/assets/images/global/icon-lock-outline.svg"
                alt="Fixed Payment"
            >
        </div>
        <div class="col text-start">
            <div class="text-muted">
                <div>
                    <div class="bg-gray-100 rounded-3 border-1 text-dark mb-2">
                        <div class="section-header fw-bold p-1 text-center bg-dark text-white header-rounded">
                            Fixed Monthly Payments
                        </div>
                        <div class="d-flex flex-row justify-content-between pt-2 px-2">
                            <div class="col-4 fw-bold border-secondary">
                                Monthly<br>Payment
                            </div>
                            <div class="col-4 text-end border-secondary">
                                Total<br>Paid
                            </div>
                            <div class="col-4 text-end border-secondary">
                                Months<br>Payback
                            </div>
                        </div>
                        <hr class="bg-dark">
                        <div
                            class="d-flex flex-row justify-content-between px-2"
                            v-for="(theoreticalFixedPaymentStat, index) in theoreticalFixedPaymentStats"
                            :key="`${index}-theoretical-fixed-payment-stat`"
                        >
                            <div
                                class="col-4 fw-bold border-0"
                                :data-testid="`payment-calculator-fixed-monthly-payment-${index}`"
                            >
                                {{ paymentFormatted(theoreticalFixedPaymentStat.monthlyPayment) }}
                            </div>
                            <div
                                class="col-4 border-0 text-end"
                                :data-testid="`payment-calculator-fixed-total-payment-${index}`"
                            >
                                {{ paymentFormatted(theoreticalFixedPaymentStat.totalPlusBtOrCoFee) }}
                            </div>
                            <div
                                class="col-4 border-0 text-end"
                                :data-testid="`payment-calculator-fixed-total-months-${index}`"
                            >
                                {{ termMonthsOptions[index] }}
                            </div>
                        </div>
                        <p class="small mb-0 text-muted p-2">
                            The monthly payment is made up of your monthly principal and a fixed finance charge. The first month may include interest for a partial statement cycle.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import format from '@/mixins/format'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        name: 'FixedMonthlyPaymentListItem',
        props: {
            theoreticalFixedPaymentStats: {
                type: Array,
                required: true,
            },
            lineSize: {
                type: Number,
                required: true,
            },
            termMonthsOptions: {
                type: Array,
                required: true,
            },
        },
        mixins: [format, experimentsMixin],
        computed: {
            lineSizeFormatted: function () {
                return this.toFormattedUSDNoCents(this.lineSize)
            },
        },
        methods: {
            paymentFormatted: function (paymentSize) {
                return this.toFormattedUSD(paymentSize)
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
