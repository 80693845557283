<template>
    <modal
        :open="modalOpen"
        :title="$t('components.emailVerificationHelpModal.title')"
        @on-modal-close-click="onModalCloseClick"
    >
        <p>
            {{ $t('components.emailVerificationHelpModal.subTitle') }}
        </p>

        <form-button
            v-if="showTryAgain"
            class="mb-2"
            :label="$t('components.emailVerificationHelpModal.tryAgain')"
            type="submit"
            button-classes="btn btn-secondary"
            @click="onClickTryAgain"
            event-name="click_button_email_verify_try_again"
        />

        <form-button
            class="mb-2"
            :label="$t('components.emailVerificationHelpModal.goBack')"
            type="submit"
            button-classes="btn btn-secondary"
            @click="onClickGoBack"
            event-name="click_button_email_verify_go_back"
        />
    </modal>
</template>

<script>
    import Modal from '@/components/modal/Modal'
    import FormButton from '@/components/base/FormButton'

    export default {
        name: 'EmailVerificationHelpModal',
        components: {
            Modal,
            FormButton,
        },
        props: {
            showTryAgain: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                modalOpen: false,
                submitting: false,
            }
        },
        methods: {
            expand: function () {
                this.modalOpen = true
                this.submitting = false
            },
            onClickTryAgain: async function () {
                if (this.submitting) return
                this.submitting = true
                this.modalOpen = false
                process.nextTick(() => this.$emit('on-click-try-again'))
            },
            onClickGoBack: async function () {
                if (this.submitting) return
                this.submitting = true
                this.modalOpen = false
                process.nextTick(() => this.$emit('on-click-go-back'))
            },
            onModalCloseClick: function () {
                this.modalOpen = false
            },
        },
    }
</script>
