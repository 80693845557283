<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-arrow-double-right.svg"
                alt="Skip Step"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.skipListItem.title') }}
            </p>
            <div class="text-muted">
                {{ $t('components.onboarding.skipListItem.subTitle') }}
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'SkipListItem',
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
