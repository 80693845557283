<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-piggy-bank.svg"
                alt=""
            >
        </div>
        <div class="col text-start">
            <p
                class="fw-bold mb-0 mt-1"
                v-html="$t('components.onboarding.saveUpToListIten.title')"
            />
            <div
                class="text-muted"
                v-html="$t('components.onboarding.saveUpToListIten.subTitle')"
            />
            <div class="mt-2 bg-white p-2 rounded-3 d-flex align-items-center">
                <p class="mb-0">
                    A neighbor in CA is saving $3,778 a year in interest by transferring their debt.<sup>3</sup>
                </p>
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'SaveUpToListItem',
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
