<template>
    <li class="g-0 row mb-2">
        <div
            ref="iconContainer"
            class="col-auto"
        >
            <img
                class="list-item-icon"
                src="@/assets/images/global/trust-pilot-star.svg"
                alt="TrustPilot Logo"
            >
        </div>
        <div class="col col-md-10">
            <p class="fw-bold mb-1 mt-1">
                Trustpilot
            </p>
            <p class="mb-0 small">
                <span class="fw-bold">Excellent</span> – 4.7 out of 5 Stars
            </p>
            <div :style="`max-width: ${maxWidthContainer}px`">
                <div class="bg-white pb-3 rounded-3 mt-2">
                    <tiny-slider
                        auto-height
                        prevent-scroll-on-touch="auto"
                        :mouse-drag="true"
                        :loop="true"
                        nav-position="bottom"
                        :controls="true"
                        :controls-text="['', '']"
                        controls-position="bottom"
                        :items="1"
                        :center="true"
                        ref="tinySlider"
                    >
                        <template v-for="(r, i) in reviewsArr">
                            <social-proof-item
                                :review="r"
                                :key="`social-item-${i}`"
                            />
                        </template>
                    </tiny-slider>
                    <div class="ts-controls-container">
                        <div>&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import 'tiny-slider/src/tiny-slider.scss'
    import VueTinySlider from 'vue-tiny-slider'
    import SocialProofItem from '@/components/onboarding/SocialProofItem'
    import { reviews } from '@/utils/reviews'

    export default {
        name: 'SocialProofListItem',
        components: {
            'tiny-slider': VueTinySlider,
            'social-proof-item': SocialProofItem,
        },
        props: {
            reviewIndex: {
                type: Number,
            },
            // optional so we can update the height after tiny loader has mounted on screens that render after loading is set to false
            loading: {
                type: Boolean,
            },
        },
        watch: {
            loading() {
                if (this.loading === false) {
                    // will reset the height of tiny loader if the user has loaded into the screen
                    this.$refs.tinySlider.slider.updateSliderHeight()

                    this.$nextTick(() => {
                        // can only get the container width when the component has fully mounted the element
                        this.iconContainerWidth = this.$refs.iconContainer.clientWidth
                    })
                }
            },
        },
        data() {
            return {
                reviews,
                iconContainerWidth: 0,
            }
        },
        computed: {
            maxWidthContainer() {
                // fixes a bug where tiny slider inherits the max-width of the right-container class rather than viewport width
                // current viewport width - the colAuto - 24px on each side
                return window.innerWidth - this.iconContainerWidth - 48
            },
            reviewsArr() {
                // TODO: can we get these dynamically now that we use trustpilot API
                // use william's review by default if prop has not been passed down, otherwise display list of reviews
                if (this.reviewIndex) {
                    return [this.reviews[this.reviewIndex ?? 2].content]
                }
                return [
                    // these can change often, so delaying adding them to the en.ts locale
                    'I’ve researched what this company have to offer before I decided to apply. It amazes me how everything was completed online within 15 minutes - and that includes credit check, property appraisal, loan amount and rate decision. Online notary was another 15 minutes. All with no fees.',
                    'Very streamlined process and very easy. I loved the use of technology!',
                    'This was honestly the simplest loan process I’ve ever done and most important the rate they offered was better than the competition.',
                    'The loan was approved in seconds and the notary was done in 15 minutes and interest was wonderful experience was so nice and we were approved with just our social and nothing else I have never had this so nice experience ever I totally recommend it to anyone',
                    'i have never expereienced a company that will approve ur loan in 1 day… you give them your docs ehatever they want and your lian is done… their app is so easy … i am going to recommend all my friends and families to apply here.. DO NOT APPLY ANYWHERE ELSE .. .. THEY ARE ALL FAKES AND SCAMMERS…',
                ]
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/listItem.scss';
    @import '../../styles/components/tinySlider.scss';

    .tns-controls {
        top: initial;
        bottom: -5px;
        width: 80%;
        left: 10%;

        button {
            height: 16px;
            background-size: contain;
        }
    }
</style>
