import { getMonthlyInstallmentPaymentStatsFromCoreCard } from '@/services/homeApi'
import { logger } from '@/utils/logger'

export const calculateTotalPlusBtOrCoFee = (totalPayment: number, lineSize: number, feePercentage: number) => {
    return totalPayment + lineSize * feePercentage
}

export const getTheoreticalFixedMonthlyPayments = async (apr: number, lineSize: number, loanTermInMonths: number, feePercentage: number) => {
    const monthlyInstallmentPaymentStatsFromCoreCard = await getMonthlyInstallmentPaymentStatsFromCoreCard(apr, lineSize, loanTermInMonths)

    if (!monthlyInstallmentPaymentStatsFromCoreCard.data.success) {
        logger.warn('Missing fixed payment stats, allow caller to handle.')
        return
    }

    const totalPlusBtOrCoFee = calculateTotalPlusBtOrCoFee(monthlyInstallmentPaymentStatsFromCoreCard.data.payload.totalAmount, lineSize, feePercentage)

    return {
        ...monthlyInstallmentPaymentStatsFromCoreCard.data.payload,
        totalPlusBtOrCoFee,
    }
}
