<template>
    <onboarding-layout
        :loading="loading || submitting"
        :loading-title="submitting ? 'Saving...' : 'Loading...'"
        :title="$t('pages.origination.coOwner.disputeCoOwnerTitle')"
        :error-text="errorText"
    >
        <div
            class="alert alert-secondary border-0 d-flex flex-row align-items-center mb-4 border-0 p-2 small"
            style="margin-top: -1.5rem"
        >
            <div>
                <p
                    v-for="(secondaryApplicant, i) in secondarySignersList"
                    :key="`secondary-${i}`"
                    class="mb-0 fw-bold"
                >
                    {{ secondaryApplicant }}
                </p>
            </div>
        </div>
        <form-container
            id="disputeCoOwnerMessageBox"
            ref="disputeCoOwnerMessageBox"
        >
            <form-text-area
                name="disputeCoOwnerMessageText"
                ref="disputeCoOwnerMessageText"
                class="border-primary"
                :placeholder="inputPlaceholder"
                validation-rules="required|min:2"
                inputmode="string"
                v-model="disputeCoOwnerMessageText"
            />
            <form-button
                class="mt-3"
                :label="$t('global.cta.continue')"
                @click="handleDisputeClick"
            />
        </form-container>
        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <why-other-signers-list-item />
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { postDisputeCoOwnerInfo } from '@/services/homeApi'
    import originationMixin from '@/mixins/originationMixin'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import WhyOtherSignersListItem from '@/components/onboarding/WhyOtherSignersListItem'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { logger } from '../../utils/logger'
    import FormContainer from '../../components/base/FormContainer'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormButton from '@/components/base/FormButton'
    import { appSessionStorage, sessionStorageKey } from '../../utils/storage'

    export default {
        components: {
            FeedbackListItem,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
            'why-other-signers-list-item': WhyOtherSignersListItem,
            'form-container': FormContainer,
            'form-text-area': FormTextArea,
            'form-button': FormButton,
        },
        props: {
            inputPlaceholder: { type: String, default: 'Please provide details...' },
        },
        mixins: [originationMixin, computeCreditOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                loading: true,
                disputeCoOwnerMessageText: '',
                secondarySignersList: [],
            }
        },
        mounted: async function () {
            try {
                this.loading = false
                this.getSecondarySignersList()
                this.$logEvent('view_dispute_co_owner')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {},
        methods: {
            getSecondarySignersList: function () {
                this.secondarySignersList = JSON.parse(appSessionStorage.getItem(sessionStorageKey.secondarySignersList))
            },
            handleDisputeClick: async function () {
                logger.log('Attempting to postDisputeCoOwnerInfo')
                try {
                    this.$logEvent('click_dispute_co_owner_info')
                    await postDisputeCoOwnerInfo(this.disputeCoOwnerMessageText)
                    logger.log('Attempting to postDisputeCoOwnerInfo succeeded, redirecting to thanks')
                    await this.$router.push({
                        path: sharedPagePaths.THANKS,
                        query: { reason: 'disputeCoOwnerInfo' },
                    })
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
            },
        },
    }
</script>
