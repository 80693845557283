<template>
    <onboarding-layout
        :loading="loading"
        :error-text="errorText"
        loading-title="Finalizing lookup..."
        data-testid="flood-insurance-verification-page"
    >
        <h5 class="fw-light mb-0">
            {{ $t('pages.origination.floodInsuranceVerification.title1') }}
        </h5>

        <section-header class="mt-5">
            {{ $t('pages.origination.floodInsuranceVerification.proofOfFloodInsurance') }}
        </section-header>

        <div class="mt-1 bg-light rounded-2 w-100 p-2 d-flex align-items-center">
            <div class="pe-1">
                <img
                    src="@/assets/images/global/envelope.svg"
                    alt="Envelope"
                >
            </div>
            <div class="ms-1">
                <span class="small text-muted">{{ $t('pages.origination.floodInsuranceVerification.mailInfo') }}</span>
            </div>
        </div>

        <div class="round-numbered-list">
            <ol>
                <li class="mt-3">
                    <div>
                        <p>{{ $t('pages.origination.floodInsuranceVerification.docInstructions.step1') }}</p>
                        <p class="mb-0">
                            <span class="fw-bold">{{ $t('pages.origination.floodInsuranceVerification.coastalInfo.name') }}</span>
                            <br>
                            <span>{{ $t('pages.origination.floodInsuranceVerification.coastalInfo.isaoa') }}</span>
                            <br>
                            <span>{{ $t('pages.origination.floodInsuranceVerification.coastalInfo.addressLine1') }}</span>
                            <br>
                            <span>{{ $t('pages.origination.floodInsuranceVerification.coastalInfo.addressLine2') }}</span>
                        </p>
                    </div>
                </li>
                <li class="mt-3">
                    <!-- TODO: !!!!! !!!!! !!!!! Replace the 50k string with an actual value gotten from the BE when implemented -->
                    <div v-html="$t('pages.origination.floodInsuranceVerification.docInstructions.step2', { coverageAmount: '50k' })" />
                </li>
                <li class="mt-3">
                    <div>
                        {{ $t('pages.origination.floodInsuranceVerification.docInstructions.step3.desc') }}
                        <ul>
                            <li>{{ $t('pages.origination.floodInsuranceVerification.docInstructions.step3.pt1') }}</li>
                            <li>{{ $t('pages.origination.floodInsuranceVerification.docInstructions.step3.pt2') }}</li>
                        </ul>
                    </div>
                </li>
                <li class="mt-3">
                    <div>
                        {{ $t('pages.origination.floodInsuranceVerification.docInstructions.step4') }}
                    </div>
                </li>
            </ol>
        </div>

        <div>
            <upload-button
                class="mt-3"
                :title="$t('pages.origination.floodInsuranceVerification.uploadBtn')"
                @on-file-change="uploadInsurance($event)"
                :is-complete="documentUploaded"
                :is-submitting="currentlyUploadingDocument"
                incomplete-text="Add"
                complete-text="✓ Added"
                ref="insuranceUpload"
                accept="image/*,application/pdf"
            />
        </div>

        <form-button
            type="button"
            :label="$t('pages.origination.floodInsuranceVerification.cta')"
            class="mt-5"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_flood_insurance_verification_continue"
            data-testid="property-verification-continue-button"
        />

        <template #sidebar>
            <feedback-list-item />

            <li class="g-0 row mb-2">
                <div class="col-auto">
                    <img
                        class="list-item-icon"
                        src="@/assets/images/global/no-payment.svg"
                        alt="Wrong"
                    >
                </div>
                <div class="col text-start">
                    <p class="fw-bold mb-0 mt-1">
                        {{ $t('pages.origination.floodInsuranceVerification.notInAFloodZone.title') }}
                    </p>
                    <p
                        class="text-muted"
                        v-html="$t('pages.origination.floodInsuranceVerification.notInAFloodZone.content')"
                    />
                </div>
            </li>

            <li class="g-0 row mb-2">
                <div class="col-auto">
                    <img
                        class="list-item-icon"
                        src="@/assets/images/global/info.svg"
                        alt="Info"
                    >
                </div>
                <div class="col text-start">
                    <p class="fw-bold mb-0 mt-1">
                        {{ $t('pages.origination.floodInsuranceVerification.addAvenToPolicy.title') }}
                    </p>
                    <p
                        class="text-muted"
                        v-html="$t('pages.origination.floodInsuranceVerification.addAvenToPolicy.content')"
                    />
                </div>
            </li>

            <li class="g-0 row mb-2">
                <div class="col-auto">
                    <img
                        class="list-item-icon"
                        src="@/assets/images/global/home-flood.svg"
                        alt="Home Flood"
                    >
                </div>
                <div class="col text-start">
                    <p class="fw-bold mb-0 mt-1">
                        {{ $t('pages.origination.floodInsuranceVerification.providerOfChoice.title') }}
                    </p>
                    <p
                        class="text-muted"
                        v-html="$t('pages.origination.floodInsuranceVerification.providerOfChoice.content')"
                    />
                </div>
            </li>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import { misc } from '@/mixins/misc'
    import originationMixin from '@/mixins/originationMixin'
    import { getNextRoute } from '@/flow/flowController'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    // import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { uploadDocument } from '@/services/api'
    import UploadButton from '@/components/UploadButton'
    import { i18n } from '@/utils/i18n'
    import SectionHeader from '../../components/SectionHeader'

    export default {
        name: 'PropertyVerification',
        components: {
            FeedbackListItem,
            SectionHeader,
            UploadButton,
            FormButton,
            OnboardingLayout,
        },
        mixins: [misc, originationMixin],
        data: function () {
            return {
                currentlyUploadingDocument: false,
                documentUploaded: false,
            }
        },
        mounted: async function () {
            await this.$router.replace(getNextRoute(this.$router)) // This effectively disables this page for stage 1 until backend is ready and TODOs in this file are addressed
            // try {
            //     this.loading = false
            //     this.$logEvent('view_flood_insurance_verification')
            // } catch (e) {
            //     this.errorText = ApiErrorHandler(e)
            // }
        },
        computed: {},
        methods: {
            uploadInsurance: async function (fileList, index) {
                this.$logEvent('click_button_upload_flood_insurance_document')
                this.errorText = ''
                this.isInsuranceUploading = true
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Insurance document was not selected.')
                        this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const insuranceFileInfo = this.getFileInfo(file)
                    if (insuranceFileInfo === appSessionStorage.getItem(sessionStorageKey.insuranceInfo)) {
                        logger.warn('Insurance document already exists in storage')
                        this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorDuplicate')
                        this.scrollToTop()
                        return
                    }
                    const response = await uploadDocument(file, 'floodInsuranceDocument', index, false)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.t('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = insuranceFileInfo
                    appSessionStorage.setItem(sessionStorageKey.insuranceInfo, JSON.stringify([insuranceFileInfo]))
                    this.hasInsuranceUploaded = true
                } catch (error) {
                    logger.error('Failed to upload insurance document', error)
                    this.errorText = i18n.t('pages.origination.propertyVerification.insurance.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                    this.isInsuranceUploading = false
                }
            },
            next: async function () {
                return await this.$router.push(getNextRoute(this.$router))
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/roundNumberedList';
</style>
