<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
        ref="onboardingLayout"
    >
        <h5
            class="fw-light"
            v-html="title"
        />
        <form-container
            id="applicationForm"
            ref="basicInfoForm"
            @on-submit="onSubmit"
            @onError="onError"
        >
            <div class="bg-light rounded-2 w-100 p-1">
                <span class="pe-1">
                    <img
                        src="@/assets/images/global/stopwatch.svg"
                        alt="Very Fast"
                    >
                </span>
                <span class="small text-gray-400">For most, this takes less than 60 seconds</span>
            </div>
            <!-- PRIMARY APPLICANT -->
            <section-header class="mt-6">
                {{ primaryHeading }}
            </section-header>

            <div class="row g-2 mb-2">
                <div class="col">
                    <form-field
                        @blur="getStateModuleData"
                        v-model="primaryFirstName"
                        data-testid="personal-info-primary-first-name-input"
                        name="primaryFirstName"
                        :placeholder="$t('components.applicationForm.placeholder.firstName')"
                        :label="$t('components.applicationForm.placeholder.firstName')"
                        validation-rules="required|min:2"
                        autocomplete="given-name"
                    />
                </div>
                <div class="col">
                    <form-field
                        @blur="getStateModuleData"
                        v-model="primaryLastName"
                        data-testid="personal-info-primary-last-name-input"
                        name="primaryLastName"
                        :placeholder="$t('components.applicationForm.placeholder.lastName')"
                        :label="$t('components.applicationForm.placeholder.lastName')"
                        validation-rules="required|min:2"
                        autocomplete="family-name"
                    />
                </div>
                <span
                    class="small text-gray-400 mt-1"
                    v-show="primaryFirstName || primaryLastName"
                >Use your legal name from a valid state ID or passport</span>
            </div>

            <form-field
                class="mb-2"
                v-model="primaryEmail"
                data-testid="personal-info-primary-email-input"
                name="primaryEmail"
                :placeholder="$t('components.applicationForm.placeholder.email')"
                :label="$t('components.applicationForm.placeholder.email')"
                validation-rules="required|email"
                input-type="email"
                inputmode="email"
            />

            <form-field-ssn
                v-model="primarySSN"
                data-testid="personal-info-primary-last-4-ssn-input"
                name="primarySSN"
                class="mb-2"
                last-four-only
            />

            <form-field-date
                class="mb-2"
                v-model="primaryDOB"
                data-testid="personal-info-primary-dob-input"
                name="primaryDOB"
                validation-rules="required|dateRule|ageRule"
                :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
            />

            <form-radio-group
                class="radio-left-aligned"
                :options="maritalStatusOptions"
                name="primaryMaritalStatusInput"
                v-model="primaryMaritalStatus"
                validation-rules="required"
                :inline="true"
            />

            <section-header class="mt-4">
                Address of Property
            </section-header>

            <!-- Property Address -->
            <form-field-address
                ref="propertyAddressField"
                @on-change="onPropertyAddressInputChange"
                @on-clear="onClearPropertyAddress"
                initial-input-type="auto"
            />

            <form-radio-group
                class="radio-left-aligned fw-bold"
                :prompt="promptNOO"
                :options="residenceTypeOptions"
                name="residence-type-input"
                v-model="residenceType"
                validation-rules="required"
                :inline="true"
            />
            <div
                class="invalid-feedback is-invalid"
                :style="primaryResidenceError ? 'display: block' : ''"
                data-testid="primary-residence-error"
            >
                {{ primaryResidenceError }}
            </div>

            <!-- Personal Address -->
            <section-header
                class="mt-4"
                v-if="isPersonalAddressBoxVisible"
            >
                Address of Primary Residency
            </section-header>

            <form-field-address
                v-if="isPersonalAddressBoxVisible"
                ref="personalAddressField"
                @on-change="onPersonalAddressInputChange"
                @on-clear="onClearPersonalAddress"
                initial-input-type="auto"
                data-test-id-prefix="personal-info-personal-address-box"
            />

            <div class="row g-0 mt-3">
                <div class="col pe-1 position-relative d-flex">
                    <list-button
                        v-model="inJointForm"
                        class="fw-bold small"
                        :class="inJointForm ? 'selected' : ''"
                        title="Joint"
                        image-src="origination/Household.svg"
                        @click="inJointForm = true"
                    />
                    <img
                        v-if="inJointForm"
                        class="position-absolute"
                        style="bottom: 16px; right: 8px"
                        width="32px;"
                        src="@/assets/images/origination/checkmark.svg"
                        alt="checkmark"
                    >
                </div>
                <div class="col ps-1 position-relative d-flex">
                    <list-button
                        v-model="inJointForm"
                        data-testid="personal-info-individual-option"
                        class="fw-bold small"
                        :class="!inJointForm ? 'selected' : ''"
                        title="Individual"
                        image-src="origination/Individual.svg"
                        @click="inJointForm = false"
                    />
                    <img
                        v-if="!inJointForm"
                        class="position-absolute"
                        style="bottom: 16px; right: 0"
                        width="32px;"
                        src="@/assets/images/origination/checkmark.svg"
                        alt="checkmark"
                    >
                </div>
            </div>

            <!-- CO-APPLICANT -->
            <div v-if="inJointForm">
                <section-header class="mt-3">
                    {{ secondaryHeading }}
                </section-header>

                <div class="row g-2 mb-2">
                    <div class="col">
                        <form-field
                            @blur="getStateModuleData"
                            v-model="secondaryFirstName"
                            name="secondaryFirstName"
                            data-testid="personal-info-secondary-first-name-input"
                            :placeholder="$t('components.applicationForm.placeholder.firstName')"
                            :label="$t('components.applicationForm.placeholder.firstName')"
                            validation-rules="required|min:2"
                        />
                    </div>
                    <div class="col">
                        <form-field
                            @blur="getStateModuleData"
                            v-model="secondaryLastName"
                            name="secondaryLastName"
                            data-testid="personal-info-secondary-last-name-input"
                            :placeholder="$t('components.applicationForm.placeholder.lastName')"
                            :label="$t('components.applicationForm.placeholder.lastName')"
                            validation-rules="required|min:2"
                        />
                    </div>
                    <span
                        class="small text-gray-400 mt-1"
                        v-show="secondaryFirstName || secondaryLastName"
                    >Use applicant's legal name from a valid state ID or passport</span>
                </div>

                <form-field-ssn
                    v-model="secondarySSN"
                    name="secondarySSN"
                    data-testid="personal-info-secondary-last-4-ssn-input"
                    class="mb-2"
                    last-four-only
                />

                <form-field-date
                    class="mb-2"
                    v-model="secondaryDOB"
                    name="secondaryDOB"
                    data-testid="personal-info-secondary-dob-input"
                    validation-rules="required|dateRule|ageRule"
                    :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                    :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                    :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
                />

                <form-radio-group
                    class="radio-left-aligned"
                    :options="maritalStatusOptions"
                    name="secondaryMaritalStatusInput"
                    v-model="secondaryMaritalStatus"
                    validation-rules="required"
                    :inline="true"
                />
            </div>

            <state-form-module
                ref="stateModules"
                v-if="stateModules && stateModules.modules && stateModules.modules.length > 0"
                class="mt-5"
                :data="stateModules"
            />

            <form-button
                class="mt-4"
                data-testid="personal-info-continue-button"
                image-src="global/web_16_lock.svg"
                :label="buttonCta"
                :disabled="ctaButtonDisabled"
                :submitting="submitting"
                type="submit"
                event-name="click_button_submit_personal_info_co_applicant_form"
                :event-props="{ withCoApplicant: inJointForm }"
            />

            <safe-credit-score
                :safe-credit-score-text="$t('pages.marketing.landing.newSafeCreditScore')"
                class="mt-1 mb-0"
            />

            <div class="d-none d-md-block text-start mt-3">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
                <p class="text-muted small text-start mt-2">
                    {{ $t('components.applicationForm.legal.jointDisclaimer') }}
                </p>
                <p class="text-muted small text-start mb-2">
                    For more important information about our Aven Home Cards:
                    <a
                        @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                        href="#"
                    > Pricing and Terms </a> and
                    <a
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        href="#"
                    > Early HELOC Disclosure </a>
                </p>
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.balanceTransfer.legalText1')"
                />
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.balanceTransfer.legalText2')"
                />
            </div>
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <save-up-to-list-item v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER" />
                <unlimited-cashback-list-item v-if="statedUsage === StatedUsageType.HOME_IMPROVEMENT" />
                <social-proof-list-item :review-index="8" />
                <feedback-list-item />
            </ul>
            <div class="d-block d-md-none text-start">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
                />
                <p
                    class="text-start text-muted mb-2 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
                <p class="text-muted small text-start mt-2">
                    {{ $t('components.applicationForm.legal.jointDisclaimer') }}
                </p>
                <p class="text-muted small text-start mb-2">
                    For more important information about our Aven Home Cards:
                    <a
                        @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                        href="#"
                    > Pricing and Terms </a> and
                    <a
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        href="#"
                    > Early HELOC Disclosure </a>
                </p>
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.balanceTransfer.legalText1')"
                />
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.balanceTransfer.legalText2')"
                />
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import format from '@/mixins/format'
    import originationMixin from '@/mixins/originationMixin'
    import personalInfoMixin from '@/mixins/personalInfoMixin'
    import { logger, inspect } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { sessionStorageKey, appSessionStorage } from '@/utils/storage'
    import { createCoApplicantLead } from '@/services/auth'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getNextRoute } from '@/flow/flowController'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormFieldAddress from '@/components/base/FormFieldAddress'
    import FormButton from '@/components/base/FormButton'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import ListButton from '@/components/ListButton'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import authMixin from '@/mixins/authMixin'
    import UnlimitedCashbackListItem from '@/components/onboarding/UnlimitedCashbackListItem'
    import SocialProofListItem from '@/components/onboarding/SocialProofListItem'
    import SaveUpToListItem from '@/components/onboarding/SaveUpToListItem'
    import { LegalDocumentTypes, MaritalStatus } from '@/services/api'
    import LogRocket from 'logrocket'
    import { ResidenceType, getStatePageModules, StatedUsageType } from '../../services/api'
    import StateFormModule from '../../components/state/StateFormModule'
    import { FLAG_NOO_IS_DISABLED } from '../../utils/flags'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'

    export default {
        components: {
            FeedbackListItem,
            SaveUpToListItem,
            SafeCreditScore,
            ListButton,
            FormButton,
            FormFieldDate,
            FormFieldAddress,
            FormField,
            SectionHeader,
            FormContainer,
            FormFieldSsn,
            FormRadioGroup,
            'onboarding-layout': OnboardingLayout,
            'unlimited-cashback-list-item': UnlimitedCashbackListItem,
            'social-proof-list-item': SocialProofListItem,
            'state-form-module': StateFormModule,
        },
        mixins: [format, originationMixin, personalInfoMixin, experimentsMixin, authMixin],
        data() {
            return {
                StatedUsageType,
                LegalDocumentTypes,
                loadingTitle: i18n.t('global.loadingMessage.verifying'),
                inJointForm: true,
                residenceType: '',
                buttonCta: i18n.t('global.cta.continue'),
                propertyAddressData: null,
                personalAddressData: null,
                primaryEmail: '',
                primarySSN: '',
                primaryDOB: '',
                primaryMaritalStatus: '',
                secondarySSN: '',
                secondaryDOB: '',
                secondaryMaritalStatus: '',
                statedUsage: appSessionStorage.getItem(sessionStorageKey.statedUsage),
                maritalStatusOptions: [
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.married'), value: MaritalStatus.MARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.unmarried'), value: MaritalStatus.UNMARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.separated'), value: MaritalStatus.SEPARATED },
                ],
                residenceTypeOptions: [
                    { name: i18n.t('components.applicationForm.residenceType.primary'), value: ResidenceType.PRIMARY },
                    { name: i18n.t('components.applicationForm.residenceType.secondary'), value: ResidenceType.SECONDARY },
                ],
                stateModules: {},
                ctaButtonDisabled: false,
                primaryResidenceError: '',
            }
        },
        computed: {
            title() {
                return i18n.t('pages.origination.personalInfo.noInviteTitleWithCoApplicant')
            },
            promptNOO() {
                return i18n.t('components.applicationForm.residenceType.question')
            },
            secondaryHeading() {
                return 'JOINT APPLICANT'
            },
            primaryHeading() {
                return 'YOUR INFORMATION'
            },
            isApplicantDuplicated() {
                return this.inJointForm && this.primaryDOB && this.primarySSN && this.primaryDOB === this.secondaryDOB && this.primarySSN === this.secondarySSN
            },
            isPrimaryResidence() {
                return this.residenceType === ResidenceType.PRIMARY
            },
            isPersonalAddressBoxVisible() {
                if (FLAG_NOO_IS_DISABLED) {
                    return false
                }
                return this.residenceType && !this.isPrimaryResidence
            },
        },
        watch: {
            inJointForm: function () {
                this.getStateModuleData()
            },
            propertyAddressData: function () {
                this.getStateModuleData()
            },
            residenceType: function () {
                this.primaryResidenceError = ''
            },
        },
        mounted: async function () {
            // These are the dynamically generated legal docs the applicant can view on this page.
            const docsToGenerate = [LegalDocumentTypes.pricingAndTerms, LegalDocumentTypes.earlyHELOCDisclosure]
            // Purposefully not awaiting the results here because these docs are generated for
            // our record-keeping purposes and not strictly required for the UI
            // noinspection ES6MissingAwait
            this.tryGenerateAndSaveLegalDocuments(docsToGenerate)
            this.$logEvent('view_personal_info')
        },
        methods: {
            async getStateModuleData() {
                this.ctaButtonDisabled = true
                try {
                    // Validate applicant name and co applicant name (if applicable) are present beforehand
                    const state = this.propertyAddressData && this.propertyAddressData.addressComponents && this.propertyAddressData.addressComponents.addressState
                    const primaryInfoIsPresent = this.primaryFirstName && this.primaryLastName
                    const coApplicantInfoIsPresent = !this.inJointForm || (this.secondaryFirstName && this.secondaryLastName)

                    if (!state || !primaryInfoIsPresent || !coApplicantInfoIsPresent) {
                        this.stateModules = {}
                        return
                    }

                    const partialApplicantInfo = {
                        firstName: this.primaryFirstName,
                        lastName: this.primaryLastName,
                    }

                    const partialCoApplicantInfo = this.inJointForm
                        ? {
                            firstName: this.secondaryFirstName,
                            lastName: this.secondaryLastName,
                        }
                        : undefined

                    const stateModulesResponse = await getStatePageModules('PersonalInfo', state, partialApplicantInfo, partialCoApplicantInfo)
                    this.stateModules = stateModulesResponse.data.payload
                } finally {
                    this.ctaButtonDisabled = false
                }
            },
            onError(errorText) {
                this.errorText = errorText
                this.scrollToTop()
            },
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            onPropertyAddressInputChange(value) {
                logger.log(`property address input change: ${inspect(value)}`)
                this.propertyAddressData = value
            },
            onClearPropertyAddress() {
                this.propertyAddressData = null
                this.residenceType = ''
            },
            onPersonalAddressInputChange(value) {
                logger.log(`personal address input change: ${inspect(value)}`)
                this.personalAddressData = value
            },
            onClearPersonalAddress() {
                this.personalAddressData = null
            },
            onSubmit: async function () {
                this.loading = true

                // Check if base is valid
                let isValid = await this.$refs.basicInfoForm.$refs.observer.validate()
                if (this.$refs.propertyAddressField.useAutocomplete) {
                    isValid = isValid && (await this.$refs.propertyAddressField.validateAutocompleteAddress())
                }
                if (this.isPersonalAddressBoxVisible && this.$refs.personalAddressField.useAutocomplete) {
                    isValid = isValid && (await this.$refs.personalAddressField.validateAutocompleteAddress())
                }

                this.errorText = this.isApplicantDuplicated ? 'It looks like you entered the same person twice. If you would like to apply as an individual, please select the individual option.' : ''

                const isNOOError = FLAG_NOO_IS_DISABLED && !this.isPrimaryResidence
                this.primaryResidenceError = isNOOError ? 'We currently only support primary residences. Please confirm this is your primary residence or use a different address.' : ''

                if (!isValid || !this.residenceType || isNOOError || this.isApplicantDuplicated || !this.propertyAddressData) {
                    // 1. scroll validation errors into view
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()

                    if (this.errorText) {
                        // 2. if there's errorText, scroll to the top
                        this.scrollToTop()
                    }

                    this.loading = false
                    return
                }

                if (this.isPrimaryResidence) {
                    // Addresses are the same
                    this.personalAddressData = this.propertyAddressData
                }

                this.saveDataToStorage()
                try {
                    let isSuccessful = await this.submitBasicInfo(
                        {
                            firstName: this.primaryFirstName,
                            lastName: this.primaryLastName,
                            emailAddress: this.primaryEmail,
                            propertyAddressData: this.propertyAddressData,
                            personalAddressData: this.personalAddressData,
                            residenceType: this.residenceType,
                            ssn: this.primarySSN,
                            applicantDOB: this.primaryDOB,
                            maritalStatus: this.primaryMaritalStatus,
                        },
                        /* isCoApplicant */ false
                    )
                    if (!isSuccessful) {
                        return
                    }

                    if (this.inJointForm) {
                        if (!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                            const authResponse = await createCoApplicantLead()
                            if (!authResponse.data.success) {
                                await this.handleAuthErrors(authResponse)
                                return
                            }
                        }

                        isSuccessful = await this.submitBasicInfo(
                            {
                                firstName: this.secondaryFirstName,
                                lastName: this.secondaryLastName,
                                propertyAddressData: this.propertyAddressData,
                                personalAddressData: this.personalAddressData,
                                residenceType: this.residenceType,
                                ssn: this.secondarySSN,
                                applicantDOB: this.secondaryDOB,
                                maritalStatus: this.secondaryMaritalStatus,
                            },
                            /* isCoApplicant */ true
                        )
                        if (!isSuccessful) {
                            return
                        }
                    }

                    if (this.$refs.stateModules) {
                        await this.$refs.stateModules.sendValuesToBackend()
                    }

                    this.identifyLogRocketUser()

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loading = false
                }
            },
            saveDataToStorage: function () {
                appSessionStorage.setItem(sessionStorageKey.firstName, this.primaryFirstName)
                appSessionStorage.setItem(sessionStorageKey.lastName, this.primaryLastName)

                if (this.inJointForm) {
                    appSessionStorage.setItem(sessionStorageKey.coApplicantFirstName, this.secondaryFirstName)
                    appSessionStorage.setItem(sessionStorageKey.coApplicantLastName, this.secondaryLastName)
                }

                const basicInfo = {
                    propertyAddressData: this.propertyAddressData,
                    personalAddressData: this.personalAddressData,
                    residenceType: this.residenceType,
                }
                appSessionStorage.setItem(sessionStorageKey.basicInfo, JSON.stringify(basicInfo))

                appSessionStorage.setItem(sessionStorageKey.applicantMaritalStatus, this.primaryMaritalStatus)
                appSessionStorage.setItem(sessionStorageKey.coApplicantMaritalStatus, this.secondaryMaritalStatus)
            },
            // THIS FUNC IS ACTUALLY CALLED INSIDE THE PERSONAL_INFO_MIXIN, DO NOT REMOVE
            updateBasicInfo: function (value, isCoApplicant) {
                if (!isCoApplicant) {
                    this.primaryFirstName = value.firstName || this.primaryFirstName
                    this.primaryLastName = value.lastName || this.primaryLastName
                    this.primaryDOB = value.applicantDOB || this.primaryDOB
                } else {
                    this.secondaryFirstName = value.firstName || this.secondaryFirstName
                    this.secondaryLastName = value.lastName || this.secondaryLastName
                    this.secondaryDOB = value.applicantDOB || this.secondaryDOB
                }

                this.primaryEmail = value.emailAddress || this.primaryEmail
                this.$refs.propertyAddressField.loadAddressFormData(value.propertyAddressData)
                if (this.isPersonalAddressBoxVisible) {
                    this.$refs.personalAddressField.loadAddressFormData(value.personalAddressData)
                }
                this.residenceType = value.residenceType || this.residenceType
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('global.loadingMessage.verifying')
            },
            identifyLogRocketUser: function () {
                try {
                    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
                    LogRocket.identify(sessionId, {
                        name: `${this.primaryFirstName} ${this.primaryLastName}`,
                        phone: this.phone,
                        email: this.email,
                    })
                } catch (error) {
                    logger.info(`LogRocket failed to identify: ${inspect(error)}`)
                }
            },
        },
    }
</script>

<style scoped>
    .radio-left-aligned {
        margin-left: -1.5em;
    }
</style>
